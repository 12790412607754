import React from 'react'
import { useSelector } from 'react-redux'

import { selectSubscriptionAccess } from 'shared/modules/Subscription/selectors'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { SubscriptionCanUpgrade } from 'web/modules/Subscription/components'
import { HelmetLocalised, Grid } from 'web/components'

import messages from './messages'

// @NOTE Subscriptions are currently hard-coded to access level of 20. If the user has a level less than 20, they are assumed to be able to upgrade. And the assumption is they are upgrading to 20

const AccountUpgradePage = () => {
  const subscriptionAccess = useSelector(state =>
    selectSubscriptionAccess(state),
  )

  return (
    <>
      <HelmetLocalised
        title={messages.title}
        description={messages.description}
      />
      <PageWrapper>
        <Grid container justify="center">
          <SubscriptionCanUpgrade subscriptionAccess={subscriptionAccess} />
        </Grid>
      </PageWrapper>
    </>
  )
}

export default AccountUpgradePage

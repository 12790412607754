import React from 'react'
import MuiCircularProgress from '@material-ui/core/CircularProgress'

import { colors } from 'web/theme'

const CircularProgress = props => (
  <MuiCircularProgress {...props} style={{ color: colors.loadingSpinner }} />
)

export default CircularProgress

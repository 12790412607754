import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import routes from 'web/routing/routes'
import { SubscriptionUpgrade } from 'web/modules/Subscription/components'
import { Grid } from 'web/components'

import messages from './messages'

const SubscriptionCanUpgrade = ({ subscriptionAccess }) => {
  const history = useHistory()
  const [access] = useState(subscriptionAccess)

  const handleSuccessContinue = () => {
    history.push(routes.DashboardPage.routePath)
  }

  return access > 10 ? (
    <>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h1" component="h1" align="center">
          <FormattedMessage {...messages.upgradedTitle} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Typography align="center" variant="h3" gutterBottom>
          <FormattedMessage {...messages.upgradedSubtitle} />
        </Typography>
      </Grid>
    </>
  ) : (
    <>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h1" component="h1" align="center">
          <FormattedMessage {...messages.bodyTitle} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Typography align="center" variant="h3" gutterBottom>
          <FormattedMessage {...messages.subtitle} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <SubscriptionUpgrade
          requiredAccessLevel={20}
          onSuccessContinue={handleSuccessContinue}
        />
      </Grid>
    </>
  )
}

SubscriptionCanUpgrade.propTypes = {
  subscriptionAccess: PropTypes.number.isRequired,
}

export default SubscriptionCanUpgrade

import { defineMessages } from 'react-intl'

export const authenticatedRoutes = defineMessages({
  dashboardPage: {
    id: 'web.PageLayout.authenticatedRoutes.dashboardPage',
    defaultMessage: 'Home',
  },
  weeklyPlanPage: {
    id: 'web.PageLayout.authenticatedRoutes.weeklyPlanPage',
    defaultMessage: 'Weekly Planner',
  },
  workoutsPage: {
    id: 'web.PageLayout.authenticatedRoutes.workoutsPage',
    defaultMessage: 'Workouts',
  },
  favourtiesPage: {
    id: 'web.PageLayout.authenticatedRoutes.favourtiesPage',
    defaultMessage: 'Favourites',
  },
  liveSchedulePage: {
    id: 'web.PageLayout.authenticatedRoutes.liveSchedulePage',
    defaultMessage: 'Live Class Schedule',
  },
  accountDetailsPage: {
    id: 'web.PageLayout.authenticatedRoutes.accountDetailsPage',
    defaultMessage: 'My Account',
  },
})

export const notAuthenticatedRoutes = defineMessages({
  signInPage: {
    id: 'web.PageLayout.notAuthenticatedRoutes.signInPage',
    defaultMessage: 'Sign In',
  },
  RegistrationUserDetailsPage: {
    id: 'web.PageLayout.notAuthenticatedRoutes.RegistrationUserDetailsPage',
    defaultMessage: 'Register',
  },
})

export const drawerLinks = defineMessages({
  signOut: {
    id: 'web.PageLayout.drawerLinks.signOut',
    defaultMessage: 'Sign Out',
  },
})

import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'

import { Grid } from 'web/components'
import { SubscriptionUpgrade } from 'web/modules/Subscription/components'

import messages from './messages'

const UpgradeOptions = ({ requiredAccessLevel }) => (
  <Grid container justify="center">
    <Grid item xs={12}>
      <Typography gutterBottom variant="h1" component="h1" align="center">
        <FormattedMessage {...messages.bodyTitle} />
      </Typography>
    </Grid>
    <Grid item xs={12} sm={10}>
      <Typography align="center" variant="h3" gutterBottom>
        <FormattedMessage {...messages.subtitle} />
      </Typography>
    </Grid>
    <Grid item xs={12} sm={8} md={6} lg={4}>
      <SubscriptionUpgrade requiredAccessLevel={requiredAccessLevel} />
    </Grid>
  </Grid>
)

UpgradeOptions.propTypes = {
  requiredAccessLevel: PropTypes.number.isRequired,
}

export default UpgradeOptions

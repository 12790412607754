import { takeLatest, call } from 'redux-saga/effects'
import ReactGA from 'react-ga'

import {
  SIGN_OUT_SUCCESS,
  SIGN_IN_SUCCESS,
  SIGN_UP_SUCCESS,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
} from 'shared/modules/Auth/actionTypes'

import {
  REMOVE_WORKOUT_FROM_FAVOURITES_SUCCESS,
  ADD_WORKOUT_TO_FAVOURITES_SUCCESS,
  UPDATE_USER_SUCCESS,
  INCREMENT_WORKOUT_WATCHED_COUNT,
} from 'shared/modules/User/actionTypes'

import { REGISTER_USER_FOR_LIVE_EVENT_SUCCESS } from 'shared/modules/LiveSchedule/actionTypes'

import { UPDATE_PAYMENT_METHOD_SUCCESS } from 'shared/modules/PaymentMethod/actionTypes'

import { SET_UI_VALUE } from 'shared/modules/UI/actionTypes'

import { CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS } from 'shared/modules/Subscription/actionTypes'

function* signOut() {
  yield call(ReactGA.event, {
    category: 'Authentication',
    action: 'Sign Out',
  })
}

function* signIn() {
  yield call(ReactGA.event, {
    category: 'Authentication',
    action: 'Sign In',
  })
}

function* signUp() {
  yield call(ReactGA.event, {
    category: 'Authentication',
    action: 'Sign Up',
  })
}

function* resetPassword() {
  yield call(ReactGA.event, {
    category: 'Authentication',
    action: 'Reset Password',
  })
}

function* updateEmail() {
  yield call(ReactGA.event, {
    category: 'Account Management',
    action: 'Update Email Address',
  })
}

function* updatePassword() {
  yield call(ReactGA.event, {
    category: 'Account Management',
    action: 'Update Password',
  })
}

// @TODO add in some details as to the values changing.
function* updateUser() {
  yield call(ReactGA.event, {
    category: 'Account Management',
    action: 'Update User Details',
  })
}

function* cancelSubscription({ cancelAtPeriodEnd }) {
  yield call(ReactGA.event, {
    category: 'Subscription Management',
    action: cancelAtPeriodEnd
      ? 'Cancel Subscription'
      : 'Reinstate Subscription',
    label: 'At Period End',
  })
}

function* addWorkoutFavourite({ workoutId }) {
  yield call(ReactGA.event, {
    category: 'Workouts',
    action: 'Add Favourite',
    label: workoutId,
  })
}

function* removeWorkoutFavourite({ workoutId }) {
  yield call(ReactGA.event, {
    category: 'Workouts',
    action: 'Remove Favourite',
    label: workoutId,
  })
}

function* registerLiveEvent({ liveScheduleId }) {
  yield call(ReactGA.event, {
    category: 'Live Classes',
    action: 'Register',
    label: liveScheduleId,
  })
}

function* updatePaymentMethod() {
  yield call(ReactGA.event, {
    category: 'Subscription Management',
    action: 'Update Payment Method',
  })
}

function* setUiValue({ key, value }) {
  yield call(ReactGA.event, {
    category: 'UI Interaction',
    action: key,
    label: value,
  })
}

function* incrementWorkoutWatched({ workoutId }) {
  yield call(ReactGA.event, {
    category: 'Workouts',
    action: 'Watched',
    label: workoutId,
  })
}

export default [
  takeLatest(SIGN_OUT_SUCCESS, signOut),
  takeLatest(SIGN_IN_SUCCESS, signIn),
  takeLatest(SIGN_UP_SUCCESS, signUp),
  takeLatest(RESET_PASSWORD_SUCCESS, resetPassword),
  takeLatest(UPDATE_EMAIL_SUCCESS, updateEmail),
  takeLatest(UPDATE_PASSWORD_SUCCESS, updatePassword),
  takeLatest(ADD_WORKOUT_TO_FAVOURITES_SUCCESS, addWorkoutFavourite),
  takeLatest(REMOVE_WORKOUT_FROM_FAVOURITES_SUCCESS, removeWorkoutFavourite),
  takeLatest(UPDATE_USER_SUCCESS, updateUser),
  takeLatest(REGISTER_USER_FOR_LIVE_EVENT_SUCCESS, registerLiveEvent),
  takeLatest(UPDATE_PAYMENT_METHOD_SUCCESS, updatePaymentMethod),
  takeLatest(SET_UI_VALUE, setUiValue),
  takeLatest(CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS, cancelSubscription),
  takeLatest(INCREMENT_WORKOUT_WATCHED_COUNT, incrementWorkoutWatched),
]

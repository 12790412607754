import * as actionTypes from './actionTypes'

export const getPaymentMethodRequest = () => ({
  type: actionTypes.GET_PAYMENT_METHOD_REQUEST,
})

export const getPaymentMethodSuccess = payload => ({
  type: actionTypes.GET_PAYMENT_METHOD_SUCCESS,
  ...payload,
})

export const getPaymentMethodError = error => ({
  type: actionTypes.GET_PAYMENT_METHOD_ERROR,
  error,
})

export const updatePaymentMethodRequest = (
  paymentMethodId,
  onSuccess,
  onError,
) => ({
  type: actionTypes.UPDATE_PAYMENT_METHOD_REQUEST,
  paymentMethodId,
  onSuccess,
  onError,
})

export const updatePaymentMethodSuccess = payload => ({
  type: actionTypes.UPDATE_PAYMENT_METHOD_SUCCESS,
  ...payload,
})

export const updatePaymentMethodError = error => ({
  type: actionTypes.UPDATE_PAYMENT_METHOD_ERROR,
  error,
})

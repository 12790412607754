import * as actionTypes from './actionTypes'

export const getUserRequest = () => ({
  type: actionTypes.GET_USER_REQUEST,
})

export const getUserSuccess = user => ({
  type: actionTypes.GET_USER_SUCCESS,
  user,
})

export const getUserError = error => ({
  type: actionTypes.GET_USER_ERROR,
  error,
})

export const updateUserRequest = (payload, onSuccess, onError) => ({
  type: actionTypes.UPDATE_USER_REQUEST,
  ...payload,
  onSuccess,
  onError,
})

export const updateUserSuccess = user => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  user,
})

export const updateUserError = error => ({
  type: actionTypes.UPDATE_USER_ERROR,
  error,
})

export const addWorkoutToFavouritesRequest = (
  workoutId,
  onSuccess,
  onError,
) => ({
  type: actionTypes.ADD_WORKOUT_TO_FAVOURITES_REQUEST,
  workoutId,
  onSuccess,
  onError,
})

export const addWorkoutToFavouritesSuccess = workoutId => ({
  type: actionTypes.ADD_WORKOUT_TO_FAVOURITES_SUCCESS,
  workoutId,
})

export const removeWorkoutFromFavouritesRequest = (
  workoutId,
  onSuccess,
  onError,
) => ({
  type: actionTypes.REMOVE_WORKOUT_FROM_FAVOURITES_REQUEST,
  workoutId,
  onSuccess,
  onError,
})

export const removeWorkoutFromFavouritesSuccess = workoutId => ({
  type: actionTypes.REMOVE_WORKOUT_FROM_FAVOURITES_SUCCESS,
  workoutId,
})

export const incrementWorkoutWatchedCount = workoutId => ({
  type: actionTypes.INCREMENT_WORKOUT_WATCHED_COUNT,
  workoutId,
})

import moment from 'moment'

export const transformLiveSchedule = response => {
  if (!response || response.empty) return null

  let liveScheduleById = {}

  response.forEach(doc => {
    liveScheduleById = {
      ...liveScheduleById,
      [doc.id]: {
        ...doc.data(),
        id: doc.id,
      },
    }
  })

  return liveScheduleById
}

export const transformLiveScheduleItem = (response, id) => {
  if (!response)
    return {
      id,
    }

  return {
    id,
    ...response.data(),
  }
}

export const isLiveScheduleItemLive = (startDateTime, endDateTime) => {
  const now = moment().valueOf()
  return startDateTime < now && endDateTime > now
}

export const isLiveScheduleItemPast = (startDateTime, endDateTime) => {
  const now = moment().valueOf()
  return startDateTime < now && endDateTime < now
}

export const isLiveScheduleItemFuture = (startDateTime, endDateTime) => {
  const now = moment().valueOf()
  return endDateTime > now && startDateTime > now
}

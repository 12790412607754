export const transformUser = (user, liveSchedule, workoutFavourites) => {
  if (!user) return null
  const { firstName, lastName, stripeCustomerId, freeTrialUsed } = user.data()

  let liveScheduleById = null

  if (liveSchedule && !liveSchedule.empty) {
    liveScheduleById = {}
    liveSchedule.forEach(doc => {
      liveScheduleById = {
        ...liveScheduleById,
        [doc.id]: {
          ...doc.data(),
          id: doc.id,
        },
      }
    })
  }

  let workoutFavouritesById = null

  if (workoutFavourites && !workoutFavourites.empty) {
    workoutFavouritesById = {}
    workoutFavourites.forEach(doc => {
      workoutFavouritesById = {
        ...workoutFavouritesById,
        [doc.id]: {
          ...doc.data(),
          id: doc.id,
        },
      }
    })
  }

  return {
    firstName,
    lastName,
    stripeCustomerId,
    liveScheduleById,
    workoutFavouritesById,
    freeTrialUsed,
  }
}

import { call, put, takeLatest, select, spawn } from 'redux-saga/effects'

import { envConfig } from 'shared/config'
import * as database from 'shared/firebase/database'
import { selectUserStripeCustomerId } from 'shared/modules/User/selectors'
import { authenticatedRequest } from 'shared/utils/RequestUtils'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import { transformPaymentMethod, transformStripePaymentMethod } from './utils'
import { UPDATE_PAYMENT_METHOD_URL } from './constants'

function* getPaymentMethod() {
  try {
    const customerId = yield select(selectUserStripeCustomerId)
    const response = yield call(database.getPaymentMethod, customerId)
    yield put(actions.getPaymentMethodSuccess(transformPaymentMethod(response)))
  } catch (error) {
    yield put(actions.getPaymentMethodError(error))
    yield spawn(throwSentryError, error)
  }
}

function* updatePaymentMethod({ paymentMethodId, onSuccess, onError }) {
  try {
    const customerId = yield select(selectUserStripeCustomerId)
    const options = {
      method: 'POST',
      body: JSON.stringify({ customerId, paymentMethodId }),
    }
    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${UPDATE_PAYMENT_METHOD_URL}`,
      options,
      // @NOTE allow 402 (card declined) responses so we can normalise the response and error
      { addResponseCodes: [402] },
    )
    if (response.error) throw response.error
    yield put(
      actions.updatePaymentMethodSuccess(
        transformStripePaymentMethod(response.paymentMethod),
      ),
    )
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.updatePaymentMethodError(error))
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

export default [
  takeLatest(actionTypes.GET_PAYMENT_METHOD_REQUEST, getPaymentMethod),
  takeLatest(actionTypes.UPDATE_PAYMENT_METHOD_REQUEST, updatePaymentMethod),
]

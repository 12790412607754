import React from 'react'
import styled from 'styled-components'

import { breakpoints } from 'web/theme'

const StyledListItem = styled(({ last, ...rest }) => <div {...rest} />)`
  width: calc(100vw / 1.1);
  flex-shrink: 0;
  padding: 8px;
  padding-right: ${({ last }) => (last ? '24px' : '8px')};
  @media (min-width: ${breakpoints.xs}) {
    width: calc(100vw / 1.3);
  }
  @media (min-width: ${breakpoints.sm}) {
    width: calc(100vw / 2.2);
  }
  @media (min-width: ${breakpoints.md}) {
    width: calc(100vw / 3.2);
  }
  @media (min-width: ${breakpoints.lg}) {
    width: calc(100vw / 3.4);
  }
  @media (min-width: ${breakpoints.xl}) {
    width: calc(100vw / 3.6);
  }
`

export default StyledListItem

import * as actionTypes from './actionTypes'

const initialState = {
  workoutCategoriesById: null,
  workoutCategoriesBySlug: null,
  workoutCategoriesTree: undefined,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WORKOUT_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GET_WORKOUT_CATEGORIES_SUCCESS:
      return {
        ...state,
        workoutCategoriesById: action.categories.byId,
        workoutCategoriesBySlug: action.categories.bySlug,
        workoutCategoriesTree: action.workoutCategoriesTree,
        loading: false,
      }
    case actionTypes.GET_WORKOUT_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

import React from 'react'
import Typography from '@material-ui/core/Typography'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { HelmetLocalised, Grid } from 'web/components'

import messages from './messages'

const Error404Page = () => (
  <>
    <HelmetLocalised
      title={messages.title}
      description={messages.description}
    />
    <PageWrapper>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h1" align="center">
            Oops...
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle1"
            align="center"
            color="textSecondary"
          >
            Sorry, we could not find that page.
          </Typography>
          <Typography variant="body1" align="center">
            The page might not exist anymore.
          </Typography>
        </Grid>
      </Grid>
    </PageWrapper>
  </>
)

export default Error404Page

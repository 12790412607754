import { createSelector } from 'reselect'
import get from 'lodash/get'
import { NAME } from './constants'

const selectUiState = state => state[NAME]

const selectUi = createSelector(selectUiState, uiState => uiState)

export const selectUiWelcomeMessageSeen = createSelector(selectUi, ui =>
  get(ui, 'welcomeMessageSeen'),
)

import React from 'react'
import Typography from '@material-ui/core/Typography'

import { WeeklyPlanContainer } from 'shared/modules/WeeklyPlan/components'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { HelmetLocalised, Grid } from 'web/components'
import { WeeklyPlan } from 'web/modules/WeeklyPlan/components'

import messages from './messages'

const WeeklyPlanPage = () => (
  <>
    <HelmetLocalised
      title={messages.title}
      description={messages.description}
    />
    <PageWrapper>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">Weekly Plan</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Take the effort out of workout selection and follow our Weekly Plan
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <WeeklyPlanContainer>
            {({ weeklyPlanArray, todaysWorkout, loading, error }) => (
              <WeeklyPlan
                weeklyPlanArray={weeklyPlanArray}
                todaysWorkout={todaysWorkout}
                loading={loading}
                error={error}
              />
            )}
          </WeeklyPlanContainer>
        </Grid>
      </Grid>
    </PageWrapper>
  </>
)

export default WeeklyPlanPage

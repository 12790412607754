import { all } from 'redux-saga/effects'

import authSagas from 'shared/modules/Auth/sagas'
import subscriptionSagas from 'shared/modules/Subscription/sagas'
import plansSagas from 'shared/modules/Plans/sagas'
import paymentMethodSagas from 'shared/modules/PaymentMethod/sagas'
import userSagas from 'shared/modules/User/sagas'
import workoutsSagas from 'shared/modules/Workouts/sagas'
import workoutCategoriesSagas from 'shared/modules/WorkoutCategories/sagas'
import equipmentSagas from 'shared/modules/Equipment/sagas'
import muscleGroupsSagas from 'shared/modules/MuscleGroups/sagas'
import liveScheduleSagas from 'shared/modules/LiveSchedule/sagas'
import weeklyPlanSagas from 'shared/modules/WeeklyPlan/sagas'
import promotionSagas from 'shared/modules/Promotion/sagas'
import invoicesSagas from 'shared/modules/Invoices/sagas'
import weeklyMantraSagas from 'shared/modules/WeeklyMantra/sagas'

import sagas from './sagas'

const sharedSagas = [
  ...authSagas,
  ...subscriptionSagas,
  ...plansSagas,
  ...paymentMethodSagas,
  ...userSagas,
  ...workoutsSagas,
  ...workoutCategoriesSagas,
  ...equipmentSagas,
  ...muscleGroupsSagas,
  ...liveScheduleSagas,
  ...weeklyPlanSagas,
  ...promotionSagas,
  ...invoicesSagas,
  ...weeklyMantraSagas,
]

export function* rootSaga() {
  yield all([...sharedSagas, ...sagas])
}

import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player/vimeo'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ReactGA from 'react-ga'

import { throwSentryError } from 'shared/utils/ErrorUtils'
import { incrementWorkoutWatchedCount } from 'shared/modules/User/actions'

import { colors } from 'web/theme'
import { CircularProgress, Alert } from 'web/components'

const Video = ({ id, workoutId, onReady, ...rest }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [showVideo, setShowVideo] = useState(false)
  const [watched, setWatched] = useState()
  const [error, setError] = useState(false)

  const handleOnReady = () => {
    if (onReady) onReady()
    setTimeout(() => {
      setShowVideo(true)
    }, 1500)
  }

  const handleOnProgress = ({ played }) => {
    if (!watched && played >= 0.97) {
      setWatched(true)
      dispatch(incrementWorkoutWatchedCount(workoutId))
    }
  }

  const handleOnError = () => {
    setError(true)
    throwSentryError(
      Error(`Video failed to load - ${id} - ${location.pathname}`),
    )
  }

  const handleOnEnded = () => {
    ReactGA.event({
      category: 'Workouts',
      action: 'Ended (complete)',
      label: workoutId,
    })
  }

  return (
    <div style={styles.playerWrapper}>
      <ReactPlayer
        style={styles.reactPlayer}
        url={`https://vimeo.com/${id}`}
        controls
        width="100%"
        height="100%"
        config={{
          vimeo: {
            playerOptions: {
              color: 'f5fe34',
            },
          },
        }}
        onReady={handleOnReady}
        onProgress={handleOnProgress}
        onError={handleOnError}
        onEnded={handleOnEnded}
        {...rest}
      />
      {!showVideo && (
        <div style={styles.placeholder}>
          <div style={styles.placeholderInner}>
            {error ? (
              <Alert severity="error" style={styles.errorNotice}>
                Oops, something went wrong trying to load this video. Please try
                refreshing the page. We have recorded this and will aim to fix
                it as soon as possible.
              </Alert>
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const styles = {
  playerWrapper: {
    position: 'relative',
    paddingBottom: '56.25%',
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  placeholder: {
    position: 'absolute',
    height: 0,
    paddingBottom: '56.25%',
    width: '100%',
    backgroundColor: colors.placeholderBg,
    zIndex: 1000,
  },
  placeholderInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorNotice: {
    marginRight: 20,
    marginLeft: 20,
  },
}

Video.propTypes = {
  id: PropTypes.string.isRequired,
  workoutId: PropTypes.string.isRequired,
  onReady: PropTypes.func,
}

export default memo(Video)

import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from 'web/components'
import CardLiveScheduleItemSummary from 'web/modules/LiveSchedule/components/CardLiveScheduleItemSummary'

const LiveScheduleList = ({ items, isPast }) => (
  <Grid container>
    {items.map(
      ({
        id,
        meetingNumber,
        name,
        image,
        summary,
        startDateTime,
        endDateTime,
        duration,
      }) => (
        <Grid key={id} item xs={12}>
          <CardLiveScheduleItemSummary
            id={id}
            meetingNumber={meetingNumber}
            name={name}
            image={image}
            summary={summary}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
            duration={duration}
            isPast={isPast}
          />
        </Grid>
      ),
    )}
  </Grid>
)

LiveScheduleList.propTypes = {
  items: PropTypes.array.isRequired,
  isPast: PropTypes.bool,
}

export default LiveScheduleList

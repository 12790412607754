import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import orderBy from 'lodash/orderBy'

import { getWorkoutsRequest } from 'shared/modules/Workouts/actions'
import {
  selectWorkoutsById,
  selectWorkoutsLoading,
  selectWorkoutsError,
} from 'shared/modules/Workouts/selectors'

const WorkoutsContainer = ({ children, render }) => {
  const dispatch = useDispatch()
  const workoutsById = useSelector(state => selectWorkoutsById(state))
  const loading = useSelector(state => selectWorkoutsLoading(state))
  const error = useSelector(state => selectWorkoutsError(state))

  const workouts = workoutsById
    ? orderBy(Object.values(workoutsById), ['publishAt'], ['desc'])
    : undefined

  const getWorkouts = useCallback(() => {
    dispatch(getWorkoutsRequest())
  }, [dispatch])

  useEffect(() => {
    getWorkouts()
  }, [getWorkouts])

  const renderProps = children || render

  return renderProps({
    getWorkouts,
    workouts,
    loading,
    error,
  })
}

WorkoutsContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
}

export default WorkoutsContainer

import React from 'react'
import styled from 'styled-components'

import { colors } from 'web/theme'
import HelperText from '../HelperText'

const StyledHelperText = styled(({ ...props }) => (
  <HelperText {...props} classes={{ error: 'error' }} />
))`
  &&.error {
    color: ${colors.textError};
  }
`

export default StyledHelperText

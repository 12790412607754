import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import InputAdornment from '@material-ui/core/InputAdornment'

import { componentPropType } from 'web/utils/PropTypeUtils'
import { colors } from 'web/theme'

import ButtonMaterialIcon from '../ButtonMaterialIcon'
import FormInputText from '../FormInputText'

import messages from './messages'

const FormInputPasswordToggle = ({
  showPasswordProps,
  showPasswordStrengthHelperText,
  onShowPasswordToggle,
  disabled,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const showPasswordToggle = () => {
    if (onShowPasswordToggle) {
      onShowPasswordToggle()
    }
    setShowPassword(!showPassword)
  }

  return (
    <FormInputText
      {...rest}
      type={showPassword ? 'text' : 'password'}
      helperText={
        showPasswordStrengthHelperText && (
          <FormattedMessage {...messages.passwordStrengthHelperText} />
        )
      }
      disabled={disabled}
      endAdornment={
        !disabled ? (
          <InputAdornment position="end">
            <ButtonMaterialIcon
              aria-label="password view"
              color={colors.formIcon}
              icon={showPassword ? 'VisibilityOff' : 'Visibility'}
              onClick={showPasswordToggle}
              size={20}
              style={styles.button}
            />
          </InputAdornment>
        ) : null
      }
    />
  )
}

const styles = {
  button: { height: 32 },
}

FormInputPasswordToggle.propTypes = {
  showPasswordProps: PropTypes.bool,
  showPasswordStrengthHelperText: PropTypes.bool,
  onShowPasswordToggle: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    componentPropType(FormattedMessage),
  ]).isRequired,
  disabled: PropTypes.bool,
}

export default FormInputPasswordToggle

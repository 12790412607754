import React from 'react'
import PropTypes from 'prop-types'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

const Alert = ({ children, ...rest }) => {
  const classes = useStyles()
  return (
    <MuiAlert classes={{ root: classes.root }} {...rest}>
      {children}
    </MuiAlert>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,
  },
}))

Alert.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Alert

import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'

import MaterialIcon from 'web/components/MaterialIcon'

const ButtonMaterialIcon = ({ icon, style, color, size, ...otherProps }) => {
  const spacing = size <= 28 ? 48 : size + 20
  const mixedStyle = {
    color,
    width: spacing,
    height: spacing,
    padding: 0,
    ...style,
  }

  return (
    <IconButton style={mixedStyle} {...otherProps}>
      <MaterialIcon icon={icon} color={color} size={size} />
    </IconButton>
  )
}

ButtonMaterialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number,
}

export default ButtonMaterialIcon

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.AccountPages.AccountUpgradePage.title',
    defaultMessage: 'Downgrade',
  },
  description: {
    id: 'web.Pages.AccountPages.AccountUpgradePage.description',
    defaultMessage: 'Downgrade your Subscription',
  },
})

import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'

import { selectUserFirstName } from 'shared/modules/User/selectors'

import { Card, Grid, Image } from 'web/components'
import signInImage from 'web/images/evolve-1.png'

const WelcomeBackMessage = () => {
  const firstName = useSelector(state => selectUserFirstName(state))
  return (
    <Card style={styles.card}>
      <Grid
        container
        alignItems="center"
        justify="center"
        style={styles.wrapper}
      >
        <Grid item xs={8} style={styles.textWrapper}>
          <Typography variant="h1">Welcome back, {firstName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Image src={signInImage} style={styles.image} alt="Welcome Back" />
        </Grid>
      </Grid>
    </Card>
  )
}

const styles = {
  wrapper: {
    maxWidth: 910,
    marginBottom: 10,
    backgroundColor: '#000000',
  },
  textWrapper: {
    padding: 25,
    color: '#FFFFFF',
  },
  image: {
    width: '100%',
    maxWidth: '250px',
    margin: '0 auto',
  },
  card: {
    border: 'none',
  },
}

export default WelcomeBackMessage

import { defineMessages } from 'react-intl'

export default defineMessages({
  bodyTitle: {
    id: 'web.Navigation.AccessLevelRequired.bodyTitle',
    defaultMessage: 'Premium Membership Required',
  },
  subtitle: {
    id: 'web.Navigation.AccessLevelRequired.bodyTitle',
    defaultMessage:
      'Upgrade your subscription today for instant access to the Live Class Schedule and Live Class catch ups!',
  },
})

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  getWorkoutBySlugRequest,
  getWorkoutFeedbackRequest,
} from 'shared/modules/Workouts/actions'
import * as selectors from 'shared/modules/Workouts/selectors'

const WorkoutContainer = ({ children, render, slug }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  const id = useSelector(state => selectors.selectWorkoutBySlugId(state, slug))

  const categories = useSelector(state =>
    selectors.selectWorkoutByIdCategories(state, id),
  )
  const description = useSelector(state =>
    selectors.selectWorkoutByIdDescription(state, id),
  )
  const duration = useSelector(state =>
    selectors.selectWorkoutByIdDuration(state, id),
  )
  const equipment = useSelector(state =>
    selectors.selectWorkoutByIdEquipment(state, id),
  )
  const image = useSelector(state =>
    selectors.selectWorkoutByIdImage(state, id),
  )
  const muscleGroups = useSelector(state =>
    selectors.selectWorkoutByIdMuscleGroups(state, id),
  )
  const name = useSelector(state => selectors.selectWorkoutByIdName(state, id))
  const publishAt = useSelector(state =>
    selectors.selectWorkoutByIdPublishAt(state, id),
  )
  const summary = useSelector(state =>
    selectors.selectWorkoutByIdSummary(state, id),
  )
  const vimeoId = useSelector(state =>
    selectors.selectWorkoutByIdVimeoId(state, id),
  )
  const watchCount = useSelector(state =>
    selectors.selectWorkoutByIdWatchCount(state, id),
  )
  const feedback = useSelector(state =>
    selectors.selectWorkoutByIdFeedbackArray(state, id),
  )

  const renderProps = children || render

  const onSuccess = () => setLoading(false)
  const onError = () => setError(true)

  useEffect(() => {
    dispatch(getWorkoutBySlugRequest(slug, onSuccess, onError))
  }, [dispatch, slug])

  useEffect(() => {
    if (id) {
      dispatch(getWorkoutFeedbackRequest(id))
    }
  }, [dispatch, id])

  return renderProps({
    id,
    categories,
    description,
    duration,
    equipment,
    image,
    muscleGroups,
    name,
    publishAt,
    summary,
    vimeoId,
    watchCount,
    feedback,
    loading,
    error,
  })
}

WorkoutContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
  slug: PropTypes.string.isRequired,
}

export default WorkoutContainer

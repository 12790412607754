import React from 'react'
import styled from 'styled-components'

// import { breakpoints } from 'web/theme'
import { Grid } from 'web/components'

const StyledGrid = styled(props => <Grid {...props} />)`
  display: flex;
  justify-content: flex-end;
  ${'' /* @media (min-width: ${breakpoints.sm}) {
    justify-content: flex-end;
  } */}
`

export default StyledGrid

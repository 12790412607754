import * as actionTypes from './actionTypes'

export const getPromotionRequest = (code, onSuccess, onError) => ({
  type: actionTypes.GET_PROMOTION_REQUEST,
  code,
  onSuccess,
  onError,
})

export const addPromotion = promotion => ({
  type: actionTypes.ADD_PROMOTION,
  promotion,
})

export const removePromotion = () => ({
  type: actionTypes.REMOVE_PROMOTION,
})

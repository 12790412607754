export const transformWorkouts = response => {
  if (!response || response.empty) return null

  let byId = {}
  let bySlug = {}

  response.forEach(doc => {
    byId = {
      ...byId,
      [doc.id]: {
        ...doc.data(),
        id: doc.id,
      },
    }
    bySlug = {
      ...bySlug,
      [doc.data().slug]: doc.id,
    }
  })

  return { byId, bySlug }
}

export const transformWorkout = (response, id) => {
  if (!response)
    return {
      id,
    }

  return {
    id,
    ...response.data(),
  }
}

export const transformWorkoutBySlug = response => {
  if (!response || response.empty) return null

  // @TODO log this as an error = duplicate results
  if (response.docs.length > 1) console.warn('more than one result...')

  return { ...response.docs[0].data(), id: response.docs[0].id }
}

export const transformWorkoutFeedback = (response, id) => {
  if (!response || response.empty) return null

  let results = []

  response.forEach(doc => {
    results = [
      ...results,
      {
        ...doc.data(),
        id: doc.id,
      },
    ]
  })

  return {
    id,
    results,
  }
}

import { defineMessages } from 'react-intl'

export default defineMessages({
  bodyTitle: {
    id: 'web.Subscription.SubscriptionCanUpgrade.bodyTitle',
    defaultMessage: 'Upgrade your Subscription today',
  },
  subtitle: {
    id: 'web.Subscription.SubscriptionCanUpgrade.subtitle',
    defaultMessage:
      'Upgrade your subscription today for instant access to the Live Class Schedule and Live Class catch ups!',
  },
  upgradedTitle: {
    id: 'web.Subscription.SubscriptionCanUpgrade.upgradedTitle',
    defaultMessage: 'Congratulations!',
  },
  upgradedSubtitle: {
    id: 'web.Subscription.SubscriptionCanUpgrade.upgradedSubtitle',
    defaultMessage: `You're already on the Evolve Premium subscription and have full access to the Evolve Platform.`,
  },
})

export const palette = {
  palette01: '#000000',
  palette02: '#8833FF',
  palette03: '#3ED102',
  palette04: '#f5f5f5',
  palette05: '#00ffd8',
  palette06: '#132490',
  palette07: '#FFFFFF',
  palette08: '#f44336',
  palette09: '#494949',
}

import * as actionTypes from './actionTypes'

export const getWorkoutCategoriesRequest = () => ({
  type: actionTypes.GET_WORKOUT_CATEGORIES_REQUEST,
})

export const getWorkoutCategoriesSuccess = (
  categories,
  workoutCategoriesTree,
) => ({
  type: actionTypes.GET_WORKOUT_CATEGORIES_SUCCESS,
  categories,
  workoutCategoriesTree,
})

export const getWorkoutCategoriesError = error => ({
  type: actionTypes.GET_WORKOUT_CATEGORIES_ERROR,
  error,
})

import { createSelector } from 'reselect'
import orderBy from 'lodash/orderBy'
import { NAME } from './constants'

export const selectPlansState = state => state[NAME]
export const getPlanByPriceIdState = (state, priceId) =>
  state[NAME]?.plansByPriceId?.[priceId]

const selectPlans = createSelector(selectPlansState, plansState => plansState)

export const selectPlansLoading = createSelector(
  selectPlans,
  plans => plans.loading,
)

export const selectPlansError = createSelector(
  selectPlans,
  plans => plans.error,
)

export const selectPlansByPriceId = createSelector(
  selectPlans,
  plans => plans.plansByPriceId,
)

export const selectPlansArray = createSelector(
  selectPlansByPriceId,
  plansByPriceId => Object.values(plansByPriceId),
)

export const selectPlansByPriceIdSortedByAccess = createSelector(
  selectPlansByPriceId,
  allPlansByPriceId =>
    allPlansByPriceId
      ? orderBy(allPlansByPriceId, ['access', 'frequency'], ['asc', 'asc'])
      : undefined,
)

export const selectAllPlansByAccess = createSelector(
  selectPlansByPriceId,
  plansByPriceId => {
    if (!plansByPriceId) return null
    return Object.values(plansByPriceId).reduce(
      (acc, plan) => ({
        ...acc,
        ...(acc[plan.access]
          ? { [plan.access]: { ...acc[plan.access], [plan.priceId]: plan } }
          : { [plan.access]: { [plan.priceId]: plan } }),
      }),
      {},
    )
  },
)

const getAccess = (state, access) => access

export const selectPlansByAccess = createSelector(
  selectAllPlansByAccess,
  getAccess,
  (allPlansByAccess, access) => {
    if (!allPlansByAccess || !access) return null
    return allPlansByAccess[access]
  },
)

export const selectPlansByAccessArray = createSelector(
  selectPlansByAccess,
  plansByAccess => (plansByAccess ? Object.values(plansByAccess) : undefined),
)

export const selectPlanAccessLevels = createSelector(
  selectAllPlansByAccess,
  allPlansByAccess =>
    allPlansByAccess ? Object.keys(allPlansByAccess) : undefined,
)

export const selectPlanByPriceIdAccess = createSelector(
  getPlanByPriceIdState,
  plan => plan?.access,
)

export const selectPlanByPriceIdFrequency = createSelector(
  getPlanByPriceIdState,
  plan => plan?.frequency,
)

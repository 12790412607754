import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

import { Grid, Alert } from 'web/components'
import WorkoutsList from 'web/modules/Workouts/components/WorkoutsList'
import WorkoutCategoriesList from 'web/modules/Workouts/components/WorkoutCategoriesList'
import WorkoutCategoryBreadcrumbs from 'web/modules/Workouts/components/WorkoutCategoryBreadcrumbs'

const WorkoutCategory = ({
  name,
  summary,
  workoutsArray,
  workoutCategoriesWithWorkouts,
}) => (
  <Grid container>
    <Grid item xs={12}>
      <WorkoutCategoryBreadcrumbs />
    </Grid>
    <Grid item xs={12}>
      {!name ? (
        <Alert severity="warning">That Category does not seem to exist</Alert>
      ) : (
        <>
          <Typography variant="h1">{name}</Typography>
          {summary && (
            <Typography variant="subtitle1" color="textSecondary">
              {summary}
            </Typography>
          )}
        </>
      )}
    </Grid>
    <Grid item xs={12}>
      {!workoutsArray?.length && !workoutCategoriesWithWorkouts?.length ? (
        <Alert severity="warning">
          Looks like this Category is empty at the moment! Check back soon for
          some Workouts.
        </Alert>
      ) : (
        <>
          {workoutCategoriesWithWorkouts?.length && (
            <WorkoutCategoriesList categories={workoutCategoriesWithWorkouts} />
          )}
          <WorkoutsList workouts={workoutsArray} />
        </>
      )}
    </Grid>
  </Grid>
)

WorkoutCategory.propTypes = {
  name: PropTypes.string,
  summary: PropTypes.string,
  workoutsArray: PropTypes.array,
  workoutCategoriesWithWorkouts: PropTypes.array,
}

export default WorkoutCategory

import Styled from 'styled-components'

import { breakpoints, appLayoutSizes } from 'web/theme'

const StyledBackground = Styled.div`
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : 'rgba(255, 255, 255, 0.8)'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1200;
  display: table;
  text-align: center;
  @media only screen and (min-width: ${breakpoints.md}) {
    margin-left: ${appLayoutSizes.drawerWidth}px;
    width: calc(100% - ${appLayoutSizes.drawerWidth}px);
  }
`
export default StyledBackground

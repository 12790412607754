import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'

import { CircularProgress, Alert, Grid } from 'web/components'
import {
  CardWorkoutSummary,
  WorkoutsCarousel,
} from 'web/modules/Workouts/components'

const WeeklyPlan = ({ weeklyPlanArray, todaysWorkout, loading, error }) => {
  if (error)
    return (
      <Alert severity="error">
        Oops, something went wrong trying to fetch the Weekly plan - please try
        refreshing the page.
      </Alert>
    )

  if (loading && !weeklyPlanArray) return <CircularProgress />

  if (!weeklyPlanArray || weeklyPlanArray.length !== 7)
    return (
      <Alert severity="warning">
        We could not find this weeks plan. Please try refreshing the page
      </Alert>
    )

  return (
    <Grid container>
      {todaysWorkout && (
        <>
          <Grid item xs={12}>
            <Typography variant="h3">
              Today&apos;s Workout: {moment().format('dddd')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CardWorkoutSummary
              id={todaysWorkout.id}
              name={todaysWorkout.name}
              image={todaysWorkout.image}
              slug={todaysWorkout.slug}
              summary={todaysWorkout.summary}
              duration={todaysWorkout.duration}
              publishAt={todaysWorkout.publishAt}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3">The rest of your week</Typography>
          </Grid>
          <Grid item xs={12}>
            <WorkoutsCarousel workouts={weeklyPlanArray} />
          </Grid>
        </>
      )}
    </Grid>
  )
}

WeeklyPlan.propTypes = {
  weeklyPlanArray: PropTypes.array,
  todaysWorkout: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

export default WeeklyPlan

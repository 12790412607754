import { createSelector } from 'reselect'
import get from 'lodash/get'

import { selectUserLiveScheduleById } from 'shared/modules/User/selectors'

import { NAME } from './constants'
import {
  isLiveScheduleItemLive,
  isLiveScheduleItemPast,
  isLiveScheduleItemFuture,
} from './utils'

const selectLiveScheduleState = state => state[NAME]
const getLiveScheduleItemById = (state, id) =>
  get(state[NAME], `liveScheduleById[${id}]`)

const selectLiveSchedule = createSelector(
  selectLiveScheduleState,
  liveScheduleState => liveScheduleState,
)

export const selectLiveScheduleLoading = createSelector(
  selectLiveSchedule,
  liveSchedule => liveSchedule.loading,
)

export const selectLiveScheduleError = createSelector(
  selectLiveSchedule,
  liveSchedule => liveSchedule.error,
)

export const selectLiveScheduleItemsById = createSelector(
  selectLiveSchedule,
  selectUserLiveScheduleById,
  (liveSchedule, userLiveScheduleById) => {
    if (!liveSchedule.liveScheduleById) return null
    if (!userLiveScheduleById) return liveSchedule.liveScheduleById
    return Object.values(liveSchedule.liveScheduleById).reduce((acc, item) => {
      if (userLiveScheduleById[item.id]) {
        return {
          ...acc,
          [item.id]: {
            ...item,
            joinUrl: userLiveScheduleById[item.id].joinUrl,
          },
        }
      }
      return {
        ...acc,
        [item.id]: item,
      }
    }, {})
  },
)

export const selectLiveScheduleFutureItemsById = createSelector(
  selectLiveScheduleItemsById,
  liveScheduleById => {
    if (liveScheduleById) {
      return Object.values(liveScheduleById).reduce((acc, liveSchedule) => {
        if (
          isLiveScheduleItemFuture(
            liveSchedule.endDateTime,
            liveSchedule.startDateTime,
          )
        )
          return {
            ...acc,
            [liveSchedule.id]: liveSchedule,
          }
        return {
          ...acc,
        }
      }, {})
    }
    return null
  },
)

export const selectLiveSchedulePastItemsById = createSelector(
  selectLiveScheduleItemsById,
  liveScheduleById => {
    if (liveScheduleById) {
      return Object.values(liveScheduleById).reduce((acc, liveSchedule) => {
        if (
          isLiveScheduleItemPast(
            liveSchedule.startDateTime,
            liveSchedule.endDateTime,
          )
        )
          return {
            ...acc,
            [liveSchedule.id]: liveSchedule,
          }
        return {
          ...acc,
        }
      }, {})
    }
    return null
  },
)

export const selectLiveScheduleActiveItemsById = createSelector(
  selectLiveScheduleItemsById,
  liveScheduleById => {
    if (liveScheduleById) {
      return Object.values(liveScheduleById).reduce((acc, liveSchedule) => {
        if (
          isLiveScheduleItemLive(
            liveSchedule.startDateTime,
            liveSchedule.endDateTime,
          )
        )
          return {
            ...acc,
            [liveSchedule.id]: liveSchedule,
          }
        return {
          ...acc,
        }
      }, {})
    }
    return null
  },
)

const selectLiveScheduleItemById = createSelector(
  getLiveScheduleItemById,
  liveScheduleItem => liveScheduleItem,
)

export const selectLiveScheduleItemByIdCatchupUrl = createSelector(
  selectLiveScheduleItemById,
  liveScheduleItem => get(liveScheduleItem, 'catchupUrl'),
)

export const selectLiveScheduleItemByIdDescription = createSelector(
  selectLiveScheduleItemById,
  liveScheduleItem => get(liveScheduleItem, 'description'),
)

export const selectLiveScheduleItemByIdEndDateTime = createSelector(
  selectLiveScheduleItemById,
  liveScheduleItem => get(liveScheduleItem, 'endDateTime'),
)

export const selectLiveScheduleItemByIdMeetingNumber = createSelector(
  selectLiveScheduleItemById,
  liveScheduleItem => get(liveScheduleItem, 'meetingNumber'),
)

export const selectLiveScheduleItemByIdMeetingPassword = createSelector(
  selectLiveScheduleItemById,
  liveScheduleItem => get(liveScheduleItem, 'meetingPassword'),
)

export const selectLiveScheduleItemByIdName = createSelector(
  selectLiveScheduleItemById,
  liveScheduleItem => get(liveScheduleItem, 'name'),
)

export const selectLiveScheduleItemByIdStartDateTime = createSelector(
  selectLiveScheduleItemById,
  liveScheduleItem => get(liveScheduleItem, 'startDateTime'),
)

export const selectLiveScheduleItemByIdSummary = createSelector(
  selectLiveScheduleItemById,
  liveScheduleItem => get(liveScheduleItem, 'summary'),
)

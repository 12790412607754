import { DEFAULT_LOCALE } from 'shared/config/constants'
import * as actionTypes from './actionTypes'

const initialState = {
  locale: DEFAULT_LOCALE,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_LOCALE:
      return { locale: action.locale }
    default:
      return state
  }
}

import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import { useDispatch } from 'react-redux'

import {
  validateRequired,
  validateMaxLength500,
  composeValidators,
} from 'shared/utils/FormUtils'
import { InputGroup } from 'shared/components'
import { postWorkoutFeedbackRequest } from 'shared/modules/Workouts/actions'

import {
  Grid,
  FormInputText,
  ButtonPrimary,
  FormErrorDisplay,
  Alert,
} from 'web/components'

import { AlertTitle } from '@material-ui/lab'
import messages from './messages'

const WorkoutFeedbackForm = ({ workoutId }) => {
  const [displaySuccess, setDisplaySuccess] = useState()
  const [displayError, setDisplayError] = useState()
  const [loading, setLoading] = useState()
  const dispatch = useDispatch()

  const handleSuccess = () => {
    setLoading(false)
    setDisplaySuccess(true)
  }

  const handleError = () => {
    setLoading(false)
    setDisplayError(true)
  }

  const handleFormSubmit = ({ feedback }) => {
    setLoading(true)
    dispatch(
      postWorkoutFeedbackRequest(
        { feedback, workoutId },
        handleSuccess,
        handleError,
      ),
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">Feedback</Typography>
      </Grid>
      <Grid item xs={12}>
        {displaySuccess ? (
          <Alert severit="success">
            <AlertTitle>Thanks for the feedback!</AlertTitle>
            Your feedback has been submitted and will be reviewed shortly.
          </Alert>
        ) : (
          <Form onSubmit={handleFormSubmit}>
            {({ handleSubmit }) => (
              <Grid
                container
                component="form"
                onSubmit={handleSubmit}
                noValidate
              >
                {displayError && (
                  <Grid item xs={12}>
                    <FormErrorDisplay error={displayError} />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <InputGroup
                    name="feedback"
                    Component={FormInputText}
                    validate={composeValidators(
                      validateRequired,
                      validateMaxLength500,
                    )}
                    placeholder={messages.feedbackPlaceholder}
                    label={<FormattedMessage {...messages.feedbackLabel} />}
                    required
                    hiddenLabel
                    multiline={5}
                  />
                </Grid>
                <Grid item xs={12} style={styles.buttonGrid}>
                  <ButtonPrimary
                    type="submit"
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    <FormattedMessage {...messages.submitButton} />
                  </ButtonPrimary>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Grid>
    </Grid>
  )
}

const styles = {
  buttonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  divider: {
    marginTop: 12,
    marginBottom: 16,
  },
}

export default WorkoutFeedbackForm

import React from 'react'
import styled from 'styled-components'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import { colors, fonts } from 'web/theme'

const StyledOutlinedInput = styled(({ inactive, ...otherProps }) => (
  <OutlinedInput
    {...otherProps}
    classes={{
      root: 'root',
      disabled: 'disabled',
      input: 'input',
      error: 'error',
      multiline: 'multiline',
      focused: 'focused',
    }}
  />
))`
  &.root {
    width: 100%;
    border-radius: 0;
    &:hover {
      fieldset {
        border-color: ${colors.inputBorderHover};
      }
    }
    .input {
      font-size: 14px;
      font-family: ${fonts.first};
      color: ${colors.inputText};
      padding: 16px 12px;
      ${({ startAdornment }) => startAdornment && 'padding-left: 0px;'}
      ${({ endAdornment }) => endAdornment && 'padding-right: 0px;'}
    }
    fieldset {
      transition: border 0.2s;
      border-color: ${colors.inputBorder};
    }
    &.focused {
      fieldset {
        border-color: ${colors.inputBorderFocused};
        border-width: 1px;
      }
    }
    &.error {
      fieldset {
        border-color: ${colors.inputBorderError};
      }
    }
    &.multiline {
      .input {
        padding: 0;
      }
    }
    &.disabled {
      fieldset {
        border-color: ${colors.inputBorderDisabled};
      }
      .input {
        color: ${colors.inputTextDisabled};
        &:disabled {
          // @NOTE This is a fix for Safari
          -webkit-text-fill-color: ${colors.inputTextDisabled};
        }
      }
    }
  }
`

export default StyledOutlinedInput

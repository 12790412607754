import React from 'react'
import PropTypes from 'prop-types'

import { throwSentryError } from 'shared/utils/ErrorUtils'

import { Error } from './components'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    })
    throwSentryError(error)
  }

  render() {
    const { children } = this.props
    const { error, errorInfo } = this.state
    if (error) {
      return <Error error={error} errorInfo={errorInfo} />
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary

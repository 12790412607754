import React from 'react'
import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'
import { useIntl } from 'react-intl'

import { colors, fonts } from 'web/theme'

import messages from './messages'

const StyledInputLabel = styled(
  ({ inactive, children, required, hidden, ...otherProps }) => {
    const intl = useIntl()
    return (
      <InputLabel
        {...otherProps}
        classes={{
          root: 'root',
          focused: 'focused',
          asterisk: 'asterisk',
          shrink: 'shrink',
          error: 'error',
          disabled: 'disabled',
        }}
      >
        {children}
        {!required && (
          <span style={styles.notRequired}>
            {intl.formatMessage(messages.notRequired)}
          </span>
        )}
      </InputLabel>
    )
  },
)`
  &.root {
    ${({ hidden }) => hidden && 'display: none;'}
    font-family: ${fonts.first};
    background-color: ${colors.inputBg};
    color: ${colors.label};
    font-size: 14px;
    transform: translate(12px, 16.5px) scale(1);
    &.focused {
      color: ${colors.labelFocused};
    }
    &.shrink {
      transform: translate(12px, -5px) scale(0.85);
    }
    &.error {
      color: ${colors.labelError};
    }
    &.disabled {
      color: ${colors.labelDisabled};
    }
    .asterisk {
      display: none;
    }
  }
`

const styles = {
  notRequired: {
    color: colors.placeholder,
    marginLeft: 4,
  },
}

export default StyledInputLabel

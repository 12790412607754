import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { emailCensor } from 'shared/utils/StringUtils'
import { signOutRequest } from 'shared/modules/Auth/actions'
import { selectAuthEmail } from 'shared/modules/Auth/selectors'

import routes from 'web/routing/routes'
import { Alert, ButtonSecondary } from 'web/components'

const RegisteringAs = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const email = useSelector(state => selectAuthEmail(state))
  const handleNotYou = () => {
    dispatch(signOutRequest())
    history.push(routes.SignInPage.routPath)
  }

  return (
    <Alert
      severity="info"
      variant="outlined"
      action={
        <ButtonSecondary color="inherit" size="small" onClick={handleNotYou}>
          Not you?
        </ButtonSecondary>
      }
    >
      You are registering as {email && emailCensor(email)}
    </Alert>
  )
}

export default RegisteringAs

import { defineMessages } from 'react-intl'

export default defineMessages({
  canceling: {
    id: 'web.Subscription.SubscriptionDetails.AddPromotion.canceling',
    defaultMessage:
      "You can't apply promotions with your subscription set to cancel.",
  },
  downgrading: {
    id: 'web.Subscription.SubscriptionDetails.AddPromotion.downgrading',
    defaultMessage:
      'You will be able to apply promotions when your downgrade has completed.',
  },
})

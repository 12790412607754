import React from 'react'
import { useSelector } from 'react-redux'

import { selectSubscriptionScheduleId } from 'shared/modules/Subscription/selectors'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { SubscriptionCanDowngrade } from 'web/modules/Subscription/components'
import { HelmetLocalised, Grid } from 'web/components'

import messages from './messages'

const AccountDowngradePage = () => {
  const scheduleId = useSelector(state => selectSubscriptionScheduleId(state))

  return (
    <>
      <HelmetLocalised
        title={messages.title}
        description={messages.description}
      />
      <PageWrapper>
        <Grid container justify="center">
          <SubscriptionCanDowngrade subscriptionScheduleId={scheduleId} />
        </Grid>
      </PageWrapper>
    </>
  )
}

export default AccountDowngradePage

import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'

import { updatePaymentMethodRequest } from 'shared/modules/PaymentMethod/actions'
import { selectPaymentMethodLoading } from 'shared/modules/PaymentMethod/selectors'

import { Grid } from 'web/components'
import StripeElementsCardDetailsForm from 'web/modules/Subscription/components/StripeElementsCardDetailsForm'

const UpdatePaymentMethodForm = ({ onSuccess, onCancel }) => {
  const [loading, setLoading] = useState()
  const [formError, setFormError] = useState()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const paymentMethodLoading = useSelector(state =>
    selectPaymentMethodLoading(state),
  )

  const handleSuccess = () => {
    setLoading(false)
    onSuccess()
  }

  const onError = error => {
    setLoading(false)
    setFormError(error.code)
  }

  const handleSubmit = async event => {
    setLoading(true)
    event.preventDefault()
    if (!stripe || !elements) {
      setLoading(false)
      return null
    }
    const cardElement = elements.getElement(CardNumberElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    if (error) {
      setFormError(error.code)
    } else {
      const paymentMethodId = paymentMethod.id
      dispatch(
        updatePaymentMethodRequest(paymentMethodId, handleSuccess, onError),
      )
    }

    return null
  }

  const handleChange = () => setFormError(undefined)

  return (
    <Grid container>
      <Grid item xs={12}>
        <StripeElementsCardDetailsForm
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          onCancel={onCancel}
          error={formError}
          disabled={!stripe}
          loading={loading || paymentMethodLoading}
          submitButtonText="Update Card Details"
        />
      </Grid>
    </Grid>
  )
}

UpdatePaymentMethodForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default memo(UpdatePaymentMethodForm)

import { Typography } from '@material-ui/core'
import React from 'react'
import moment from 'moment'

import { Alert, Grid } from 'web/components'
import { useSelector } from 'react-redux'
import { selectAuthUid } from 'shared/modules/Auth/selectors'

const WorkoutFeedbackList = ({ feedback }) => {
  const uid = useSelector(state => selectAuthUid(state))
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">Community Feedback</Typography>
      </Grid>
      {feedback.map(item => (
        <Grid item xs={12} key={item.id}>
          <Alert
            severity="info"
            icon={false}
            style={{ whiteSpace: 'pre-line' }}
          >
            {item.feedback}
          </Alert>
          <Typography variant="body2" color="textSecondary" align="right">
            {uid === item.uid ? 'You' : item.name} |{' '}
            {moment(item.timestamp).fromNow()}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

export default WorkoutFeedbackList

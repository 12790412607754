import React from 'react'
import PropTypes from 'prop-types'
import MuiGrid from '@material-ui/core/Grid'

const Grid = ({ container, ...rest }) =>
  container ? (
    <MuiGrid spacing={2} {...rest} container={container} />
  ) : (
    <MuiGrid {...rest} />
  )

Grid.propTypes = {
  container: PropTypes.bool,
}

export default Grid

import React from 'react'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

import { shouldHideNav } from 'web/modules/PageLayout/utils'
import { appLayoutSizes, colors } from 'web/theme'

const PageWrapper = ({ children }) => {
  const location = useLocation()
  const classes = useStyles()

  return (
    <main
      className={classes.content}
      style={{
        ...(shouldHideNav(location.pathname) ? { marginLeft: '0' } : null),
      }}
    >
      {!shouldHideNav(location.pathname) && (
        <Hidden mdUp implementation="js">
          <div className={classes.toolbarSpacer} />
        </Hidden>
      )}
      {children}
      <footer className={classes.footer}>
        <Divider />
        <div className={classes.footerInner}>
          <Typography variant="body2">
            <a href="mailto:hello@joinevolve.co" className={classes.link}>
              Contact us
            </a>
          </Typography>
        </div>
      </footer>
    </main>
  )
}

const useStyles = makeStyles(theme => ({
  toolbarSpacer: theme.mixins.toolbar,
  content: {
    backgroundColor: 'white',
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginLeft: appLayoutSizes.drawerWidth,
    },
  },
  footer: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  footerInner: {
    padding: theme.spacing(3),
  },
  link: {
    color: colors.fontFirst,
  },
}))

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageWrapper

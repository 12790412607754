import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'

import { getComparisonPlanPercentageSaving } from 'shared/modules/Plans/utils'
import {
  plansMessages,
  frequencyMessages,
  planBenefitsMessages,
} from 'shared/modules/Plans/messages'
import { ACCESS_LEVELS, FREQUENCIES } from 'shared/modules/Plans/constants'
import { selectPlansByAccess } from 'shared/modules/Plans/selectors'

import { ButtonPrimary, Card } from 'web/components'

import messages from './messages'

const Plan = ({ frequency, price, onSelect, access }) => {
  const plansByAccess = useSelector(state => selectPlansByAccess(state, access))

  const comparisonPlan = Object.values(plansByAccess).find(
    plan => plan.frequency === FREQUENCIES.monthly,
  )
  const comparisonPlanAnnualPrice = comparisonPlan.price * 12

  return (
    <Card>
      <CardContent>
        <Typography align="center" color="textSecondary">
          <FormattedMessage {...frequencyMessages[frequency]} />
        </Typography>
        <Typography variant="h3" align="center">
          <FormattedMessage {...plansMessages[ACCESS_LEVELS[access]]} />
        </Typography>
        <Typography align="center" style={styles.priceText}>
          <FormattedMessage {...messages.price} values={{ PRICE: price }} />
        </Typography>
        <Typography align="center" color="textSecondary" variant="body2">
          {frequency === FREQUENCIES.monthly ? (
            <FormattedMessage {...messages.cancelAnyTime} />
          ) : (
            <FormattedMessage
              {...messages.savings}
              values={{
                SAVING: getComparisonPlanPercentageSaving(
                  price,
                  comparisonPlanAnnualPrice,
                ),
              }}
            />
          )}
        </Typography>
        <ul>
          <FormattedMessage
            {...planBenefitsMessages[ACCESS_LEVELS[access]]}
            values={{ li: chunks => <li>{chunks}</li> }}
          />
        </ul>
        <div style={styles.buttonWrapper}>
          <ButtonPrimary onClick={onSelect}>Select Plan</ButtonPrimary>
        </div>
      </CardContent>
    </Card>
  )
}

const styles = {
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '15px',
  },
  priceText: {
    fontSize: 50,
  },
}

Plan.propTypes = {
  frequency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  access: PropTypes.number.isRequired,
}

export default Plan

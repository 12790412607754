export const transformPlans = response => {
  if (response.empty) return null

  let plansByPriceId = {}

  response.forEach(doc => {
    plansByPriceId = {
      ...plansByPriceId,
      [doc.data().priceId]: {
        ...doc.data(),
        id: doc.id,
      },
    }
  })

  return plansByPriceId
}

export const getMonthlyPriceFromFrequency = (frequency, price) =>
  frequency === 'yearly' ? (price / 12).toFixed(2) : price

export const getComparisonPlanPercentageSaving = (
  planPrice,
  comparisonPlanAnnualPrice,
) => {
  const saving = planPrice - comparisonPlanAnnualPrice
  return Math.abs(Math.round((saving / comparisonPlanAnnualPrice) * 100))
}

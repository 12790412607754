const routes = {
  DefaultPage: {
    routePath: '/',
  },

  // Not authenticated
  SignInPage: {
    routePath: '/sign-in',
  },
  ResetPasswordPage: {
    routePath: '/reset-password',
  },

  // Registration Pages
  RegistrationUserDetailsPage: {
    routePath: '/register',
  },
  RegistrationPricePlanPage: {
    routePath: '/register/price-plan',
  },
  RegistrationBillingDetailsPage: {
    routePath: '/register/billing-details',
  },

  // Free Trial Registration pages
  RegistrationFreeTrialUserDetailsPage: {
    routePath: '/register-free-trial',
  },
  RegistrationFreeTrialPricePlanPage: {
    routePath: '/register-free-trial/price-plan',
  },
  RegistrationFreeTrialBillingDetailsPage: {
    routePath: '/register-free-trial/billing-details',
  },

  // Subscribed
  DashboardPage: {
    routePath: '/home',
  },
  AccountDetailsPage: {
    routePath: '/account',
  },
  AccountUpgradePage: {
    routePath: '/account/upgrade',
  },
  AccountDowngradePage: {
    routePath: '/account/downgrade',
  },
  WorkoutsPage: {
    routePath: '/workouts',
  },
  WorkoutPreviewPage: {
    routeName: '/workouts/p/',
    routePath: '/workouts/p/:id',
  },
  WorkoutPage: {
    routeName: '/workouts/',
    routePath: '/workouts/:slug',
  },
  WorkoutCategoryPage: {
    routeName: '/workout-category/',
    routePath: '/workout-category/:slug*',
  },
  LiveSchedulePage: {
    routePath: '/live-classes-schedule',
  },
  LiveScheduleItemPage: {
    routeName: '/live-classes-schedule/',
    routePath: '/live-classes-schedule/:id',
  },
  FavouritesPage: {
    routePath: '/favourites',
  },
  WeeklyPlanPage: {
    routePath: '/weekly-plan',
  },
}

export default routes

import { call, put, takeLatest, spawn } from 'redux-saga/effects'

import * as database from 'shared/firebase/database'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import { transformMuscleGroups } from './utils'

export function* getMuscleGroups() {
  try {
    const response = yield call(database.getMuscleGroups)
    yield put(actions.getMuscleGroupsSuccess(transformMuscleGroups(response)))
  } catch (error) {
    yield put(actions.getMuscleGroupsError(error))
    yield spawn(throwSentryError, error)
  }
}

export default [
  takeLatest(actionTypes.GET_MUSCLE_GROUPS_REQUEST, getMuscleGroups),
]

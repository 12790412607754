import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import { SUBSCRIPTION_STATUS } from 'shared/modules/Subscription/constants'
import { SubscriptionContainer } from 'shared/modules/Subscription/components'
import { selectAuthSignedIn } from 'shared/modules/Auth/selectors'

import routes from 'web/routing/routes'
import { PageWrapper } from 'web/modules/PageLayout/components'
import { CircularProgress, Grid } from 'web/components'

const SubscribedRoute = ({ component: Component, ...otherProps }) => {
  const signedIn = useSelector(state => selectAuthSignedIn(state))

  const authenticatedRedirectTo = status => {
    if (
      status === SUBSCRIPTION_STATUS.canceled ||
      status === SUBSCRIPTION_STATUS.incompleteExpired
    ) {
      // users subscription has been canceled either by the user or automatically after exhaused attempts.
      // Or it's no longer valid. The user needs to start again with a NEW subscription.
      return {
        pathname: routes.RegistrationPricePlanPage.routePath,
        state: { message: 'expired' },
      }
    }
    if (status === SUBSCRIPTION_STATUS.incomplete) {
      // users subscription has not been completed yet. User should retry their subscription
      return {
        pathname: routes.RegistrationPricePlanPage.routePath,
        state: { message: 'retry' },
      }
    }
    // default to return to SignUp (start)
    return {
      pathname: routes.RegistrationPricePlanPage.routePath,
      state: { message: 'restart' },
    }
  }

  return (
    <Route
      {...otherProps}
      render={routeProps => {
        if (!signedIn) {
          return (
            <Redirect
              to={{
                pathname: routes.SignInPage.routePath,
                state: { from: routeProps.location },
              }}
              push
            />
          )
        }

        return (
          <SubscriptionContainer>
            {({ status, loading, error }) => {
              if (loading && !status) {
                return (
                  <PageWrapper>
                    <CircularProgress />
                  </PageWrapper>
                )
              }
              if (error) {
                return (
                  <PageWrapper>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant="body1">
                          Something went wrong loading your subscription...
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Please try Signing out and back in again.
                        </Typography>
                      </Grid>
                    </Grid>
                  </PageWrapper>
                )
              }
              // @NOTE Allow pastDue as we will try to get them to update their card details
              if (
                status !== SUBSCRIPTION_STATUS.active &&
                status !== SUBSCRIPTION_STATUS.pastDue &&
                status !== SUBSCRIPTION_STATUS.trialing
              )
                return <Redirect to={authenticatedRedirectTo(status)} push />
              return <Component {...routeProps} {...otherProps} />
            }}
          </SubscriptionContainer>
        )
      }}
    />
  )
}

SubscribedRoute.propTypes = {
  component: PropTypes.func.isRequired,
}

export default SubscribedRoute

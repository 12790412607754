import { defineMessages } from 'react-intl'

export default defineMessages({
  submitButton: {
    id: 'web.Promotion.PromotionCodeForm.submitButton',
    defaultMessage: 'Add Promotion',
  },
  success: {
    id: 'web.Promotion.PromotionCodeForm.success',
    defaultMessage: 'Success...',
  },
  codeLabel: {
    id: 'web.Promotion.PromotionCodeForm.codeLabel',
    defaultMessage: 'Promotion Code',
  },
  enterCodeButton: {
    id: 'web.Promotion.PromotionCodeForm.enterCodeButton',
    defaultMessage: 'Enter Promotion Code',
  },
})

export const errorMessages = defineMessages({
  'promotion-not-found': {
    id: 'web.Promotion.PromotionCodeForm.errorMessages.promotion-not-found',
    defaultMessage: 'Promotion not found or invalid.',
  },
  defaultMessage: {
    id: 'web.Promotion.PromotionCodeForm.errorMessages.defaultMessage',
    defaultMessage: 'Sorry, something went wrong. Please try again.',
  },
})

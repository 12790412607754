import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.AccountPages.BillingDetailsPage.title',
    defaultMessage: 'Billing Details',
  },
  description: {
    id: 'web.Pages.AccountPages.BillingDetailsPage.description',
    defaultMessage: 'Update your billing details',
  },
})

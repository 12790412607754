import React from 'react'
import PropTypes from 'prop-types'
import { Link as RrdLink } from 'react-router-dom'

import { colors } from 'web/theme'

const Link = ({ to, children, ...otherProps }) =>
  /^(http|https):\/\//.test(to) ? (
    <a href={to} {...otherProps} style={styles}>
      {children}
    </a>
  ) : (
    <RrdLink to={to} {...otherProps} style={styles}>
      {children}
    </RrdLink>
  )

const styles = {
  color: colors.fontFirst,
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
}

export default Link

import React from 'react'
import { useParams } from 'react-router-dom'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { HelmetLocalised } from 'web/components'
import { LiveScheduleItemContainer } from 'shared/modules/LiveSchedule/components'
import { LiveScheduleItem } from 'web/modules/LiveSchedule/components'

import messages from './messages'

const LiveScheduleItemPage = () => {
  const { id } = useParams()
  return (
    <>
      <HelmetLocalised
        title={messages.title}
        description={messages.description}
      />
      <PageWrapper>
        <LiveScheduleItemContainer id={id}>
          {liveSchedule => <LiveScheduleItem id={id} {...liveSchedule} />}
        </LiveScheduleItemContainer>
      </PageWrapper>
    </>
  )
}

export default LiveScheduleItemPage

import { createSelector } from 'reselect'
import get from 'lodash/get'

import {
  selectPlansByPriceId,
  selectPlansArray,
} from 'shared/modules/Plans/selectors'

import { NAME } from './constants'

export const selectSubscriptionState = state => state[NAME]

const selectSubscription = createSelector(
  selectSubscriptionState,
  subscriptionState => subscriptionState,
)

export const selectSubscriptionLoading = createSelector(
  selectSubscription,
  subscription => subscription.loading,
)

export const selectSubscriptionError = createSelector(
  selectSubscription,
  subscription => subscription.error,
)

export const selectSubscriptionStatus = createSelector(
  selectSubscription,
  subscription => subscription.status,
)

export const selectSubscriptionId = createSelector(
  selectSubscription,
  subscription => subscription.id,
)

export const selectSubscriptionLatestInvoicePaymentIntentStatus = createSelector(
  selectSubscription,
  subscription => subscription.latestInvoicePaymentIntentStatus,
)

export const selectSubscriptionLatestInvoiceId = createSelector(
  selectSubscription,
  subscription => subscription.latestInvoiceId,
)

export const selectSubscriptionPriceId = createSelector(
  selectSubscription,
  subscription => subscription.priceId,
)

export const selectSubscriptionLastLoaded = createSelector(
  selectSubscription,
  subscription => subscription.lastLoaded,
)

export const selectSubscriptionCancelAtPeriodEnd = createSelector(
  selectSubscription,
  subscription => subscription.cancelAtPeriodEnd,
)

export const selectSubscriptionCurrentPeriodStart = createSelector(
  selectSubscription,
  subscription => subscription.currentPeriodStart,
)

export const selectSubscriptionCurrentPeriodEnd = createSelector(
  selectSubscription,
  subscription => subscription.currentPeriodEnd,
)

const selectSubscriptionPromotion = createSelector(
  selectSubscription,
  subscription => subscription.promotion,
)

export const selectSubscriptionPromotionCode = createSelector(
  selectSubscriptionPromotion,
  subscriptionPromotion => get(subscriptionPromotion, 'promotionCode'),
)

export const selectSubscriptionPromotionDescription = createSelector(
  selectSubscriptionPromotion,
  subscriptionPromotion => get(subscriptionPromotion, 'description'),
)

export const selectSubscriptionPromotionName = createSelector(
  selectSubscriptionPromotion,
  subscriptionPromotion => get(subscriptionPromotion, 'name'),
)

const selectSubscriptionPlan = createSelector(
  selectSubscriptionPriceId,
  selectPlansByPriceId,
  (priceId, plansByPriceId) => {
    if (!priceId || !plansByPriceId) return undefined
    return plansByPriceId[priceId]
  },
)

export const selectSubscriptionAccess = createSelector(
  selectSubscriptionPlan,
  plan => plan?.access,
)

export const selectSubscriptionPrice = createSelector(
  selectSubscriptionPlan,
  plan => plan?.price,
)

export const selectSubscriptionFrequency = createSelector(
  selectSubscriptionPlan,
  plan => plan?.frequency,
)

export const selectSubscriptionScheduleId = createSelector(
  selectSubscription,
  subscription => subscription.scheduleId,
)

const getRequiredAccessLevel = (state, requiredAccessLevel) =>
  requiredAccessLevel

const selectSubscriptionUpgradeOption = createSelector(
  selectSubscriptionPriceId,
  selectPlansByPriceId,
  getRequiredAccessLevel,
  (priceId, plansByPriceId, requiredAccessLevel) => {
    if (!priceId || !plansByPriceId || !requiredAccessLevel) return null
    const currentPlan = plansByPriceId[priceId]
    const option = Object.values(plansByPriceId).find(
      plan =>
        plan.frequency === currentPlan.frequency &&
        plan.access >= requiredAccessLevel,
    )
    return option
  },
)

export const selectSubscriptionUpgradeOptionPriceId = createSelector(
  selectSubscriptionUpgradeOption,
  upgradeOption => upgradeOption?.priceId,
)

export const selectSubscriptionUpgradeOptionFrequency = createSelector(
  selectSubscriptionUpgradeOption,
  upgradeOption => upgradeOption?.frequency,
)

export const selectSubscriptionUpgradeOptionPrice = createSelector(
  selectSubscriptionUpgradeOption,
  upgradeOption => upgradeOption?.price,
)

// @NOTE downgrade is hard coded to access of 10
export const selectSubscriptionDowngradePlan = createSelector(
  selectSubscriptionFrequency,
  selectPlansArray,
  (frequency, plans) => {
    if (!frequency || !plans?.length) return undefined
    return plans.find(
      plan => plan.frequency === frequency && plan.access === 10,
    )
  },
)

export const selectSubscriptionDowngradePriceId = createSelector(
  selectSubscriptionDowngradePlan,
  plan => plan?.priceId,
)

export const selectSubscriptionDowngradePrice = createSelector(
  selectSubscriptionDowngradePlan,
  plan => plan?.price,
)

/* eslint-disable global-require */

import areIntlLocalesSupported from 'intl-locales-supported'

import { DEFAULT_LOCALE } from 'shared/config/constants'
import enTranslationMessages from './translations/en.json'
import frTranslationMessages from './translations/fr.json'

export const appLocales = ['en', 'fr']

// Do we have an Intl we can use. Add it if not
if (!global.Intl) {
  global.Intl = require('intl')
}

// Determine if the built-in `Intl` has the locale data we need.
if (
  !areIntlLocalesSupported(appLocales, [
    Intl.PluralRules,
    Intl.RelativeTimeFormat,
    Intl.NumberFormat,
  ])
) {
  // `Intl` exists, but it doesn't have the data we need, so load the
  // polyfill and replace the constructors we need with the polyfill's.
  require('@formatjs/intl-pluralrules/polyfill')
  require('@formatjs/intl-pluralrules/locale-data/en')
  require('@formatjs/intl-pluralrules/locale-data/fr')

  require('@formatjs/intl-relativetimeformat/polyfill')
  require('@formatjs/intl-relativetimeformat/locale-data/en')
  require('@formatjs/intl-relativetimeformat/locale-data/fr')

  require('@formatjs/intl-numberformat/polyfill')
  require('@formatjs/intl-numberformat/locale-data/en')
  require('@formatjs/intl-numberformat/locale-data/fr')
}

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {}
  return Object.keys(messages).reduce((formattedMessages, key) => {
    let message = messages[key]
    if (!message && locale !== DEFAULT_LOCALE) {
      message = defaultFormattedMessages[key]
    }
    return Object.assign(formattedMessages, { [key]: message })
  }, {})
}

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  fr: formatTranslationMessages('fr', frTranslationMessages),
}

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.AccountPages.AccountUpgradePage.title',
    defaultMessage: 'Upgrade',
  },
  description: {
    id: 'web.Pages.AccountPages.AccountUpgradePage.description',
    defaultMessage: 'Upgrade your Subscription today',
  },
  bodyTitle: {
    id: 'web.Pages.AccountPages.AccountUpgradePage.bodyTitle',
    defaultMessage: 'Upgrade your Subscription today',
  },
  subtitle: {
    id: 'web.Pages.AccountPages.AccountUpgradePage.bodyTitle',
    defaultMessage:
      'Upgrade your subscription today for instant access to the Live Class Schedule and Live Class catch ups!',
  },
  upgradedTitle: {
    id: 'web.Pages.AccountPages.AccountUpgradePage.upgradedTitle',
    defaultMessage: 'Congratulations!',
  },
  upgradedSubtitle: {
    id: 'web.Pages.AccountPages.AccountUpgradePage.upgradedSubtitle',
    defaultMessage: `You're already on the Evolve Premium subscription and have full access to the Evolve Platform.`,
  },
})

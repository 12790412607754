import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import MuiDrawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Collapse from '@material-ui/core/Collapse'

import { envConfig } from 'shared/config'
import { signOutRequest } from 'shared/modules/Auth/actions'
import { selectWorkoutCategoriesTree } from 'shared/modules/WorkoutCategories/selectors'

import routes from 'web/routing/routes'
import evolveLogoLight from 'web/images/evolve-logo-light.png'
import { appLayoutSizes } from 'web/theme'
import { drawerLinks } from 'web/modules/PageLayout/messages'
import { Image } from 'web/components'

import { LinkListItem } from './components'
import messages from './messages'

const Drawer = ({ onDrawerClose, open, drawerRoutes, isAuthenticated }) => {
  const [showNested, setShowNested] = useState()
  const dispatch = useDispatch()
  const classes = useStyles()
  const workoutCategoriesTree = useSelector(state =>
    selectWorkoutCategoriesTree(state),
  )

  const handleSignOut = () => {
    dispatch(signOutRequest())
  }

  const getIconRight = isWorkouts => {
    if (isWorkouts && showNested) return 'ExpandLess'
    if (isWorkouts && !showNested) return 'ExpandMore'
    return undefined
  }

  const drawer = (
    <>
      <List>
        {drawerRoutes.map(({ name, routePath, icon }) => {
          const isWorkouts = routePath === routes.WorkoutsPage.routePath
          return (
            <span key={routePath}>
              <LinkListItem
                to={routePath}
                primary={name}
                icon={icon}
                onActivate={onDrawerClose}
                iconRight={getIconRight(isWorkouts, showNested)}
                iconRightOnClick={() =>
                  isWorkouts ? setShowNested(!showNested) : null
                }
              />
              {isWorkouts && (
                // Would need to be amended if more than one nested section.
                <Collapse in={showNested}>
                  {workoutCategoriesTree &&
                    workoutCategoriesTree.map(category =>
                      category.draft === 'Yes' ? null : (
                        <LinkListItem
                          key={category.id}
                          to={`${routes.WorkoutCategoryPage.routeName}${category.slug}`}
                          secondary={category.name}
                          onActivate={onDrawerClose}
                          nested
                        />
                      ),
                    )}
                </Collapse>
              )}
            </span>
          )
        })}
      </List>
      {isAuthenticated && (
        <>
          <Divider />
          <List>
            <LinkListItem
              secondary={drawerLinks.signOut}
              onClick={handleSignOut}
              onActivate={onDrawerClose}
            />
          </List>
        </>
      )}
      <div className={classes.versionWrapper}>
        <span className={classes.versionText}>
          <FormattedMessage
            {...messages.version}
            values={{
              VERSION: `${process.env.REACT_APP_VERSION} ${
                envConfig.ENV !== 'prod' ? `(${envConfig.ENV})` : ''
              }`,
            }}
          />
        </span>
      </div>
    </>
  )

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden mdUp implementation="js">
        <MuiDrawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={onDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </MuiDrawer>
      </Hidden>
      <Hidden smDown implementation="js">
        <MuiDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <Image alt="Evolve" src={evolveLogoLight} />
          <Divider />
          {drawer}
        </MuiDrawer>
      </Hidden>
    </nav>
  )
}

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: appLayoutSizes.drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: appLayoutSizes.drawerWidth,
    background: theme.palette.primary.main,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  versionWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  versionText: {
    fontSize: '0.875rem',
    padding: 10,
    color: theme.palette.text.disabled,
  },
}))

Drawer.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  drawerRoutes: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
}

export default memo(Drawer)

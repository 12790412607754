import { call, put, takeLatest, spawn } from 'redux-saga/effects'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import * as database from 'shared/firebase/database'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import { transformWeeklyMantra } from './utils'

function* getWeeklyMantra() {
  try {
    const response = yield call(database.getWeeklyMantra)
    if (response)
      yield put(actions.getWeeklyMantraSuccess(transformWeeklyMantra(response)))
  } catch (error) {
    yield put(actions.getWeeklyMantraError(error))
    yield spawn(throwSentryError, error)
  }
}

export default [
  takeLatest(actionTypes.GET_WEEKLY_MANTRA_REQUEST, getWeeklyMantra),
]

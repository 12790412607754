import orderBy from 'lodash/orderBy'

export const transformWorkoutCategories = response => {
  if (!response || response.empty) return null

  let byId = {}
  let bySlug = {}

  response.forEach(doc => {
    byId = {
      ...byId,
      [doc.id]: {
        ...doc.data(),
        id: doc.id,
      },
    }
    bySlug = {
      ...bySlug,
      [doc.data().slug]: doc.id,
    }
  })

  return { byId, bySlug }
}

export const transformCategoriesToTree = categoriesById => {
  const copyCat = Object.values(categoriesById).reduce(
    (acc, category) => ({
      ...acc,
      [category.id]: {
        ...category,
        level: category.slug.split('/').length - 1,
      },
    }),
    {},
  )

  const tree = Object.values(copyCat).reduce((acc, category) => {
    const current = copyCat[category.id]

    if (!current.parentCategory) {
      // if it doesn't have a parent push to root
      acc.push(current)
    } else {
      // eslint-disable-next-line
      copyCat[category.parentCategory].children ||
        (copyCat[category.parentCategory].children = []) // add the children array to the parent, if it doesn't exist
      copyCat[category.parentCategory].children.push(current) // push the current item to the parent children array
      // set the order of the children...
      copyCat[category.parentCategory].children = orderBy(
        copyCat[category.parentCategory].children,
        'order',
        'asc',
      )
    }

    return acc
  }, [])

  return orderBy(tree, 'order', 'asc')
}

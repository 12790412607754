import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { envConfig } from 'shared/config'
import ReactGA from 'react-ga'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import momentUtils from '@date-io/moment'
import CssBaseline from '@material-ui/core/CssBaseline'

import { ErrorBoundary } from 'shared/components'

import { muiTheme } from 'web/theme'
import Navigation from 'web/routing/navigation'

export const history = createBrowserHistory()

ReactGA.initialize(envConfig.GOOGLE_TRACKING_ID, { titleCase: false })

history.listen(location => {
  ReactGA.pageview(location.pathname)
})

const materialTheme = createMuiTheme(muiTheme)

const AppNavigation = () => (
  <ConnectedRouter history={history}>
    <ErrorBoundary>
      <ThemeProvider theme={materialTheme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={momentUtils}>
          <Navigation />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </ConnectedRouter>
)
export default AppNavigation

import { call, put, takeLatest, select, spawn } from 'redux-saga/effects'

import { authenticatedRequest } from 'shared/utils/RequestUtils'
import { envConfig } from 'shared/config'
import { selectSubscriptionId } from 'shared/modules/Subscription/selectors'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import { INVOICE_UPCOMING_URL } from './constants'
import { transformInvoice } from './utils'
import * as actionTypes from './actionTypes'
import * as actions from './actions'

function* getInvoiceUpcoming({ onSuccess, onError }) {
  try {
    const subscriptionId = yield select(selectSubscriptionId)
    if (!subscriptionId) throw Error('No customer ID found.')

    const options = {
      method: 'POST',
      body: JSON.stringify({ subscriptionId }),
    }

    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${INVOICE_UPCOMING_URL}`,
      options,
    )

    if (response.error || !response?.invoice) throw response.error
    yield put(
      actions.getInvoiceUpcomingSuccess(transformInvoice(response.invoice)),
    )
    if (onSuccess) onSuccess()
  } catch (error) {
    if (onError) onError(error)
    yield put(actions.getInvoiceUpcomingError(error))
    yield spawn(throwSentryError, error)
  }
}

export default [
  takeLatest(actionTypes.GET_INVOICE_UPCOMING_REQUEST, getInvoiceUpcoming),
]

import { palette } from 'shared/theme'
import { hexToRgba } from 'shared/utils/ThemeUtils'

export const colors = {
  fontFirst: palette.palette01,
  inputBg: palette.palette07,
  planSelectedBg: palette.palette04,
  gradientFirstBgTop: palette.palette02,
  gradientFirstBgBottom: palette.palette03,
  gradientSecondBgTop: palette.palette02,
  gradientSecondBgBottom: palette.palette05,
  gradientThirdBgTop: palette.palette06,
  gradientThirdBgBottom: palette.palette05,
  formIcon: palette.palette01,
  inputText: palette.palette01,
  inputTextDisabled: hexToRgba(palette.palette01, 0.6),
  inputBorder: palette.palette01,
  inputBorderFocused: palette.palette06,
  inputBorderError: palette.palette08,
  inputBorderDisabled: hexToRgba(palette.palette01, 0.6),
  inputBorderHover: palette.palette06,
  textError: palette.palette08,
  buttonEdit: palette.palette09,
  placeholderBg: palette.palette04,
  buttonPrimaryBg: palette.palette02,
  buttonPrimaryHighlightBg: palette.palette02,
  buttonPrimaryHighlightText: palette.palette07,
  drawerItemIcon: palette.palette07,
  drawerItemIconSelected: palette.palette01,
}

export const fonts = {
  first: "'Prompt', 'sans-serif'",
}

export const appLayoutSizes = {
  drawerWidth: 300,
}

export const rawBreakpoints = {
  xs: 480,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

export const breakpoints = {
  xs: `${rawBreakpoints.xs}px`,
  sm: `${rawBreakpoints.sm}px`,
  md: `${rawBreakpoints.md}px`,
  lg: `${rawBreakpoints.lg}px`,
  xl: `${rawBreakpoints.xl}px`,
}

export const muiTheme = {
  typography: {
    fontFamily: ['Prompt', 'sans-serif'].join(','),
    h1: {
      fontSize: '1.6rem',
      letterSpacing: '0.05em',
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
    },
    h2: {
      fontSize: '1.25rem',
      letterSpacing: '0.05em',
      '@media (min-width:600px)': {
        fontSize: '1.6rem',
      },
    },
    h3: {
      fontSize: '1.1rem',
      letterSpacing: '0.05em',
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    h4: {
      letterSpacing: '0.05em',
    },
    h5: {
      letterSpacing: '0.05em',
    },
    h6: {
      letterSpacing: '0.05em',
    },
    body1: {
      letterSpacing: '0.03em',
    },
    body2: {
      letterSpacing: '0.03em',
    },
  },
  palette: {
    primary: {
      light: palette.palette01,
      main: palette.palette01,
      dark: colors.palette01,
      contrastText: colors.palette01,
    },
    secondary: {
      light: palette.palette02,
      main: palette.palette02,
      dark: colors.palette02,
      contrastText: colors.palette02,
    },
    text: {
      primary: palette.palette01,
    },
  },
}

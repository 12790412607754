import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'

import { useAuth } from 'shared/modules/Auth/hooks'

import { appLayoutSizes } from 'web/theme'
import { getDrawerRoutes, shouldHideNav } from 'web/modules/PageLayout/utils'
import evolveLogoLight from 'web/images/evolve-logo-light.png'
import { Image } from 'web/components'

import { Drawer } from './components'

const Navigation = () => {
  const { signedIn } = useAuth()
  const location = useLocation()

  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = useState(false)

  const routes = getDrawerRoutes(signedIn)

  const handleDrawerOpen = () => {
    setMobileOpen(true)
  }

  const handleDrawerClose = () => {
    setMobileOpen(false)
  }

  if (shouldHideNav(location.pathname)) return null

  return (
    <>
      <Hidden mdUp implementation="js">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.appBarLogo}>
              <Image alt="Evolve" src={evolveLogoLight} style={styles.logo} />
            </div>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Drawer
        onDrawerClose={handleDrawerClose}
        open={mobileOpen}
        drawerRoutes={routes}
        isAuthenticated={signedIn}
      />
    </>
  )
}

const styles = {
  logo: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    top: -2,
  },
}

const useStyles = makeStyles(theme => ({
  appBarLogo: {
    height: theme.mixins.toolbar.minHeight,
    padding: theme.spacing(1),
    marginRight: theme.spacing(6),
    width: '100%',
    '& img': {
      height: '100%',
      maxWidth: 'none',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginLeft: appLayoutSizes.drawerWidth,
    },
  },
}))

export default Navigation

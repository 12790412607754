import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'

import { selectWorkoutsByFavouitiesIds } from 'shared/modules/Workouts/selectors'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { HelmetLocalised, Grid, Alert } from 'web/components'
import { WorkoutsList } from 'web/modules/Workouts/components'

import messages from './messages'

const FavouritesPage = () => {
  const workoutFavourites = useSelector(state =>
    selectWorkoutsByFavouitiesIds(state),
  )

  return (
    <>
      <HelmetLocalised
        title={messages.title}
        description={messages.description}
      />
      <PageWrapper>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1">Favourites</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Bookmark your favourite Workouts so you can revisit them
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {workoutFavourites ? (
              <WorkoutsList workouts={Object.values(workoutFavourites)} />
            ) : (
              <Alert severity="info">
                You have not added any Favourites yet.
              </Alert>
            )}
          </Grid>
        </Grid>
      </PageWrapper>
    </>
  )
}

export default FavouritesPage

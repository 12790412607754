import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { colors } from 'web/theme'
import CircularProgress from 'web/components/CircularProgress'

const ButtonPrimary = ({ children, loading, disabled, highlight, ...rest }) => {
  const classes = useStyles()
  return (
    <Button
      variant="contained"
      color="secondary"
      classes={{
        root: highlight ? classes.buttonHighlight : classes.button,
        label: highlight ? classes.buttonHighlightText : classes.buttonText,
      }}
      disabled={loading || disabled}
      disableElevation
      size="large"
      {...rest}
    >
      {children}
      {loading && (
        <div style={styles.loadingWrapper}>
          <div style={styles.loadingInner}>
            <CircularProgress size={20} />
          </div>
        </div>
      )}
    </Button>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'uppercase',
    letterSpacing: '1.6px',
    borderRadius: 0,
  },
  buttonHighlight: {
    textTransform: 'uppercase',
    letterSpacing: '1.6px',
    borderRadius: 0,
    backgroundColor: colors.buttonPrimaryHighlightBg,
  },
  buttonText: {},
  buttonHighlightText: {
    color: colors.buttonPrimaryHighlightText,
    '&:hover': {
      color: '#FFFFFF',
    },
  },
}))

const styles = {
  loadingWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  loadingInner: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginTop: -10,
    marginLeft: -10,
  },
}

ButtonPrimary.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  highlight: PropTypes.bool,
}

export default ButtonPrimary

export const transformSignIn = authResponse => {
  if (!authResponse) return null
  const { user } = authResponse
  return {
    uid: user.uid,
    displayName: user.displayName,
    photoURL: user.photoURL,
    email: user.email,
    providers: user.providerData.reduce(
      (acc, provider) => ({
        ...acc,
        [provider.providerId]: provider.providerId,
      }),
      {},
    ),
  }
}

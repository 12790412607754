// @NOTE This could be consolidated with native/components/InputGroup/components/InputErrorMessage/messages

import { defineMessages } from 'react-intl'
import { validationCodes } from 'shared/utils/FormUtils'

export default defineMessages({
  [validationCodes.required]: {
    id: 'web.Components.FormInputErrorMessage.required',
    defaultMessage: 'This field is required',
  },
  [validationCodes.UKNationalInsurance]: {
    id: 'web.Components.FormInputErrorMessage.UKNationalInsurance',
    defaultMessage: 'Please enter a valid UK National Insurance number',
  },
  [validationCodes.UKHomeTelephoneNumber]: {
    id: 'web.Components.FormInputErrorMessage.UKHomeTelephoneNumber',
    defaultMessage:
      'Please enter a valid UK home number in the format 2012345678',
  },
  [validationCodes.UKMobileTelephoneNumber]: {
    id: 'web.Components.FormInputErrorMessage.UKMobileTelephoneNumber',
    defaultMessage:
      'Please enter a valid UK mobile number in the format 7960123456',
  },
  [validationCodes.emailAddress]: {
    id: 'web.Components.FormInputErrorMessage.emailAddress',
    defaultMessage: 'Please provide a valid email address',
  },
  [validationCodes.UKDate]: {
    id: 'web.Components.FormInputErrorMessage.UKDate',
    defaultMessage: 'Please enter a valid date',
  },
  [validationCodes.passwordStrength]: {
    id: 'web.Components.FormInputErrorMessage.passwordStrength',
    defaultMessage:
      'Passwords must be between 8 and 20 characters long. Include at least one uppercase letter, at least one lowercase letter and at least one number.',
  },
  [validationCodes.maxLength16]: {
    id: 'web.Components.FormInputErrorMessage.maxLength16',
    defaultMessage: 'Max length, 16 characters',
  },
  [validationCodes.alphaNumeric]: {
    id: 'web.Components.FormInputErrorMessage.alphaNumeric',
    defaultMessage: 'Please enter letters and numbers only',
  },
  [validationCodes.date]: {
    id: 'web.Components.FormInputErrorMessage.date',
    defaultMessage: 'Please enter a valid date in the format YYYY-MM-DD',
  },
  [validationCodes.passwordsMatch]: {
    id: 'web.Components.FormInputErrorMessage.passwordsMatch',
    defaultMessage: 'Passwords do not match',
  },
  [validationCodes.datePeriodMax]: {
    id: 'web.Components.FormInputErrorMessage.datePeriodMax',
    defaultMessage:
      'Rental period must be a minimum of 30 days or a maximum of 85 days',
  },
  [validationCodes.dateAfterYesterday]: {
    id: 'web.Components.FormInputErrorMessage.dateAfterYesterday',
    defaultMessage: 'Rental start date can not be in the past',
  },
  [validationCodes.minimumDateOfBirth]: {
    id: 'web.Components.FormInputErrorMessage.minimumDateOfBirth',
    defaultMessage: 'Minimum age required for rental is 21',
  },
  [validationCodes.dateAfterActive]: {
    id: 'web.Components.FormInputErrorMessage.dateAfterActive',
    defaultMessage: 'Rental start date can not overlap with other rentals',
  },
  [validationCodes.emailAddressesMatch]: {
    id: 'web.Components.FormInputErrorMessage.emailAddressesMatch',
    defaultMessage: 'Email addresses do not match',
  },
  [validationCodes.incorrectPassword]: {
    id: 'web.Components.FormInputErrorMessage.incorrectPassword',
    defaultMessage: 'The password you have entered is incorrect',
  },
  [validationCodes.dateOfBirth]: {
    id: 'web.Components.FormInputErrorMessage.dateOfBirth',
    defaultMessage: 'Please enter a valid Date of Birth',
  },
  [validationCodes.maxLength47]: {
    id: 'web.Components.FormInputErrorMessage.maxLength47',
    defaultMessage: 'Max length, 47 characters',
  },
  [validationCodes.maxLengthPerLine47]: {
    id: 'web.Components.FormInputErrorMessage.maxLengthPerLine47',
    defaultMessage: 'Max length per line, 47 characters',
  },
  [validationCodes.maxLines3]: {
    id: 'web.Components.FormInputErrorMessage.maxLines3',
    defaultMessage: 'Maximum of 3 lines',
  },
  [validationCodes.dayNumber]: {
    id: 'web.Components.FormInputErrorMessage.dayNumber',
    defaultMessage:
      'Please select which day of the week you would like to be billed',
  },
  [validationCodes.maxLength500]: {
    id: 'web.Components.FormInputErrorMessage.maxLength500',
    defaultMessage: 'Max length, 500 characters',
  },
  default: {
    id: 'web.Components.FormInputErrorMessage.default',
    defaultMessage: 'This field has an error',
  },
})

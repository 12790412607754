import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.DashboardPage.title',
    defaultMessage: 'Home',
  },
  description: {
    id: 'web.Pages.DashboardPage.description',
    defaultMessage: 'Evolve with us',
  },
})

import moment from 'moment'

export const formatDate = date => moment(date).format('DD/MM/YYYY')

export const formatLongDate = date => moment(date).format('Do MMMM, YYYY')

export const formatLongDateTime = date =>
  moment(date).format('Do MMMM, YYYY - HH:mm')

export const formatUnixDate = timestamp =>
  moment.unix(timestamp).format('DD/MM/YYYY')

export const formatTime = time => moment(time, 'HH:mm:ss.sss').format('HH:mm')

export const isDateInTimePeriod = (start, end, now = moment()) => {
  const startDate = moment(start)
  const endDate = moment(end)
  const nowDate = now.startOf('d').valueOf()
  if (!start || !end || !startDate.isValid() || !endDate.isValid()) return false
  if (nowDate >= startDate.valueOf() && nowDate <= endDate.valueOf())
    return true
  return false
}

export const getFutureDateByDay = (date, day, format = 'YYYY-MM-DD') => {
  const momentDate = moment(date)
  if (momentDate.isValid() && !Number.isNaN(parseFloat(day))) {
    const startDay = momentDate.day()
    const isFuture = Math.sign(startDay - day) === -1
    return momentDate.day(isFuture ? day : day + 7).format(format)
  }
  return undefined
}

export const formatPaymentCardExpiry = (expMonth, expYear) => {
  if (!expMonth || !expYear) return undefined
  return `${moment()
    .month(expMonth - 1)
    .format('MM')}/${moment()
    .year(expYear)
    .format('YY')}`
}

import { createSelector } from 'reselect'
import get from 'lodash/get'

import { NAME } from './constants'

export const selectWorkoutCategoriesState = state => state[NAME]
export const getWorkoutCategoryById = (state, id) =>
  get(state[NAME], `workoutCategoriesById[${id}]`)
const getWorkoutCategoryIdBySlug = (state, slug) =>
  get(state[NAME], `workoutCategoriesBySlug[${slug}]`)

const selectWorkoutCategories = createSelector(
  selectWorkoutCategoriesState,
  workoutCategoriesState => workoutCategoriesState,
)

export const selectWorkoutCategoriesLoading = createSelector(
  selectWorkoutCategories,
  workoutCategories => workoutCategories.loading,
)

export const selectWorkoutCategoriesError = createSelector(
  selectWorkoutCategories,
  workoutCategories => workoutCategories.error,
)

export const selectWorkoutCategoriesById = createSelector(
  selectWorkoutCategories,
  workoutCategories => workoutCategories.workoutCategoriesById,
)

export const selectWorkoutCategoriesBySlug = createSelector(
  selectWorkoutCategories,
  workoutCategories => workoutCategories.workoutCategoriesBySlug,
)

export const selectWorkoutCategoriesByIdArray = createSelector(
  selectWorkoutCategoriesById,
  workoutCategoriesById =>
    workoutCategoriesById ? Object.values(workoutCategoriesById) : undefined,
)

export const selectWorkoutCategoriesTree = createSelector(
  selectWorkoutCategories,
  workoutCategories => workoutCategories?.workoutCategoriesTree,
)

export const selectWorkoutCategoryById = createSelector(
  getWorkoutCategoryById,
  workoutCategory => workoutCategory,
)

export const selectWorkoutCategoryByIdName = createSelector(
  selectWorkoutCategoryById,
  workoutCategory => workoutCategory.name,
)

export const selectWorkoutCategoryByIdSlug = createSelector(
  selectWorkoutCategoryById,
  workoutCategory => workoutCategory.slug,
)

export const selectWorkoutCategoryByIdSummary = createSelector(
  selectWorkoutCategoryById,
  workoutCategory => workoutCategory.summary,
)

export const selectWorkoutCategoryByIdChildrenArray = createSelector(
  selectWorkoutCategoryById,
  selectWorkoutCategoriesByIdArray,
  (workoutCategory, workoutCategories) => {
    const { id } = workoutCategory
    return workoutCategories.reduce((acc, category) => {
      if (category?.parentCategory === id) {
        return [...acc, category]
      }
      return acc
    }, [])
  },
)

export const selectWorkoutCategoryIdsBySlug = createSelector(
  selectWorkoutCategories,
  workoutCategories => workoutCategories.workoutCategoriesBySlug,
)

export const selectWorkoutCategoryIdBySlug = createSelector(
  getWorkoutCategoryIdBySlug,
  workoutCategoryIdBySlug => workoutCategoryIdBySlug,
)

export const selectWorkoutCategoryBySlug = createSelector(
  selectWorkoutCategoryIdBySlug,
  selectWorkoutCategoriesById,
  (workoutCategoryId, workoutCategories) => {
    if (!workoutCategoryId || !workoutCategories) return null
    return workoutCategories[workoutCategoryId]
  },
)

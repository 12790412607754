import { NAME } from './constants'

export const GET_LIVE_SIGNATURE_REQUEST = `${NAME}/GET_LIVE_SIGNATURE_REQUEST`

export const REGISTER_USER_FOR_LIVE_EVENT_REQUEST = `${NAME}/REGISTER_USER_FOR_LIVE_EVENT_REQUEST`
export const REGISTER_USER_FOR_LIVE_EVENT_SUCCESS = `${NAME}/REGISTER_USER_FOR_LIVE_EVENT_SUCCESS`

export const GET_LIVE_SCHEDULE_REQUEST = `${NAME}/GET_LIVE_SCHEDULE_REQUEST`
export const GET_LIVE_SCHEDULE_SUCCESS = `${NAME}/GET_LIVE_SCHEDULE_SUCCESS`
export const GET_LIVE_SCHEDULE_ERROR = `${NAME}/GET_LIVE_SCHEDULE_ERROR`

export const GET_LIVE_SCHEDULE_ITEM_REQUEST = `${NAME}/GET_LIVE_SCHEDULE_ITEM_REQUEST`
export const GET_LIVE_SCHEDULE_ITEM_SUCCESS = `${NAME}/GET_LIVE_SCHEDULE_ITEM_SUCCESS`

import { createSelector } from 'reselect'
import { NAME } from './constants'

export const selectPaymentMethodState = state => state[NAME]

const selectPaymentMethod = createSelector(
  selectPaymentMethodState,
  paymentMethodState => paymentMethodState,
)

export const selectPaymentMethodLoading = createSelector(
  selectPaymentMethod,
  paymentMethod => paymentMethod.loading,
)

export const selectPaymentMethodError = createSelector(
  selectPaymentMethod,
  paymentMethod => paymentMethod.error,
)

export const selectPaymentMethodBrand = createSelector(
  selectPaymentMethod,
  paymentMethod => paymentMethod.brand,
)

export const selectPaymentMethodExpMonth = createSelector(
  selectPaymentMethod,
  paymentMethod => paymentMethod.expMonth,
)

export const selectPaymentMethodExpYear = createSelector(
  selectPaymentMethod,
  paymentMethod => paymentMethod.expYear,
)

export const selectPaymentMethodLast4 = createSelector(
  selectPaymentMethod,
  paymentMethod => paymentMethod.last4,
)

import DebugConfig, { isDev } from './debugConfig'
import { native } from './clientConfig'

if (isDev) {
  console.disableYellowBox = !DebugConfig.yellowBox
}

export const nativeClientConfig = native

export const envConfig = {
  ENV: 'prod',
  STRIPE_PUBLISHABLE_KEY:
    'pk_live_51HSS4sJg9sHcP5tIdyMnRgELX7QO6j4HFO1EyqzugWiLcijBCAQhvNI6vu92g1oa3nd7sTSnbxbFQDapMia5rrCk00EIvKSYZd',
  FIREBASE_API_KEY: 'AIzaSyBzdzxALV3IdsUzeJS9SZwmsWenLmmmpwo',
  FIREBASE_AUTH_DOMAIN: 'evolve-5d463.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://evolve-5d463.firebaseio.com',
  FIREBASE_PROJECT_ID: 'evolve-5d463',
  FIREBASE_STORAGE_BUCKET: 'evolve-5d463.appspot.com',
  FIREBASE_MESSAGE_SENDER_ID: '90841500036',
  FIREBASE_API: 'https://europe-west2-evolve-5d463.cloudfunctions.net/api',
  AUTHORIZATION: 'admin:g66vyBJZvzJ7G8jk',
  ZOOM_API: 'WJDNGDaiQYGpBVC9JsMrxA',
  GOOGLE_TRACKING_ID: 'UA-181283103-2',
  GOOGLE_TAG_ID: 'G-JJPK5VRJQL',
}

export const appConfig = {
  appName: 'Evolve',
  sentryWebDsn:
    'https://a0a498aded584e25a6227ccefad1935c@o450173.ingest.sentry.io/5434374',
  termsOfUseUrl: 'https://joinevolve.co/terms-of-use',
  privacyPolicyUrl: 'https://joinevolve.co/privacy-policy',
}

import moment from 'moment'
import { firestore, firebase } from './firebase'

export const getUser = uid =>
  firestore
    .collection('users')
    .doc(uid)
    .get()

export const setUser = (uid, payload) =>
  firestore
    .collection('users')
    .doc(uid)
    .set(
      {
        ...payload,
      },
      { merge: true },
    )

export const getSubscription = customerId =>
  firestore
    .collection('subscriptions')
    .doc(customerId)
    .get()

export const getPlans = () => firestore.collection('plans').get()

export const getPaymentMethod = customerId =>
  firestore
    .collection('paymentMethods')
    .doc(customerId)
    .get()

export const getWorkouts = () => {
  const now = moment().valueOf()
  return firestore
    .collection('workouts')
    .where('draft', '==', 'No')
    .where('publishAt', '<=', now)
    .get()
}

export const getWorkout = id =>
  firestore
    .collection('workouts')
    .doc(id)
    .get()

export const getWorkoutFeedback = id =>
  firestore
    .collection('workoutFeedback')
    .where('status', '==', 'published')
    .where('workoutId', '==', id)
    .orderBy('timestamp', 'desc')
    .get()

export const getWorkoutBySlug = slug =>
  firestore
    .collection('workouts')
    .where('slug', '==', slug)
    .get()

export const getWorkoutCategories = () =>
  firestore
    .collection('workoutCategories')
    .where('draft', '==', 'No')
    .get()

export const getEquipment = () => firestore.collection('equipment').get()

export const getMuscleGroups = () => firestore.collection('muscleGroups').get()

// @NOTE select items that are up to 7 days old, and 14 days in the future.
export const getLiveSchedule = () => {
  const oneWeekAgo = moment()
    .subtract(1, 'week')
    .valueOf()
  const twoWeeksFromNow = moment()
    .add(2, 'weeks')
    .valueOf()
  return firestore
    .collection('liveSchedule')
    .where('endDateTime', '>=', oneWeekAgo)
    .where('endDateTime', '<=', twoWeeksFromNow)
    .get()
}

export const getLiveScheduleItem = id =>
  firestore
    .collection('liveSchedule')
    .doc(id)
    .get()

export const getUserLiveScheduleRegistrations = uid =>
  firestore
    .collection('users')
    .doc(uid)
    .collection('liveSchedule')
    .get()

export const addUserLiveScheduleRegistration = (
  uid,
  liveScheduleId,
  joinUrl,
  meetingNumber,
) => {
  const timestamp = moment().valueOf()
  return firestore
    .collection('users')
    .doc(uid)
    .collection('liveSchedule')
    .doc(liveScheduleId)
    .set({ timestamp, joinUrl, meetingNumber }, { merge: true })
}

export const getUserWorkoutFavourites = uid =>
  firestore
    .collection('users')
    .doc(uid)
    .collection('workoutFavourites')
    .get()

export const addWorkoutToFavourites = (uid, workoutId) => {
  const timestamp = moment().valueOf()
  return firestore
    .collection('users')
    .doc(uid)
    .collection('workoutFavourites')
    .doc(workoutId)
    .set({ timestamp }, { merge: true })
}

export const removeWorkoutFromFavourites = (uid, workoutId) =>
  firestore
    .collection('users')
    .doc(uid)
    .collection('workoutFavourites')
    .doc(workoutId)
    .delete()

export const getWeeklyPlan = () => {
  // Week start now.
  const id = moment
    .utc()
    .startOf('isoWeek')
    .valueOf()
    .toString()

  return firestore
    .collection('weeklyPlans')
    .doc(id)
    .get()
}

export const getWeeklyMantra = () => {
  // Week start now.
  const id = moment
    .utc()
    .startOf('isoWeek')
    .valueOf()
    .toString()

  return firestore
    .collection('weeklyMantras')
    .doc(id)
    .get()
}

export const incrementWorkoutWatchedCount = (uid, workoutId) =>
  firestore
    .collection('users')
    .doc(uid)
    .collection('workoutWatched')
    .doc(workoutId)
    .set({ count: firebase.firestore.FieldValue.increment(1) }, { merge: true })

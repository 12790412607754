export const transformPromotion = response => {
  if (!response || !response.codes || !response.codes.length) return null

  const promotion = response.codes[0]

  return {
    id: promotion.id,
    active: promotion.active,
    code: promotion.code,
    couponId: promotion.coupon.id,
    description: promotion.coupon.metadata.description,
    restrictions: promotion.restrictions,
  }
}

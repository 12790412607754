import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import { useHistory, Link, generatePath } from 'react-router-dom'

import routes from 'web/routing/routes'
import {
  selectSubscriptionUpgradeOptionPriceId,
  selectSubscriptionUpgradeOptionFrequency,
  selectSubscriptionUpgradeOptionPrice,
  selectSubscriptionFrequency,
  selectSubscriptionStatus,
  selectSubscriptionCancelAtPeriodEnd,
} from 'shared/modules/Subscription/selectors'
import {
  previewSubscriptionProrationRequest,
  updateSubscriptionPriceRequest,
} from 'shared/modules/Subscription/actions'
import { SUBSCRIPTION_STATUS } from 'shared/modules/Subscription/constants'

import {
  ButtonPrimary,
  Grid,
  Card,
  FormattedMoney,
  Alert,
} from 'web/components'

import messages, { errorMessages } from './messages'

const SubscriptionUpgrade = ({ requiredAccessLevel, onSuccessContinue }) => {
  const [loading, setLoading] = useState()
  const [prices, setPrices] = useState()
  const [displaySuccess, setDisplaySuccess] = useState()
  const [displayError, setDisplayError] = useState()

  const dispatch = useDispatch()
  const history = useHistory()

  const currentFrequency = useSelector(state =>
    selectSubscriptionFrequency(state),
  )
  const optionPriceId = useSelector(state =>
    selectSubscriptionUpgradeOptionPriceId(state, requiredAccessLevel),
  )
  const optionFrequency = useSelector(state =>
    selectSubscriptionUpgradeOptionFrequency(state, requiredAccessLevel),
  )
  const optionPrice = useSelector(state =>
    selectSubscriptionUpgradeOptionPrice(state, requiredAccessLevel),
  )
  const subscriptionStatus = useSelector(state =>
    selectSubscriptionStatus(state),
  )
  const subscriptionCancelAtPeriodEnd = useSelector(state =>
    selectSubscriptionCancelAtPeriodEnd(state),
  )

  const handleProrationRequestSuccess = response => {
    setLoading(false)
    setPrices(response)
  }

  const handleProrationRequestError = () => {
    setLoading(false)
    setDisplayError({ type: 'preview' })
  }

  const handleUpgradeSuccess = () => {
    setLoading(false)
    setDisplaySuccess(true)
  }

  const handleUpgradeError = () => {
    setLoading(false)
    setDisplayError({ type: 'upgrade' })
  }

  const handleUpgrade = () => {
    setLoading(true)
    dispatch(
      updateSubscriptionPriceRequest(
        optionPriceId,
        handleUpgradeSuccess,
        handleUpgradeError,
      ),
    )
  }

  const handleSuccessContinue = () => {
    if (onSuccessContinue) {
      onSuccessContinue()
    } else {
      history.go(0)
    }
  }

  const isTrialing = subscriptionStatus === SUBSCRIPTION_STATUS.trialing

  useEffect(() => {
    if (
      !subscriptionCancelAtPeriodEnd &&
      optionPriceId &&
      optionFrequency &&
      optionPrice
    ) {
      setLoading(true)
      dispatch(
        previewSubscriptionProrationRequest(
          optionPriceId,
          handleProrationRequestSuccess,
          handleProrationRequestError,
        ),
      )
    }
  }, [
    dispatch,
    subscriptionCancelAtPeriodEnd,
    optionPriceId,
    optionFrequency,
    optionPrice,
  ])

  if (subscriptionCancelAtPeriodEnd) {
    return (
      <Alert
        severity="info"
        action={
          <Button
            component={Link}
            to={generatePath(routes.AccountDetailsPage.routePath)}
          >
            <FormattedMessage
              {...messages.subscriptionCancellingActionButton}
            />
          </Button>
        }
      >
        <FormattedMessage {...messages.subscriptionCancelling} />
      </Alert>
    )
  }

  if (displayError) {
    return (
      <Alert severity="error">
        <FormattedMessage {...errorMessages[displayError.type]} />
      </Alert>
    )
  }

  if (displaySuccess) {
    return (
      <Alert
        action={
          <Button color="inherit" onClick={handleSuccessContinue}>
            <FormattedMessage {...messages.successMessageButton} />
          </Button>
        }
      >
        <FormattedMessage {...messages.successMessage} />
      </Alert>
    )
  }

  return (
    <Grid container alignItems="center" justify="center">
      <Grid item>
        <Card>
          <CardContent>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography align="center" gutterBottom>
                  {!prices ? (
                    <FormattedMessage {...messages.loadingUpgrade} />
                  ) : (
                    <>
                      {isTrialing ? (
                        <FormattedMessage
                          {...messages.upgradeSummaryTrialling}
                          values={{
                            FREQUENCY: <strong>{currentFrequency}</strong>,
                            PRICE: (
                              <strong>
                                <FormattedMoney value={prices.futurePrice} />
                              </strong>
                            ),
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          {...messages.upgradeSummary}
                          values={{
                            UPGRADE_PRICE: (
                              <strong>
                                <FormattedMoney value={prices.upgradePrice} />
                              </strong>
                            ),
                            FREQUENCY: <strong>{currentFrequency}</strong>,
                            PRICE: (
                              <strong>
                                <FormattedMoney value={prices.futurePrice} />
                              </strong>
                            ),
                          }}
                        />
                      )}
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" justify="center">
                  <Grid item>
                    <ButtonPrimary
                      loading={!prices || loading}
                      onClick={handleUpgrade}
                      highlight
                    >
                      <FormattedMessage
                        {...(isTrialing
                          ? messages.upgradeNowButton
                          : messages.payNowButton)}
                      />
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </Grid>
              {!isTrialing && (
                <Grid item xs={12}>
                  <Typography align="center" variant="body2">
                    <FormattedMessage
                      {...messages.upgradeProcessDetails}
                      values={{ FREQUENCY: currentFrequency }}
                    />
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

SubscriptionUpgrade.propTypes = {
  requiredAccessLevel: PropTypes.number.isRequired,
  onSuccessContinue: PropTypes.func,
}

export default SubscriptionUpgrade

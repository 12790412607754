import { createSelector } from 'reselect'
import { NAME } from './constants'

export const selectAuthState = state => state[NAME]

const selectAuth = createSelector(selectAuthState, authState => authState)

export const selectAuthSignedIn = createSelector(
  selectAuth,
  auth => auth.signedIn,
)

export const selectAuthUid = createSelector(selectAuth, auth => auth.uid)

export const selectAuthEmail = createSelector(selectAuth, auth => auth.email)

export const selectAuthLoading = createSelector(
  selectAuth,
  auth => auth.loading,
)

export const selectAuthError = createSelector(selectAuth, auth => auth.error)

import { defineMessages } from 'react-intl'

export default defineMessages({
  downgradedTitle: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.downgradedTitle',
    defaultMessage: 'Whoops...',
  },
  downgradedSubtitle: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.downgradedSubtitle',
    defaultMessage: "You're already on the Evolve On Demand pricing plan.",
  },
  downgradeScheduledTitle: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.downgradeScheduledTitle',
    defaultMessage: 'Subscription Downgrade Scheduled',
  },
  downgradeScheduledSubtitle: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.downgradeScheduledTitle',
    defaultMessage:
      'Your subscription is already scheduled to be downgraded at the end of your billing period.',
  },
  bodyTitle: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.bodyTitle',
    defaultMessage: 'Downgrade to Evolve On Demand',
  },
  subtitle: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.subtitle',
    defaultMessage:
      'You can downgrade your subscription now. It will take effect at the end of your billing period.',
  },
  body: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.body',
    defaultMessage:
      'By choosing to downgrade to Evolve On Demand, you will no longer be able to access the Live Classes and Catch up.',
  },
  body2: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.body2',
    defaultMessage: 'Are you sure you want to downgrade?',
  },
  body3: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.body3',
    defaultMessage:
      "Don't worry, if you change your mind before your subscription changes, you can cancel the downgrade.",
  },
  body4: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.body4',
    defaultMessage:
      'When you feel like taking part in the Live classes again, you can choose to upgrade again at any point',
  },
  downgradeSubscriptionButton: {
    id:
      'web.Subscriptions.SubscriptionCanDowngrade.downgradeSubscriptionButton',
    defaultMessage: 'Yes, Downgrade to Evolve On Demand',
  },
  successMessage: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.successMessage',
    defaultMessage:
      'Your subscription will be downgraded at the end of the current billing period.',
  },
  successMessageContinue: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.successMessageContinue',
    defaultMessage: 'Continue',
  },
  errorMessage: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.errorMessage',
    defaultMessage:
      'Sorry, something went wrong trying to schedule your downgrade. Please refresh and try again, or contact us if the problem persisits.',
  },
  promotionWarning: {
    id: 'web.Subscriptions.SubscriptionCanDowngrade.promotionWarning',
    defaultMessage:
      'Please note that your active promotion will be removed from your subscription.',
  },
})

import * as actionTypes from './actionTypes'

export const signInRequest = (email, password, onSuccess, onError) => ({
  type: actionTypes.SIGN_IN_REQUEST,
  email,
  password,
  onSuccess,
  onError,
})

export const signInSuccess = authData => ({
  type: actionTypes.SIGN_IN_SUCCESS,
  authData,
})

export const signInError = error => ({
  type: actionTypes.SIGN_IN_ERROR,
  error,
})

export const signInCancelled = () => ({
  type: actionTypes.SIGN_IN_CANCELLED,
})

export const clearError = () => ({
  type: actionTypes.CLEAR_ERROR,
})

export const resetPasswordRequest = (email, onSuccess, onError) => ({
  type: actionTypes.RESET_PASSWORD_REQUEST,
  email,
  onSuccess,
  onError,
})

export const resetPasswordSuccess = () => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
})

export const resetPasswordError = error => ({
  type: actionTypes.RESET_PASSWORD_ERROR,
  error,
})

export const signUpRequest = (
  email,
  password,
  firstName,
  lastName,
  onSuccess,
  onError,
) => ({
  type: actionTypes.SIGN_UP_REQUEST,
  email,
  password,
  firstName,
  lastName,
  onSuccess,
  onError,
})

export const signUpSuccess = () => ({
  type: actionTypes.SIGN_UP_SUCCESS,
})

export const signUpError = error => ({
  type: actionTypes.SIGN_UP_ERROR,
  error,
})

export const signOutRequest = () => ({
  type: actionTypes.SIGN_OUT_REQUEST,
})

export const signOutSuccess = () => ({
  type: actionTypes.SIGN_OUT_SUCCESS,
})

export const signOutError = () => ({
  type: actionTypes.SIGN_OUT_ERROR,
})

export const updateEmailRequest = (email, password, onSuccess, onError) => ({
  type: actionTypes.UPDATE_EMAIL_REQUEST,
  email,
  password,
  onSuccess,
  onError,
})

export const updateEmailSuccess = email => ({
  type: actionTypes.UPDATE_EMAIL_SUCCESS,
  email,
})

export const updateEmailError = error => ({
  type: actionTypes.UPDATE_EMAIL_ERROR,
  error,
})

export const updatePasswordRequest = (
  password,
  newPassword,
  onSuccess,
  onError,
) => ({
  type: actionTypes.UPDATE_PASSWORD_REQUEST,
  password,
  newPassword,
  onSuccess,
  onError,
})

export const updatePasswordSuccess = () => ({
  type: actionTypes.UPDATE_PASSWORD_SUCCESS,
})

export const updatePasswordError = error => ({
  type: actionTypes.UPDATE_PASSWORD_ERROR,
  error,
})

import * as actionTypes from './actionTypes'

export const getLiveSignatureRequest = (meetingNumber, onSuccess, onError) => ({
  type: actionTypes.GET_LIVE_SIGNATURE_REQUEST,
  meetingNumber,
  onSuccess,
  onError,
})

export const registerUserForLiveEventRequest = (
  meetingNumber,
  liveScheduleId,
  onSuccess,
  onError,
) => ({
  type: actionTypes.REGISTER_USER_FOR_LIVE_EVENT_REQUEST,
  meetingNumber,
  liveScheduleId,
  onSuccess,
  onError,
})

export const registerUserForLiveEventSuccess = (
  meetingNumber,
  liveScheduleId,
  joinUrl,
) => ({
  type: actionTypes.REGISTER_USER_FOR_LIVE_EVENT_SUCCESS,
  meetingNumber,
  liveScheduleId,
  joinUrl,
})

export const getLiveScheduleRequest = () => ({
  type: actionTypes.GET_LIVE_SCHEDULE_REQUEST,
})

export const getLiveScheduleSuccess = liveScheduleById => ({
  type: actionTypes.GET_LIVE_SCHEDULE_SUCCESS,
  liveScheduleById,
})

export const getLiveScheduleError = error => ({
  type: actionTypes.GET_LIVE_SCHEDULE_ERROR,
  error,
})

export const getLiveScheduleItemRequest = (id, onSuccess, onError) => ({
  type: actionTypes.GET_LIVE_SCHEDULE_ITEM_REQUEST,
  id,
  onSuccess,
  onError,
})

export const getLiveScheduleItemSuccess = liveScheduleItem => ({
  type: actionTypes.GET_LIVE_SCHEDULE_ITEM_SUCCESS,
  liveScheduleItem,
})

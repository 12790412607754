import { call, put, takeLatest, select, spawn } from 'redux-saga/effects'

import { authenticatedRequest } from 'shared/utils/RequestUtils'
import { envConfig } from 'shared/config'
import * as auth from 'shared/firebase/auth'
import { getSubscriptionRequest } from 'shared/modules/Subscription/actions'
import { getWorkoutsRequest } from 'shared/modules/Workouts/actions'
import { getWorkoutCategoriesRequest } from 'shared/modules/WorkoutCategories/actions'
import { getEquipmentRequest } from 'shared/modules/Equipment/actions'
import { getMuscleGroupsRequest } from 'shared/modules/MuscleGroups/actions'
import { getWeeklyPlanRequest } from 'shared/modules/WeeklyPlan/actions'
import { getPlansRequest } from 'shared/modules/Plans/actions'
import { selectUserStripeCustomerId } from 'shared/modules/User/selectors'
import { getFullUser } from 'shared/modules/User/sagas'
import { setUiValue } from 'shared/modules/UI/actions'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import * as selectors from './selectors'
import { transformSignIn } from './utils'
import { SIGN_UP_URL, UPDATE_EMAIL_URL } from './constants'

function* signUp({ email, password, firstName, lastName, onSuccess, onError }) {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ email, password, firstName, lastName }),
    }
    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${SIGN_UP_URL}`,
      options,
    )
    yield put(setUiValue('welcomeMessageSeen', false))
    yield call(signIn, { email, password })
    yield put(actions.signUpSuccess(response.user))
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.signUpError(error))
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* signIn({ email, password, onSuccess, onError }) {
  try {
    const authResponse = yield call(
      auth.doSignInWithEmailAndPassword,
      email,
      password,
    )
    const authCredentials = transformSignIn(authResponse)
    yield call(getFullUser, { uid: authCredentials.uid })
    // Gather all required details to display correctly
    yield put(getPlansRequest())
    yield put(getSubscriptionRequest())
    yield put(getWorkoutsRequest())
    yield put(getWorkoutCategoriesRequest())
    yield put(getMuscleGroupsRequest())
    yield put(getEquipmentRequest())
    yield put(getWeeklyPlanRequest())
    yield put(actions.signInSuccess(authCredentials))
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.signInError(error))
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* resetPassword({ email, onSuccess, onError }) {
  try {
    yield call(auth.doResetPassword, email)
    yield put(actions.resetPasswordSuccess())
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.resetPasswordError(error))
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* signOut() {
  try {
    yield call(auth.doSignOut)
    yield put(actions.signOutSuccess())
  } catch (error) {
    yield put(actions.signOutError())
    yield spawn(throwSentryError, error)
  }
}

function* updateEmail({ email, password, onSuccess, onError }) {
  try {
    const uid = yield select(selectors.selectAuthUid)
    const customerId = yield select(selectUserStripeCustomerId)
    yield call(auth.doReauthenticateWithCredential, password)
    const options = {
      method: 'POST',
      body: JSON.stringify({ email, uid, customerId }),
    }
    yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${UPDATE_EMAIL_URL}`,
      options,
    )
    yield call(auth.doSignInWithEmailAndPassword, email, password)
    yield put(actions.updateEmailSuccess(email))
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.updateEmailError(error))
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* updatePassword({ password, newPassword, onSuccess, onError }) {
  try {
    yield call(auth.doReauthenticateWithCredential, password)
    yield call(auth.doUpdatePassword, newPassword)
    yield put(actions.updatePasswordSuccess())
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.updatePasswordError(error))
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

export default [
  takeLatest(actionTypes.SIGN_OUT_REQUEST, signOut),
  takeLatest(actionTypes.SIGN_UP_REQUEST, signUp),
  takeLatest(actionTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(actionTypes.RESET_PASSWORD_REQUEST, resetPassword),
  takeLatest(actionTypes.UPDATE_EMAIL_REQUEST, updateEmail),
  takeLatest(actionTypes.UPDATE_PASSWORD_REQUEST, updatePassword),
]

import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import LiveTvIcon from '@material-ui/icons/LiveTv'

import { formatLongDateTime } from 'shared/utils/DateTimeUtils'

import routes from 'web/routing/routes'
import { CardItemSummary, ButtonSecondary, Alert } from 'web/components'
import ButtonRegisterOrJoin from 'web/modules/LiveSchedule/components/ButtonRegisterOrJoin'

const CardLiveScheduleItemSummary = ({
  id,
  name,
  image,
  summary,
  startDateTime,
  meetingNumber,
}) => {
  const history = useHistory()
  return (
    <CardItemSummary
      name={name}
      image={image}
      onClick={() =>
        history.push(`${routes.LiveScheduleItemPage.routeName}${id}`)
      }
      cardActions={
        <>
          <ButtonSecondary
            onClick={() =>
              history.push(`${routes.LiveScheduleItemPage.routeName}${id}`)
            }
          >
            View Class
          </ButtonSecondary>
          <ButtonRegisterOrJoin
            meetingNumber={meetingNumber}
            liveScheduleId={id}
          />
        </>
      }
    >
      <div style={styles.noticeWrapper}>
        <Alert
          icon={<LiveTvIcon icon="LiveTv" fontSize="inherit" />}
          severity="success"
        >
          We are live now!
        </Alert>
      </div>
      <Typography gutterBottom variant="h5" component="h2">
        {name}
      </Typography>
      <Typography paragraph variant="body2" color="textSecondary">
        {summary.length > 100 ? `${summary.substr(0, 100 - 1)}...` : summary}
      </Typography>
      <Typography variant="body2">
        Started {moment(startDateTime).fromNow()}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {formatLongDateTime(startDateTime)}
      </Typography>
    </CardItemSummary>
  )
}

const styles = {
  noticeWrapper: {
    marginBottom: 15,
  },
}

CardLiveScheduleItemSummary.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  summary: PropTypes.string.isRequired,
  startDateTime: PropTypes.number.isRequired,
  meetingNumber: PropTypes.string.isRequired,
}

export default CardLiveScheduleItemSummary

import { defineMessages } from 'react-intl'

export default defineMessages({
  emailLabel: {
    id: 'web.Auth.UpdateEmailForm.emailLabel',
    defaultMessage: 'New Email Address',
  },
  passwordLabel: {
    id: 'web.Auth.UpdateEmailForm.passwordLabel',
    defaultMessage: 'Password',
  },
  submitButton: {
    id: 'web.Auth.UpdatePasswordForm.submitButton',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'web.Auth.UpdatePasswordForm.cancelButton',
    defaultMessage: 'Cancel',
  },
})

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { useHistory } from 'react-router-dom'

import routes from 'web/routing/routes'
import { Card } from 'web/components'

import { StyledListItem, Image } from './components'

const WorkoutsCarousel = ({ workouts }) => {
  const history = useHistory()
  return (
    <div style={styles.wrapper}>
      {workouts.map(
        ({ image, name, summary, duration, publishAt, slug, subTitle }, i) => (
          <StyledListItem key={slug} last={i === workouts.length - 1}>
            <Card>
              <CardActionArea
                onClick={() =>
                  history.push(`${routes.WorkoutPage.routeName}${slug}`)
                }
              >
                <Image {...image} alt={name} />
                <CardContent>
                  <Typography
                    gutterBottom={!subTitle}
                    variant="h6"
                    component="h2"
                  >
                    {name}
                  </Typography>
                  {subTitle && (
                    <Typography
                      variant="h6"
                      component="h3"
                      color="textSecondary"
                      gutterBottom
                    >
                      {subTitle}
                    </Typography>
                  )}
                  <Typography paragraph variant="body2" color="textSecondary">
                    {summary.length > 100
                      ? `${summary.substr(0, 100 - 1)}...`
                      : summary}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary"
                  >
                    {duration} minutes | Added {moment(publishAt).fromNow()}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </StyledListItem>
        ),
      )}
    </div>
  )
}

const styles = {
  wrapper: {
    marginLeft: -24,
    marginRight: -24,
    paddingLeft: 16,
    paddingRight: 16,
    overflowX: 'auto',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
}

WorkoutsCarousel.propTypes = {
  workouts: PropTypes.array.isRequired,
}

export default WorkoutsCarousel

import React from 'react'
import { useParams } from 'react-router-dom'

import { WorkoutContainer } from 'shared/modules/Workouts/components'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { HelmetLocalised } from 'web/components'
import { Workout } from 'web/modules/Workouts/components'

import messages from './messages'

const WorkoutPage = () => {
  // @TODO this page will also be used by the Preview func.
  const { slug } = useParams()

  return (
    <>
      <HelmetLocalised
        title={messages.title}
        description={messages.description}
      />
      <PageWrapper>
        <WorkoutContainer slug={slug}>
          {workout => <Workout {...workout} />}
        </WorkoutContainer>
      </PageWrapper>
    </>
  )
}

export default WorkoutPage

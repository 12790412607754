import React from 'react'
import PropTypes from 'prop-types'

import ButtonPrimary from 'web/components/ButtonPrimary'

const ButtonSecondary = ({ children, ...rest }) => (
  <ButtonPrimary variant="text" {...rest}>
    {children}
  </ButtonPrimary>
)

ButtonSecondary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ButtonSecondary

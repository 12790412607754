import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { getWeeklyMantraRequest } from 'shared/modules/WeeklyMantra/actions'
import {
  selectWeeklyMantraMantra,
  selectWeeklyMantraVariant,
  selectWeeklyMantraLoading,
  selectWeeklyMantraError,
} from 'shared/modules/WeeklyMantra/selectors'

const WeeklyMantraContainer = ({ children, render }) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => selectWeeklyMantraLoading(state))
  const error = useSelector(state => selectWeeklyMantraError(state))

  const mantra = useSelector(state => selectWeeklyMantraMantra(state))
  const variant = useSelector(state => selectWeeklyMantraVariant(state))

  const getWeeklyMantra = useCallback(() => {
    dispatch(getWeeklyMantraRequest())
  }, [dispatch])

  useEffect(() => {
    getWeeklyMantra()
  }, [getWeeklyMantra])

  const renderProps = children || render

  return renderProps({
    mantra,
    variant,
    loading,
    error,
  })
}

WeeklyMantraContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
}

export default WeeklyMantraContainer

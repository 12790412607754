import * as actionTypes from './actionTypes'

const initialState = {
  mantra: undefined,
  variant: undefined,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WEEKLY_MANTRA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GET_WEEKLY_MANTRA_SUCCESS:
      return {
        ...state,
        loading: false,
        mantra: action.weeklyMantra.mantra,
        variant: action.weeklyMantra.variant,
      }
    case actionTypes.GET_WEEKLY_MANTRA_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}

import { defineMessages } from 'react-intl'

export default defineMessages({
  price: {
    id: 'web.Plans.PlanSelect.Plan.price',
    defaultMessage: '£{PRICE}',
  },
  cancelAnyTime: {
    id: 'web.Plans.PlanSelect.Plan.cancelAnyTime',
    defaultMessage: '(Cancel Anytime)',
  },
  savings: {
    id: 'web.Plans.PlanSelect.Plan.savings',
    defaultMessage: '(Saving {SAVING}%)',
  },
})

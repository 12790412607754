import { defineMessages } from 'react-intl'

export default defineMessages({
  cardNumber: {
    id: 'web.Payments.StripeElementsCardDetailsForm.cardNumber',
    defaultMessage: 'Card Number',
  },
  cardExpiry: {
    id: 'web.Payments.StripeElementsCardDetailsForm.cardExpiry',
    defaultMessage: 'Card Expiry',
  },
  cvc: {
    id: 'web.Payments.StripeElementsCardDetailsForm.cvc',
    defaultMessage: 'CVC',
  },
  cvcPlaceholder: {
    id: 'web.Payments.StripeElementsCardDetailsForm.cvcPlaceholder',
    defaultMessage: '123',
  },
  cancelButton: {
    id: 'web.Payments.StripeElementsCardDetailsForm.cancelButton',
    defaultMessage: 'Cancel',
  },
})

import { NAME } from './constants'

export const GET_SUBSCRIPTION_REQUEST = `${NAME}/GET_SUBSCRIPTION_REQUEST`
export const GET_SUBSCRIPTION_SUCCESS = `${NAME}/GET_SUBSCRIPTION_SUCCESS`
export const GET_SUBSCRIPTION_ERROR = `${NAME}/GET_SUBSCRIPTION_ERROR`

export const CREATE_SUBSCRIPTION_REQUEST = `${NAME}/CREATE_SUBSCRIPTION_REQUEST`
export const CREATE_SUBSCRIPTION_SUCCESS = `${NAME}/CREATE_SUBSCRIPTION_SUCCESS`
export const CREATE_SUBSCRIPTION_ERROR = `${NAME}/CREATE_SUBSCRIPTION_ERROR`

export const CANCEL_SUBSCRIPTION_AT_PERIOD_END_REQUEST = `${NAME}/CANCEL_SUBSCRIPTION_AT_PERIOD_END_REQUEST`
export const CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS = `${NAME}/CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS`
export const CANCEL_SUBSCRIPTION_AT_PERIOD_END_ERROR = `${NAME}/CANCEL_SUBSCRIPTION_AT_PERIOD_END_ERROR`

export const RETRY_INVOICE_REQUEST = `${NAME}/RETRY_INVOICE_REQUEST`
export const RETRY_INVOICE_SUCCESS = `${NAME}/RETRY_INVOICE_SUCCESS`
export const RETRY_INVOICE_ERROR = `${NAME}/RETRY_INVOICE_ERROR`

export const INVOICE_PAYMENT_FAILED = `${NAME}/INVOICE_PAYMENT_FAILED`

export const UPDATE_SUBSCRIPTION_SUCCESS = `${NAME}/UPDATE_SUBSCRIPTION_SUCCESS`

export const ADD_SUBSCRIPTION_PRICE_ID = `${NAME}/ADD_SUBSCRIPTION_PRICE_ID`

export const ADD_SUBSCRIPTION_PROMOTION_REQUEST = `${NAME}/ADD_SUBSCRIPTION_PROMOTION_REQUEST`
export const ADD_SUBSCRIPTION_PROMOTION_SUCCESS = `${NAME}/ADD_SUBSCRIPTION_PROMOTION_SUCCESS`
export const ADD_SUBSCRIPTION_PROMOTION_ERROR = `${NAME}/ADD_SUBSCRIPTION_PROMOTION_ERROR`

export const UPDATE_SUBSCRIPTION_PRICE_REQUEST = `${NAME}/UPDATE_SUBSCRIPTION_PRICE_REQUEST`
export const UPDATE_SUBSCRIPTION_PRICE_SUCCESS = `${NAME}/UPDATE_SUBSCRIPTION_PRICE_SUCCESS`
export const UPDATE_SUBSCRIPTION_PRICE_ERROR = `${NAME}/UPDATE_SUBSCRIPTION_PRICE_ERROR`

export const PREVIEW_SUBSCRIPTION_PRORATION_REQUEST = `${NAME}/PREVIEW_SUBSCRIPTION_PRORATION_REQUEST`

export const SCHEDULE_SUBSCRIPTION_DOWNGRADE_REQUEST = `${NAME}/SCHEDULE_SUBSCRIPTION_DOWNGRADE_REQUEST`
export const SCHEDULE_SUBSCRIPTION_DOWNGRADE_SUCCESS = `${NAME}/SCHEDULE_SUBSCRIPTION_DOWNGRADE_SUCCESS`
export const SCHEDULE_SUBSCRIPTION_DOWNGRADE_ERROR = `${NAME}/SCHEDULE_SUBSCRIPTION_DOWNGRADE_ERROR`

export const CANCEL_SUBSCRIPTION_DOWNGRADE_REQUEST = `${NAME}/CANCEL_SUBSCRIPTION_DOWNGRADE_REQUEST`
export const CANCEL_SUBSCRIPTION_DOWNGRADE_SUCCESS = `${NAME}/CANCEL_SUBSCRIPTION_DOWNGRADE_SUCCESS`
export const CANCEL_SUBSCRIPTION_DOWNGRADE_ERROR = `${NAME}/CANCEL_SUBSCRIPTION_DOWNGRADE_ERROR`

export const SUBSCRIPTION_SHOULD_UPDATE = `${NAME}/SUBSCRIPTION_SHOULD_UPDATE`

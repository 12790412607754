import { createSelector } from 'reselect'
import get from 'lodash/get'
import { NAME } from './constants'

export const selectMuscleGroupsState = state => state[NAME]
export const getMuscleGroupById = (state, id) =>
  get(state[NAME], `muscleGroupsById[${id}]`)

const selectMuscleGroups = createSelector(
  selectMuscleGroupsState,
  muscleGroupsState => muscleGroupsState,
)

export const selectMuscleGroupsLoading = createSelector(
  selectMuscleGroups,
  muscleGroups => muscleGroups.loading,
)

export const selectMuscleGroupsError = createSelector(
  selectMuscleGroups,
  muscleGroups => muscleGroups.error,
)

export const selectMuscleGroupsById = createSelector(
  selectMuscleGroups,
  muscleGroups => muscleGroups.muscleGroupsById,
)

export const selectMuscleGroupById = createSelector(
  getMuscleGroupById,
  muscleGroup => muscleGroup,
)

import React from 'react'
import PropTypes from 'prop-types'
import iconPath from './iconPath'

const MaterialIcon = ({ color, size, icon, style, ...other }) => {
  const mixedStyles = {
    pointerEvents: 'none',
    color,
    width: size,
    height: size,
    ...style,
  }

  const Icon = iconPath[icon]

  return <Icon {...other} style={mixedStyles} size={size} />
}

MaterialIcon.defaultProps = {
  color: '#000',
  size: 24,
  style: {},
}

MaterialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
}

export default MaterialIcon

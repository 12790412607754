import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import routes from 'web/routing/routes'

import { selectAuthSignedIn } from 'shared/modules/Auth/selectors'

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const signedIn = useSelector(state => selectAuthSignedIn(state))
  return (
    <Route
      {...otherProps}
      render={routeProps =>
        signedIn ? (
          <Component {...routeProps} {...otherProps} />
        ) : (
          <Redirect
            to={{
              pathname: routes.SignInPage.routePath,
              state: { from: routeProps.location },
            }}
            push
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
}

export default PrivateRoute

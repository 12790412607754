import React from 'react'
import PropTypes from 'prop-types'

const FullPageBgFade = ({ children, type = 'first' }) => (
  <div style={{ ...styles.wrapper, ...styles.wrapperTypes[type] }}>
    {children}
  </div>
)

const styles = {
  wrapper: {
    width: '100vw',
    minHeight: '100vh',
  },
  wrapperTypes: {
    first: {
      background: '#FFFFFF',
    },
    second: {
      background: '#FFFFFF',
    },
    third: {
      background: '#FFFFFF',
    },
  },
}

FullPageBgFade.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['first', 'second', 'third']),
}

export default FullPageBgFade

import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import { useSelector, useDispatch } from 'react-redux'
import Hidden from '@material-ui/core/Hidden'

import { selectUserFirstName } from 'shared/modules/User/selectors'
import { selectUiWelcomeMessageSeen } from 'shared/modules/UI/selectors'
import { setUiValue } from 'shared/modules/UI/actions'

import { Card, Grid, Image, ButtonPrimary } from 'web/components'
import evolveLogoLight from 'web/images/veronique.png'

import { WelcomeBackMessage } from './components'

const WelcomeMessage = () => {
  const [dismissed, setDismissed] = useState()
  const dispatch = useDispatch()
  const firstName = useSelector(state => selectUserFirstName(state))
  const welcomeMessageSeen = useSelector(state =>
    selectUiWelcomeMessageSeen(state),
  )

  const handleDismiss = () => {
    setDismissed('yes')
    dispatch(setUiValue('welcomeMessageSeen', true))
  }

  if (!dismissed && welcomeMessageSeen) {
    return (
      <Grid item xs={12} style={{ maxWidth: '925px' }}>
        <WelcomeBackMessage />
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Collapse in={dismissed !== 'yes' && !welcomeMessageSeen} timeout={500}>
        <Card style={styles.card}>
          <Grid container>
            <Hidden xsDown implementation="js">
              <Grid item sm={6} style={styles.imageWrapper}>
                <Image
                  alt="Welcome to Evolve from founder, Veronique"
                  src={evolveLogoLight}
                  style={styles.image}
                />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={6}>
              <CardContent>
                <Typography paragraph>
                  <strong>
                    Hi {firstName}, welcome to the Evolve community!
                  </strong>
                </Typography>
                <Typography paragraph variant="body2">
                  I’m so excited to be on this journey with you where we’re
                  going to move, align and transform together.
                </Typography>
                <Typography paragraph variant="body2">
                  Remember we are always here to support and inspire each other
                  to be the best version of ourselves, so please reach out and
                  give us feedback on what you’re enjoying and what you’d like
                  to see more of!
                </Typography>
                <Typography variant="body2">
                  Let’s evolve and I’ll see you on the mat! V x
                </Typography>
                <div style={styles.dismissButtonWrapper}>
                  <ButtonPrimary
                    variant="text"
                    size="small"
                    onClick={handleDismiss}
                  >
                    Dismiss
                  </ButtonPrimary>
                </div>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Collapse>
    </Grid>
  )
}

const styles = {
  card: {
    position: 'relative',
    maxWidth: 925,
  },
  imageWrapper: {
    position: 'relative',
  },
  image: {
    display: 'block',
    position: 'absolute',
    bottom: '0px',
    left: '10%',
    objectFit: 'contain',
    width: '96%',
    height: '96%',
  },
  dismissButtonWrapper: {
    marginTop: '20px',
    marginBottom: '-10px',
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  },
}

export default WelcomeMessage

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'

import { selectPromotionPromotion } from 'shared/modules/Promotion/selectors'
import { removePromotion } from 'shared/modules/Promotion/actions'

import { Grid, Alert } from 'web/components'

const ActivePromotionCodes = () => {
  const dispatch = useDispatch()
  const promotion = useSelector(state => selectPromotionPromotion(state))

  if (!promotion) return null

  return (
    <Grid item xs={12}>
      <Alert
        severity="success"
        action={
          <IconButton
            aria-label="delete"
            onClick={() => dispatch(removePromotion())}
          >
            <CancelIcon />
          </IconButton>
        }
      >
        <strong>{promotion.code}</strong>{' '}
        {promotion.description && `- ${promotion.description}`}
      </Alert>
    </Grid>
  )
}

export default ActivePromotionCodes

import { defineMessages } from 'react-intl'

export const stripeErrorMessages = defineMessages({
  incomplete_number: {
    id: 'shared.Subscription.stripeErrorMessages.incomplete_number',
    defaultMessage: 'Your card number is incomplete.',
  },
  invalid_number: {
    id: 'shared.Subscription.stripeErrorMessages.invalid_number',
    defaultMessage: 'Your card number is invalid.',
  },
  incomplete_expiry: {
    id: 'shared.Subscription.stripeErrorMessages.incomplete_expiry',
    defaultMessage: "Your card's expiration date is incomplete.",
  },
  invalid_expiry_month_past: {
    id: 'shared.Subscription.stripeErrorMessages.invalid_expiry_month_past',
    defaultMessage: "Your card's expiration month is in the past.",
  },
  invalid_expiry_year_past: {
    id: 'shared.Subscription.stripeErrorMessages.invalid_expiry_year_past',
    defaultMessage: "Your card's expiration year is in the past.",
  },
  incomplete_cvc: {
    id: 'shared.Subscription.stripeErrorMessages.incomplete_cvc',
    defaultMessage: "Your card's security code is incomplete.",
  },
  payment_intent_unexpected_state: {
    id:
      'shared.Subscription.stripeErrorMessages.payment_intent_unexpected_state',
    defaultMessage: 'This payment has already been completed. Please refresh',
  },
  payment_intent_authentication_failure: {
    id:
      'shared.Subscription.stripeErrorMessages.payment_intent_authentication_failure',
    defaultMessage:
      'We are unable to authenticate your payment method. Please choose a different payment method and try again.',
  },
  incorrect_cvc: {
    id: 'shared.Subscription.stripeErrorMessages.incorrect_cvc',
    defaultMessage: "Your card's security code is incorrect.",
  },
  api_connection_error: {
    id: 'shared.Subscription.stripeErrorMessages.api_connection_error',
    defaultMessage:
      'We are experiencing issues connecting to our payments provider. You have not been charged. Please check your internet connection and try again.',
  },
  expired_card: {
    id: 'shared.Subscription.stripeErrorMessages.expired_card',
    defaultMessage: 'Your card has expired.',
  },
  processing_error: {
    id: 'shared.Subscription.stripeErrorMessages.processing_error',
    defaultMessage:
      'An error occurred while processing your card. Try again in a little bit.',
  },
  // card_declined contains several sub-errors such as insuficient funds. We would be smart to report these back to the user.
  // https://stripe.com/docs/testing#cards-responses
  card_declined: {
    id: 'shared.Subscription.stripeErrorMessages.card_declined',
    defaultMessage: 'Your card was declined.',
  },
  PAY_001: {
    id: 'shared.Subscription.stripeErrorMessages.PAY_001',
    defaultMessage:
      'Something went wrong. If this problem persists, please contact us. {CODE}',
  },
  default: {
    id: 'shared.Subscription.stripeErrorMessages.default',
    defaultMessage:
      'Something went wrong. If this problem persists, please contact us. (payment_error_{CODE})',
  },
})

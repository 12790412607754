import { call, put, takeLatest, select, spawn } from 'redux-saga/effects'

import * as database from 'shared/firebase/database'
import { selectAuthUid } from 'shared/modules/Auth/selectors'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import { transformUser } from './utils'

export function* getFullUser({ uid: authUid }) {
  try {
    let uid = authUid
    if (!uid) {
      uid = yield select(selectAuthUid)
      if (!uid) throw Error('No UID found for user')
    }
    const userResponse = yield call(database.getUser, uid)
    const userLiveScheduleResponse = yield call(
      database.getUserLiveScheduleRegistrations,
      uid,
    )
    const userWorkoutFavouritesResponse = yield call(
      database.getUserWorkoutFavourites,
      uid,
    )
    yield put(
      actions.getUserSuccess(
        transformUser(
          userResponse,
          userLiveScheduleResponse,
          userWorkoutFavouritesResponse,
        ),
      ),
    )
  } catch (error) {
    yield put(actions.getUserError(error))
    yield spawn(throwSentryError, error)
  }
}

function* getUser() {
  try {
    const uid = yield select(selectAuthUid)
    if (!uid) throw Error('No UID defined')
    const userResponse = yield call(database.getUser, uid)
    const userLiveScheduleResponse = yield call(
      database.getUserLiveScheduleRegistrations,
      uid,
    )
    yield put(
      actions.getUserSuccess(
        transformUser(userResponse, userLiveScheduleResponse),
      ),
    )
  } catch (error) {
    yield put(actions.getUserError(error))
    yield spawn(throwSentryError, error)
  }
}

function* updateUser({ onSuccess, onError, type, ...payload }) {
  try {
    const uid = yield select(selectAuthUid)
    if (!uid) throw Error('No UID defined')
    yield call(database.setUser, uid, payload)
    yield put(actions.updateUserSuccess(payload))
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.updateUserError(error))
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* addWorkoutToFavourites({ workoutId, onSuccess, onError }) {
  try {
    const uid = yield select(selectAuthUid)
    yield call(database.addWorkoutToFavourites, uid, workoutId)
    yield put(actions.addWorkoutToFavouritesSuccess(workoutId))
    if (onSuccess) onSuccess()
  } catch (error) {
    if (onError) onError()
    yield spawn(throwSentryError, error)
  }
}

function* removeWorkoutFromFavourites({ workoutId, onSuccess, onError }) {
  try {
    const uid = yield select(selectAuthUid)
    yield call(database.removeWorkoutFromFavourites, uid, workoutId)
    yield put(actions.removeWorkoutFromFavouritesSuccess(workoutId))
    if (onSuccess) onSuccess()
  } catch (error) {
    if (onError) onError()
    yield spawn(throwSentryError, error)
  }
}

function* incrementWorkoutWatchedCount({ workoutId }) {
  try {
    const uid = yield select(selectAuthUid)
    yield call(database.incrementWorkoutWatchedCount, uid, workoutId)
  } catch (error) {
    console.warn('error...', error)
    yield spawn(throwSentryError, error)
  }
}

export default [
  takeLatest(actionTypes.GET_USER_REQUEST, getUser),
  takeLatest(actionTypes.UPDATE_USER_REQUEST, updateUser),
  takeLatest(
    actionTypes.ADD_WORKOUT_TO_FAVOURITES_REQUEST,
    addWorkoutToFavourites,
  ),
  takeLatest(
    actionTypes.REMOVE_WORKOUT_FROM_FAVOURITES_REQUEST,
    removeWorkoutFromFavourites,
  ),
  takeLatest(
    actionTypes.INCREMENT_WORKOUT_WATCHED_COUNT,
    incrementWorkoutWatchedCount,
  ),
]

import { createSelector } from 'reselect'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'

import {
  selectWorkoutCategoriesById,
  selectWorkoutCategoryById,
  selectWorkoutCategoryByIdChildrenArray,
} from 'shared/modules/WorkoutCategories/selectors'
import { selectEquipmentById } from 'shared/modules/Equipment/selectors'
import { selectMuscleGroupsById } from 'shared/modules/MuscleGroups/selectors'
import { selectUserWorkoutFavouritesById } from 'shared/modules/User/selectors'

import { NAME } from './constants'

const selectWorkoutsState = state => state[NAME]
const getWorkoutById = (state, id) => get(state[NAME], `workoutsById[${id}]`)
const getWorkoutIdBySlug = (state, slug) =>
  get(state[NAME], `workoutIdsBySlug[${slug}]`)

const selectWorkouts = createSelector(
  selectWorkoutsState,
  workoutsState => workoutsState,
)

export const selectWorkoutsLoading = createSelector(
  selectWorkouts,
  workouts => workouts.loading,
)

export const selectWorkoutsError = createSelector(
  selectWorkouts,
  workouts => workouts.error,
)

// @TODO check all are expecting object...
export const selectWorkoutsById = createSelector(
  selectWorkouts,
  selectWorkoutCategoriesById,
  selectEquipmentById,
  selectMuscleGroupsById,
  (workouts, workoutCategoriesById, equipmentById, muscleGroupsById) => {
    // @NOTE This bails out if anything is missing - we might be able to do better
    if (
      !workouts ||
      !workouts.workoutsById ||
      !workoutCategoriesById ||
      !equipmentById ||
      !muscleGroupsById
    )
      return null

    const { workoutsById } = workouts
    const orderedWorkouts = orderBy(
      Object.keys(workoutsById).reduce(
        (acc, workoutId) => ({
          ...acc,
          [workoutId]: {
            ...workoutsById[workoutId],
            categories: workoutsById[workoutId].categories?.reduce(
              (cAcc, cId) => [...cAcc, workoutCategoriesById[cId]],
              [],
            ),
            categoryIds: workoutsById[workoutId].categories,
            equipment: workoutsById[workoutId].equipment?.reduce(
              (eAcc, eId) => [...eAcc, equipmentById[eId]],
              [],
            ),
            equipmentIds: workoutsById[workoutId].equipment,
            muscleGroups: workoutsById[workoutId].muscleGroups?.reduce(
              (mAcc, mId) => [...mAcc, muscleGroupsById[mId]],
              [],
            ),
            muscleGroupIds: workoutsById[workoutId].muscleGroups,
          },
        }),
        {},
      ),
      'order',
      'asc',
    )

    if (!orderedWorkouts.length) return null

    return orderedWorkouts.reduce(
      (acc, workout) => ({
        ...acc,
        [workout.id]: workout,
      }),
      {},
    )
  },
)

export const selectWorkoutsByWorkoutParentCategoryWithWorkoutsArray = createSelector(
  selectWorkoutCategoryByIdChildrenArray,
  selectWorkoutsById,
  (workoutCategoryChildrenCategories, workouts) => {
    if (!workoutCategoryChildrenCategories?.length || !workouts)
      return undefined

    const getWorkouts = categoryId =>
      Object.values(workouts).reduce((acc, workout) => {
        if (
          workout.categoryIds?.length &&
          workout.categoryIds.indexOf(categoryId) !== -1
        ) {
          return [...acc, workout]
        }
        return acc
      }, [])

    return workoutCategoryChildrenCategories.reduce(
      (acc, category) =>
        orderBy(
          [
            ...acc,
            {
              ...category,
              workouts: orderBy(getWorkouts(category.id), 'order', 'asc'),
            },
          ],
          'order',
          'asc',
        ),
      [],
    )
  },
)

// Single Workout

export const selectWorkoutById = createSelector(
  getWorkoutById,
  selectWorkoutCategoriesById,
  selectEquipmentById,
  selectMuscleGroupsById,
  (workout, workoutCategoriesById, equipmentById, muscleGroupsById) => {
    // @NOTE This bails out if anything is missing - we might be able to do better
    if (
      !workout ||
      !workoutCategoriesById ||
      !equipmentById ||
      !muscleGroupsById
    )
      return null

    return {
      ...workout,
      categories: workout.categories?.reduce((cAcc, cId) => {
        // check the category exists before trying to spread it in...
        // (draft categories for example do not exist.)
        if (workoutCategoriesById[cId]) {
          return [...cAcc, workoutCategoriesById[cId]]
        }
        return cAcc
      }, []),
      equipment: workout.equipment?.reduce(
        (eAcc, eId) => [...eAcc, equipmentById[eId]],
        [],
      ),
      muscleGroups: workout.muscleGroups?.reduce(
        (mAcc, mId) => [...mAcc, muscleGroupsById[mId]],
        [],
      ),
    }
  },
)

export const selectWorkoutBySlug = createSelector(
  getWorkoutIdBySlug,
  selectWorkoutsById,
  (workoutId, workoutsById) => {
    if (!workoutId || !workoutsById || !workoutsById[workoutId]) return null
    return workoutsById[workoutId]
  },
)

export const selectWorkoutBySlugId = createSelector(
  selectWorkoutBySlug,
  workout => workout?.id,
)

export const selectWorkoutByIdCategories = createSelector(
  selectWorkoutById,
  workout => workout?.categories,
)

export const selectWorkoutByIdDescription = createSelector(
  selectWorkoutById,
  workout => workout?.description,
)

export const selectWorkoutByIdDuration = createSelector(
  selectWorkoutById,
  workout => workout?.duration,
)

export const selectWorkoutByIdEquipment = createSelector(
  selectWorkoutById,
  workout => workout?.equipment,
)

export const selectWorkoutByIdImage = createSelector(
  selectWorkoutById,
  workout => workout?.image,
)

export const selectWorkoutByIdMuscleGroups = createSelector(
  selectWorkoutById,
  workout => workout?.muscleGroups,
)

export const selectWorkoutByIdName = createSelector(
  selectWorkoutById,
  workout => workout?.name,
)

export const selectWorkoutByIdPublishAt = createSelector(
  selectWorkoutById,
  workout => workout?.publishAt,
)

export const selectWorkoutByIdSummary = createSelector(
  selectWorkoutById,
  workout => workout?.summary,
)

export const selectWorkoutByIdVimeoId = createSelector(
  selectWorkoutById,
  workout => workout?.vimeoId,
)

export const selectWorkoutByIdWatchCount = createSelector(
  selectWorkoutById,
  workout => get(workout, 'watchCount'),
)

export const selectWorkoutByIdFeedbackArray = createSelector(
  selectWorkoutById,
  workout => get(workout, 'feedback'),
)

export const selectWorkoutsByCategoryId = createSelector(
  selectWorkoutsById,
  selectWorkoutCategoryById,
  (workouts, workoutCategory) => {
    if (!workouts || !workoutCategory) return null

    return Object.values(workouts).reduce((acc, workout) => {
      if (
        workout.categoryIds?.length &&
        workout.categoryIds.indexOf(workoutCategory.id) !== -1
      ) {
        return {
          ...acc,
          [workout.id]: workout,
        }
      }
      return { ...acc }
    }, {})
  },
)

export const selectWorkoutsByCategoryIdArray = createSelector(
  selectWorkoutsByCategoryId,
  workoutsByCategoryId =>
    workoutsByCategoryId
      ? orderBy(Object.values(workoutsByCategoryId), ['order'], ['asc'])
      : undefined,
)

export const selectWorkoutsByFavouitiesIds = createSelector(
  selectWorkoutsById,
  selectUserWorkoutFavouritesById,
  (workoutsById, userWorkoutFavouries) => {
    if (!workoutsById || !userWorkoutFavouries) return null
    return Object.keys(userWorkoutFavouries).reduce((acc, workoutId) => {
      const workout = get(workoutsById, workoutId)
      if (workout) {
        return {
          ...acc,
          [workoutId]: {
            ...workout,
            favouritedAt: userWorkoutFavouries[workoutId].timestamp,
          },
        }
      }
      return acc
    }, null)
  },
)

export const selectWorkoutLatestByPublishAt = createSelector(
  selectWorkoutsById,
  workoutsById => {
    if (!workoutsById) return null
    return orderBy(workoutsById, 'publishAt', 'desc')[0]
  },
)

export const selectWorkoutLatestByFavourite = createSelector(
  selectWorkoutsByFavouitiesIds,
  favouriteWorkouts => {
    if (!favouriteWorkouts) return null
    return orderBy(favouriteWorkouts, 'favouritedAt', 'desc')[0]
  },
)

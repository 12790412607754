import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { selectWorkoutCategoriesByIdArray } from 'shared/modules/WorkoutCategories/selectors'

import { CircularProgress, Alert } from 'web/components'
import WorkoutsCategoryList from 'web/modules/Workouts/components/WorkoutsCategoryList'

const Workouts = ({ workouts, loading, error }) => {
  const workoutCategories = useSelector(state =>
    selectWorkoutCategoriesByIdArray(state),
  )

  if (error)
    return (
      <Alert severity="error">
        Oops, something went wrong trying to fetch the Workouts - please try
        refreshing the page.
      </Alert>
    )

  if (loading || !workouts) return <CircularProgress />

  return workoutCategories.map(({ id }) => (
    <WorkoutsCategoryList key={id} id={id} />
  ))
}

Workouts.propTypes = {
  workouts: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

export default Workouts

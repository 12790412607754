import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { PlansContainer } from 'shared/modules/Plans/components'

import { Grid, CircularProgress, Alert } from 'web/components'

import { Plan } from './components'
import messages from './messages'

const PlanSelect = ({ onSelect }) => (
  <PlansContainer>
    {({ plans, loading, error: plansError }) => {
      if (!plans || loading)
        return (
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <CircularProgress size={20} />
            </Grid>
          </Grid>
        )

      if (plansError)
        return (
          <Alert severity="error">
            <FormattedMessage {...messages.errorMessage} />
          </Alert>
        )

      return (
        <Grid container>
          {plans.map(({ id, frequency, price, priceId, access, status }) =>
            status !== 'live' ? null : (
              <Grid key={id} item xs={12} sm={6}>
                <Plan
                  frequency={frequency}
                  price={price}
                  access={access}
                  onSelect={() => onSelect(priceId)}
                />
              </Grid>
            ),
          )}
        </Grid>
      )
    }}
  </PlansContainer>
)

PlanSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default PlanSelect

import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'

import { selectWorkoutLatestByPublishAt } from 'shared/modules/Workouts/selectors'

import routes from 'web/routing/routes'
import { Grid, ButtonPrimary } from 'web/components'
import { CardWorkoutSummary } from 'web/modules/Workouts/components'

const LatestWorkout = () => {
  const history = useHistory()
  const latestWorkout = useSelector(state =>
    selectWorkoutLatestByPublishAt(state),
  )

  if (!latestWorkout) return null

  return (
    <Grid container style={styles.wrapper}>
      <Grid item xs={12}>
        <Typography variant="h3">Latest Workout</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Check out the latest workout to be added to the library
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CardWorkoutSummary
          id={latestWorkout.id}
          name={latestWorkout.name}
          image={latestWorkout.image}
          slug={latestWorkout.slug}
          summary={latestWorkout.summary}
          duration={latestWorkout.duration}
          publishAt={latestWorkout.publishAt}
        />
      </Grid>
      <Grid item xs={12}>
        <ButtonPrimary
          onClick={() => history.push(routes.WorkoutsPage.routePath)}
        >
          All Workouts
        </ButtonPrimary>
      </Grid>
    </Grid>
  )
}

const styles = {
  wrapper: {
    marginBottom: 35,
  },
}

export default LatestWorkout

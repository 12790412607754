import { defineMessages } from 'react-intl'

export default defineMessages({
  upgradeSummary: {
    id: 'web.Subscription.SubscriptionUpgrade.upgradeSummary',
    defaultMessage:
      'To upgrade instantly, you will be charged {UPGRADE_PRICE} today and an ongoing {FREQUENCY} subscription of {PRICE}.',
  },
  upgradeSummaryTrialling: {
    id: 'web.Subscription.SubscriptionUpgrade.upgradeSummaryTrialling',
    defaultMessage:
      'You can upgrade instantly today without paying anything. When your free trial ends, you will pay an ongoing {FREQUENCY} subscription of {PRICE}.',
  },
  upgradeProcessDetails: {
    id: 'web.Subscription.SubscriptionUpgrade.upgradeProcessDetails',
    defaultMessage:
      'The price paid today is the difference remaining from your current subscription. We will attempt charge the card currently held against your account. Your {FREQUENCY} charges will continue to happen on the same day.',
  },
  loadingUpgrade: {
    id: 'web.Subscription.SubscriptionUpgrade.loadingUpgrade',
    defaultMessage: 'Fetching your Upgrade details...',
  },
  payNowButton: {
    id: 'web.Subscription.SubscriptionUpgrade.payNowButton',
    defaultMessage: 'Pay Now and Updgrade',
  },
  upgradeNowButton: {
    id: 'web.Subscription.SubscriptionUpgrade.upgradeNowButton',
    defaultMessage: 'Updgrade Now',
  },
  successMessage: {
    id: 'web.Subscription.SubscriptionUpgrade.successMessage',
    defaultMessage: 'Your Subscription has been successfully upgraded!',
  },
  successMessageButton: {
    id: 'web.Subscription.SubscriptionUpgrade.successMessage',
    defaultMessage: 'Continue',
  },
  subscriptionCancelling: {
    id: 'web.Subscription.SubscriptionUpgrade.subscriptionCancelling',
    defaultMessage:
      'You have chosen to cancel your subscription. In order to upgrate your subscription, please reactivate it in My Account.',
  },
  subscriptionCancellingActionButton: {
    id:
      'web.Subscription.SubscriptionUpgrade.subscriptionCancellingActionButton',
    defaultMessage: 'My Account',
  },
})

export const errorMessages = defineMessages({
  preview: {
    id: 'web.Subscription.SubscriptionUpgrade.preview',
    defaultMessage:
      'Sorry, there was a problem getting your Upgrade details. Please try refreshing and contact us if the problem persists.',
  },
  upgrade: {
    id: 'web.Subscription.SubscriptionUpgrade.preview',
    defaultMessage:
      'Sorry, something went wrong trying to upgrade your subscription. Please try again and contact us if the problem persists.',
  },
})

import * as actionTypes from './actionTypes'

export const getWorkoutsRequest = () => ({
  type: actionTypes.GET_WORKOUTS_REQUEST,
})

export const getWorkoutsSuccess = workouts => ({
  type: actionTypes.GET_WORKOUTS_SUCCESS,
  workouts,
})

export const getWorkoutsError = error => ({
  type: actionTypes.GET_WORKOUTS_ERROR,
  error,
})

export const getWorkoutRequest = id => ({
  type: actionTypes.GET_WORKOUT_REQUEST,
  id,
})

export const getWorkoutSuccess = workout => ({
  type: actionTypes.GET_WORKOUT_SUCCESS,
  workout,
})

export const getWorkoutError = (id, error) => ({
  type: actionTypes.GET_WORKOUT_ERROR,
  id,
  error,
})

export const getWorkoutBySlugRequest = (slug, onSuccess, onError) => ({
  type: actionTypes.GET_WORKOUT_BY_SLUG_REQUEST,
  slug,
  onSuccess,
  onError,
})

export const getWorkoutBySlugSuccess = workout => ({
  type: actionTypes.GET_WORKOUT_BY_SLUG_SUCCESS,
  workout,
})

export const getWorkoutBySlugError = error => ({
  type: actionTypes.GET_WORKOUT_BY_SLUG_ERROR,
  error,
})

export const getWorkoutFeedbackRequest = id => ({
  type: actionTypes.GET_WORKOUT_FEEDBACK_REQUEST,
  id,
})

export const getWorkoutFeedbackSuccess = feedback => ({
  type: actionTypes.GET_WORKOUT_FEEDBACK_SUCCESS,
  feedback,
})

export const getWorkoutFeedbackError = (id, error) => ({
  type: actionTypes.GET_WORKOUT_FEEDBACK_ERROR,
  error,
  id,
})

export const postWorkoutFeedbackRequest = (payload, onSuccess, onError) => ({
  type: actionTypes.POST_WORKOUT_FEEDBACK_REQUEST,
  payload,
  onSuccess,
  onError,
})

export const postWorkoutFeedbackSuccess = () => ({
  type: actionTypes.POST_WORKOUT_FEEDBACK_SUCCESS,
})

export const postWorkoutFeedbackError = (workoutId, error) => ({
  type: actionTypes.POST_WORKOUT_FEEDBACK_ERROR,
  workoutId,
  error,
})

import { defineMessages } from 'react-intl'

export default defineMessages({
  downgradeScheduled: {
    id: 'web.Subscription.DowngradeScheduledAlert.downgradeScheduled',
    defaultMessage:
      'Your subscription is scheduled to be downgraded to Evolve On Demand at the end of your billing period {END}. Changed your mind? You can cancel the downgrade before it happens.',
  },
  cancelDowngradeButton: {
    id: 'web.Subscription.DowngradeScheduledAlert.cancelDowngradeButton',
    defaultMessage: 'Cancel Downgrade',
  },
})

import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'

import { selectWorkoutLatestByFavourite } from 'shared/modules/Workouts/selectors'

import routes from 'web/routing/routes'
import { Grid, ButtonPrimary, Alert } from 'web/components'
import { CardWorkoutSummary } from 'web/modules/Workouts/components'

const LatestFavouriteWorkout = () => {
  const history = useHistory()
  const latestFavouriteWorkout = useSelector(state =>
    selectWorkoutLatestByFavourite(state),
  )

  return (
    <Grid container style={styles.wrapper}>
      <Grid item xs={12}>
        <Typography variant="h3">Your Latest Favourite</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          You can add and remove workouts to your Favourites
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {!latestFavouriteWorkout ? (
          <Alert severity="info">
            You have not added any Favourites yet. To Favourite a Workout,
            simply click the Favourite button on any workout you want to save
            for later.
          </Alert>
        ) : (
          <CardWorkoutSummary
            id={latestFavouriteWorkout.id}
            name={latestFavouriteWorkout.name}
            image={latestFavouriteWorkout.image}
            slug={latestFavouriteWorkout.slug}
            summary={latestFavouriteWorkout.summary}
            duration={latestFavouriteWorkout.duration}
            publishAt={latestFavouriteWorkout.publishAt}
          />
        )}
      </Grid>
      {latestFavouriteWorkout && (
        <Grid item xs={12}>
          <ButtonPrimary
            onClick={() => history.push(routes.FavouritesPage.routePath)}
          >
            All Favourites
          </ButtonPrimary>
        </Grid>
      )}
    </Grid>
  )
}

const styles = {
  wrapper: {
    marginBottom: 35,
  },
}

export default LatestFavouriteWorkout

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import { selectUserWorkoutFavouriteByWorkoutIdTimestamp } from 'shared/modules/User/selectors'
import {
  addWorkoutToFavouritesRequest,
  removeWorkoutFromFavouritesRequest,
} from 'shared/modules/User/actions'

import { ButtonPrimary } from 'web/components'

const ButtonToggleFavourite = ({ workoutId }) => {
  const dispatch = useDispatch()
  const timestamp = useSelector(state =>
    selectUserWorkoutFavouriteByWorkoutIdTimestamp(state, workoutId),
  )

  const handleAddWorkout = () => {
    dispatch(addWorkoutToFavouritesRequest(workoutId))
  }

  const handleRemoveWorkout = () => {
    dispatch(removeWorkoutFromFavouritesRequest(workoutId))
  }

  return (
    <ButtonPrimary
      startIcon={timestamp ? <StarBorderIcon /> : <StarIcon />}
      onClick={timestamp ? handleRemoveWorkout : handleAddWorkout}
      variant="outlined"
      size="small"
    >
      {timestamp ? 'Remove' : 'Favourite'}
    </ButtonPrimary>
  )
}

ButtonToggleFavourite.propTypes = {
  workoutId: PropTypes.string.isRequired,
}

export default ButtonToggleFavourite

/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'

import { stripeErrorMessages } from 'shared/modules/Subscription/messages'

import { colors, fonts } from 'web/theme'
import {
  ButtonPrimary,
  ButtonSecondary,
  FormErrorDisplay,
  Grid,
} from 'web/components'

import { StyledInputWrapper } from './components'
import messages from './messages'

const StripeElementsCardDetailsForm = ({
  error,
  handleChange,
  handleSubmit,
  loading,
  onCancel,
  submitButtonText,
}) => {
  // @NOTE this can probably be neatened up - not sure we need to store each state separately.
  const [cardNumber, setCardNumber] = useState()
  const [cardExpiry, setCardExpiry] = useState()
  const [cardCvc, setCardCvc] = useState()
  const intl = useIntl()

  const handleInputChange = event => {
    handleChange(event)
    const errorCode = get(event, 'error.code', null)
    if (event.elementType === 'cardNumber') {
      if (errorCode) return setCardNumber(errorCode)
      return setCardNumber(null)
    }
    if (event.elementType === 'cardExpiry') {
      if (errorCode) return setCardExpiry(errorCode)
      return setCardExpiry(null)
    }
    if (event.elementType === 'cardCvc') {
      if (errorCode) return setCardCvc(errorCode)
      return setCardCvc(null)
    }
    return null
  }

  const getError = () => error || cardNumber || cardExpiry || cardCvc

  const showError = !!getError()

  return (
    <Grid container component="form">
      {showError ? (
        <Grid item xs={12}>
          <FormErrorDisplay
            error={
              showError
                ? {
                    message: intl.formatMessage(
                      stripeErrorMessages[getError()] ||
                        stripeErrorMessages.default,
                      { CODE: getError() || 'unknown' },
                    ),
                  }
                : null
            }
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <label style={styles.label}>
          <FormattedMessage {...messages.cardNumber} />
          <StyledInputWrapper error={!!cardNumber}>
            <CardNumberElement
              onChange={handleInputChange}
              options={{ style: styles.element, disabled: loading }}
            />
          </StyledInputWrapper>
        </label>
      </Grid>

      <Grid item xs={8} sm={6}>
        <label style={styles.label}>
          <FormattedMessage {...messages.cardExpiry} />
          <StyledInputWrapper error={!!cardExpiry}>
            <CardExpiryElement
              onChange={handleInputChange}
              options={{ style: styles.element, disabled: loading }}
            />
          </StyledInputWrapper>
        </label>
      </Grid>

      <Grid item xs={8} sm={6}>
        <label style={styles.label}>
          <FormattedMessage {...messages.cvc} />
          <StyledInputWrapper error={!!cardCvc}>
            <CardCvcElement
              onChange={handleInputChange}
              options={{
                style: styles.element,
                disabled: loading,
                placeholder: intl.formatMessage(messages.cvcPlaceholder),
              }}
            />
          </StyledInputWrapper>
        </label>
      </Grid>

      <Grid item xs={12} style={styles.buttonGrid}>
        <ButtonPrimary
          loading={loading}
          disabled={loading}
          onClick={handleSubmit}
          highlight
        >
          {submitButtonText}
        </ButtonPrimary>
        {onCancel && (
          <ButtonSecondary onClick={onCancel}>
            <FormattedMessage {...messages.cancelButton} />
          </ButtonSecondary>
        )}
      </Grid>
    </Grid>
  )
}

const styles = {
  label: {
    fontFamily: fonts.first,
    fontSize: '14px',
  },
  element: {
    base: {
      fontFamily: fonts.first,
      fontSize: '14px',
      color: colors.fontFirst,
      '::placeholder': {
        color: colors.placeholder,
      },
    },
    invalid: {
      color: colors.errorText,
    },
  },
  helperText: {
    padding: '0 10px',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
}

StripeElementsCardDetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  submitButtonText: PropTypes.string.isRequired,
}

export default memo(StripeElementsCardDetailsForm)

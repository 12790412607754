import { compose, createStore, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { isDev, loggerBlacklist } from 'shared/config/debugConfig'
import { additionalMiddleware } from './middleware'
import { rootReducer } from './rootReducer'

import { rootSaga } from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware, ...additionalMiddleware]

if (isDev) {
  const logger = createLogger({
    predicate: (getState, { type }) => !loggerBlacklist.includes(type),
    collapsed: true,
    diff: true,
  })
  middleware.push(logger)
}

const configureStore = () => {
  const store = createStore(
    rootReducer,
    compose(applyMiddleware(...middleware)),
  )
  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { persistor, store }
}

export default configureStore

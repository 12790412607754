/* eslint-disable camelcase */

import { SUBSCRIPTION_STATUS } from 'shared/modules/Subscription/constants'

export const transformStripeSubscription = subscription => {
  if (!subscription) return null
  const {
    id,
    status,
    cancel_at_period_end,
    current_period_end,
    current_period_start,
    discount,
    items,
  } = subscription

  const priceId = items?.data[0]?.price?.id

  return {
    id,
    status,
    cancelAtPeriodEnd: cancel_at_period_end,
    currentPeriodEnd: current_period_end,
    currentPeriodStart: current_period_start,
    ...(discount
      ? {
          promotion: {
            promotionCode: discount.promotion_code,
            start: discount.start,
            end: discount.end,
            name: discount.coupon.name,
            description: discount.coupon.metadata.description || '',
            valid: discount.coupon.valid,
          },
        }
      : null),
    ...(priceId ? { priceId } : null),
  }
}

export const transformSubscription = response => {
  if (!response) return null
  return response.data()
}

export const handlePaymentThatRequiresCustomerAction = async ({
  stripe,
  subscription,
  invoice,
  priceId,
  paymentMethodId,
  isRetry,
}) => {
  try {
    if (
      subscription &&
      (subscription.status === SUBSCRIPTION_STATUS.active ||
        subscription.status === SUBSCRIPTION_STATUS.trialing)
    ) {
      // Subscription is active or trialing, no customer actions required.
      return { subscription, priceId, paymentMethodId }
    }
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      const confirmCardPaymentResponse = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        { payment_method: paymentMethodId },
      )

      if (confirmCardPaymentResponse.error) {
        const errorPayload = {
          error: confirmCardPaymentResponse.error,
          id: subscription?.id,
          latestInvoiceId: paymentIntent.invoice,
          latestInvoicePaymentIntentStatus:
            confirmCardPaymentResponse.error?.payment_intent?.status ||
            paymentIntent.status,
          status: SUBSCRIPTION_STATUS.incomplete, // @TODO might need updating...
          priceId,
        }
        throw errorPayload
      } else if (
        confirmCardPaymentResponse.paymentIntent.status === 'succeeded'
      ) {
        return {
          priceId,
          subscription: {
            status: SUBSCRIPTION_STATUS.active,
          },
          invoiceId: invoice.id,
          paymentMethodId,
        }
      }
    }
    return { subscription, priceId, paymentMethodId }
  } catch (error) {
    throw error
  }
}

export const handleRequiresPaymentMethod = ({
  subscription,
  paymentMethodId,
  priceId,
}) => {
  if (
    subscription.status === SUBSCRIPTION_STATUS.active ||
    subscription.status === SUBSCRIPTION_STATUS.trialing
  ) {
    // subscription is active or trialing, no customer actions required.
    return { subscription, priceId, paymentMethodId }
  }
  if (
    subscription.latest_invoice.payment_intent.status ===
    'requires_payment_method'
  ) {
    return {
      error: { message: 'Card declined. Please try another payment method.' },
      id: subscription?.id,
      latestInvoiceId: subscription.latest_invoice.id,
      latestInvoicePaymentIntentStatus:
        subscription.latest_invoice.payment_intent.status,
      status: SUBSCRIPTION_STATUS.incomplete, // @TODO might need updating...
      priceId,
    }
  }
  return { subscription, priceId, paymentMethodId }
}

export const getUpgradePricesFromPreview = invoice => {
  // @NOTE Ref = https://stripe.com/docs/billing/subscriptions/prorations#preview-proration
  if (!invoice) return null
  const creditForUnused = invoice.lines.data[0]
  const timeAtNewPrice = invoice.lines.data[1]
  const futureCost = invoice.lines.data[2]

  if (!timeAtNewPrice || !futureCost) {
    return {
      upgradePrice: 0,
      futurePrice: creditForUnused.amount / 100,
    }
  }

  return {
    upgradePrice: (timeAtNewPrice.amount + creditForUnused.amount) / 100,
    futurePrice:
      futureCost.discount_amounts?.length &&
      futureCost.discount_amounts[0]?.amount !== 0
        ? (futureCost.amount - futureCost.discount_amounts?.[0].amount) / 100
        : futureCost.amount / 100,
  }
}

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.RegistrationPages.PricePlanPage.title',
    defaultMessage: 'Select your price plan',
  },
  description: {
    id: 'web.Pages.RegistrationPages.PricePlanPage.description',
    defaultMessage: 'Pick a price plan to suit you',
  },
  bodyTitle: {
    id: 'web.Pages.RegistrationPages.PricePlanPage.bodyTitle',
    defaultMessage: 'Select a plan that suits you',
  },
})

import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { getWeeklyPlanRequest } from 'shared/modules/WeeklyPlan/actions'
import {
  selectWeeklyPlanByDayWorkouts,
  selectWeeklyPlanLoading,
  selectWeeklyPlanError,
} from 'shared/modules/WeeklyPlan/selectors'

const WeeklyPlanContainer = ({ children, render }) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => selectWeeklyPlanLoading(state))
  const error = useSelector(state => selectWeeklyPlanError(state))

  const weeklyPlan = useSelector(state => selectWeeklyPlanByDayWorkouts(state))

  const dayOfweek = moment()
    .format('dddd')
    .toLowerCase()

  const todaysWorkout = weeklyPlan ? weeklyPlan[dayOfweek] : null

  const weeklyPlanArray = weeklyPlan
    ? [
        ...(weeklyPlan.monday
          ? [
              {
                ...weeklyPlan.monday,
                name: `Monday`,
                subTitle: weeklyPlan.monday.name,
              },
            ]
          : []),
        ...(weeklyPlan.tuesday
          ? [
              {
                ...weeklyPlan.tuesday,
                name: `Tuesday`,
                subTitle: weeklyPlan.tuesday.name,
              },
            ]
          : []),
        ...(weeklyPlan.wednesday
          ? [
              {
                ...weeklyPlan.wednesday,
                name: `Wednesday`,
                subTitle: weeklyPlan.wednesday.name,
              },
            ]
          : []),
        ...(weeklyPlan.thursday
          ? [
              {
                ...weeklyPlan.thursday,
                name: `Thursday`,
                subTitle: weeklyPlan.thursday.name,
              },
            ]
          : []),
        ...(weeklyPlan.friday
          ? [
              {
                ...weeklyPlan.friday,
                name: `Friday`,
                subTitle: weeklyPlan.friday.name,
              },
            ]
          : []),
        ...(weeklyPlan.saturday
          ? [
              {
                ...weeklyPlan.saturday,
                name: `Saturday`,
                subTitle: weeklyPlan.saturday.name,
              },
            ]
          : []),
        ...(weeklyPlan.sunday
          ? [
              {
                ...weeklyPlan.sunday,
                name: `Sunday`,
                subTitle: weeklyPlan.sunday.name,
              },
            ]
          : []),
      ]
    : undefined

  const getWeeklyPlan = useCallback(() => {
    dispatch(getWeeklyPlanRequest())
  }, [dispatch])

  useEffect(() => {
    getWeeklyPlan()
  }, [getWeeklyPlan])

  const renderProps = children || render

  return renderProps({
    todaysWorkout,
    weeklyPlanArray,
    loading,
    error,
  })
}

WeeklyPlanContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
}

export default WeeklyPlanContainer

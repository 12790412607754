export const NAME = 'Plans'

export const FREQUENCIES = {
  monthly: 'monthly',
  yearly: 'yearly',
}

export const FREQUENCY_SINGULAR = {
  monthly: 'month',
  yearly: 'year',
}

export const ACCESS_LEVELS = {
  10: 'basic',
  20: 'premium',
}

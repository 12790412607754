import * as actionTypes from './actionTypes'

export const getSubscriptionRequest = () => ({
  type: actionTypes.GET_SUBSCRIPTION_REQUEST,
})

export const getSubscriptionSuccess = payload => ({
  type: actionTypes.GET_SUBSCRIPTION_SUCCESS,
  ...payload,
})

export const getSubscriptionError = error => ({
  type: actionTypes.GET_SUBSCRIPTION_ERROR,
  error,
})

export const createSubscriptionRequest = (
  stripe,
  customerId,
  paymentMethodId,
  priceId,
  trialEnd,
  promotionId,
  onSuccess,
  onError,
) => ({
  type: actionTypes.CREATE_SUBSCRIPTION_REQUEST,
  stripe,
  customerId,
  paymentMethodId,
  priceId,
  trialEnd,
  promotionId,
  onSuccess,
  onError,
})

export const createSubscriptionSuccess = payload => ({
  type: actionTypes.CREATE_SUBSCRIPTION_SUCCESS,
  ...payload,
})

export const createSubscriptionError = errorPayload => ({
  type: actionTypes.CREATE_SUBSCRIPTION_ERROR,
  ...errorPayload,
})

export const cancelSubscriptionAtPeriodEndRequest = (
  cancelAtPeriodEnd,
  onSuccess,
  onError,
) => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_AT_PERIOD_END_REQUEST,
  cancelAtPeriodEnd,
  onSuccess,
  onError,
})

export const cancelSubscriptionAtPeriodEndSuccess = payload => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS,
  ...payload,
})

export const cancelSubscriptionAtPeriodEndError = error => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_AT_PERIOD_END_ERROR,
  error,
})

export const retryInvoiceRequest = (
  stripe,
  customerId,
  paymentMethodId,
  invoiceId,
  priceId,
  onSuccess,
  onError,
) => ({
  type: actionTypes.RETRY_INVOICE_REQUEST,
  stripe,
  customerId,
  paymentMethodId,
  invoiceId,
  priceId,
  onSuccess,
  onError,
})

export const retryInvoiceSuccess = payload => ({
  type: actionTypes.RETRY_INVOICE_SUCCESS,
  ...payload,
})

export const retryInvoiceError = errorPayload => ({
  type: actionTypes.RETRY_INVOICE_ERROR,
  ...errorPayload,
})

export const invoicePaymentFailed = (
  latestInvoiceId,
  latestInvoicePaymentIntentStatus,
) => ({
  type: actionTypes.INVOICE_PAYMENT_FAILED,
  latestInvoiceId,
  latestInvoicePaymentIntentStatus,
})

export const updateSubsctiptionSuccess = payload => ({
  type: actionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
  payload,
})

export const addSubscriptionPriceId = priceId => ({
  type: actionTypes.ADD_SUBSCRIPTION_PRICE_ID,
  priceId,
})

export const addSubscriptionPromotionRequest = (promotionId, onError) => ({
  type: actionTypes.ADD_SUBSCRIPTION_PROMOTION_REQUEST,
  promotionId,
  onError,
})

export const addSubscriptionPromotionSuccess = payload => ({
  type: actionTypes.ADD_SUBSCRIPTION_PROMOTION_SUCCESS,
  ...payload,
})

export const addSubscriptionPromotionError = error => ({
  type: actionTypes.ADD_SUBSCRIPTION_PROMOTION_ERROR,
  error,
})

export const updateSubscriptionPriceRequest = (
  priceId,
  onSuccess,
  onError,
) => ({
  type: actionTypes.UPDATE_SUBSCRIPTION_PRICE_REQUEST,
  priceId,
  onSuccess,
  onError,
})

export const updateSubscriptionPriceSuccess = payload => ({
  type: actionTypes.UPDATE_SUBSCRIPTION_PRICE_SUCCESS,
  ...payload,
})

export const updateSubscriptionPriceError = error => ({
  type: actionTypes.UPDATE_SUBSCRIPTION_PRICE_ERROR,
  error,
})

export const previewSubscriptionProrationRequest = (
  requestedPriceId,
  onSuccess,
  onError,
) => ({
  type: actionTypes.PREVIEW_SUBSCRIPTION_PRORATION_REQUEST,
  requestedPriceId,
  onSuccess,
  onError,
})

export const scheduleSubscriptionDowngradeRequest = (onSuccess, onError) => ({
  type: actionTypes.SCHEDULE_SUBSCRIPTION_DOWNGRADE_REQUEST,
  onSuccess,
  onError,
})

export const scheduleSubscriptionDowngradeSuccess = scheduleId => ({
  type: actionTypes.SCHEDULE_SUBSCRIPTION_DOWNGRADE_SUCCESS,
  scheduleId,
})

export const scheduleSubscriptionDowngradeError = error => ({
  type: actionTypes.SCHEDULE_SUBSCRIPTION_DOWNGRADE_ERROR,
  error,
})

export const cancelSubscriptionDowngradeRequest = (onSuccess, onError) => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_DOWNGRADE_REQUEST,
  onSuccess,
  onError,
})

export const cancelSubscriptionDowngradeSuccess = () => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_DOWNGRADE_SUCCESS,
})

export const cancelSubscriptionDowngradeError = error => ({
  type: actionTypes.CANCEL_SUBSCRIPTION_DOWNGRADE_SUCCESS,
  error,
})

import get from 'lodash/get'
import { INCREMENT_WORKOUT_WATCHED_COUNT } from 'shared/modules/User/actionTypes'
import * as actionTypes from './actionTypes'

const initialState = {
  workoutsById: null,
  workoutIdsBySlug: null,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WORKOUTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GET_WORKOUTS_SUCCESS:
      return {
        ...state,
        workoutsById: action.workouts.byId,
        workoutIdsBySlug: action.workouts.bySlug,
        loading: false,
      }
    case actionTypes.GET_WORKOUTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case actionTypes.GET_WORKOUT_SUCCESS:
    case actionTypes.GET_WORKOUT_BY_SLUG_SUCCESS:
      return {
        ...state,
        workoutsById: {
          ...state.workoutsById,
          [action.workout.id]: {
            ...get(state, `workoutsById[${action.workout.id}]`),
            ...action.workout,
          },
        },
        workoutIdsBySlug: {
          ...state.workoutIdsBySlug,
          [action.workout.slug]: action.workout.id,
        },
      }
    case INCREMENT_WORKOUT_WATCHED_COUNT:
      return {
        ...state,
        workoutsById: {
          ...state.workoutsById,
          [action.workoutId]: {
            ...get(state, `workoutsById[${action.workoutId}]`),
            watchCount: state.workoutsById[action.workoutId].watchCount
              ? state.workoutsById[action.workoutId].watchCount + 1
              : 1,
          },
        },
      }
    case actionTypes.GET_WORKOUT_FEEDBACK_SUCCESS: {
      if (!action.feedback) {
        return state
      }
      return {
        ...state,
        workoutsById: {
          ...state.workoutsById,
          [action.feedback.id]: {
            ...get(state, `workoutsById[${action.feedback.id}]`),
            feedback: action.feedback.results,
          },
        },
      }
    }
    default:
      return state
  }
}

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.DashboardPage.title',
    defaultMessage: 'Weekly Plan',
  },
  description: {
    id: 'web.Pages.DashboardPage.description',
    defaultMessage: 'Take the effort out of planning and follow our guide',
  },
})

export const NAME = 'Subscription'

export const CREATE_SUBSCRIPTION_URL = '/subscription/create'
export const CANCEL_SUBSCRIPTION_AT_PERIOD_END_URL =
  '/subscription/cancel-at-period-end'
export const RETRY_SUBSCRIPTION_URL = '/subscription/retry'
export const ADD_SUBSCRIPTION_PROMOTION_URL = '/subscription/add-promotion'
export const UPDATE_SUBSCRIPTION_PRICE_URL = '/subscription/update-price'
export const PREVIEW_PRORATION_URL = '/subscription/preview-proration'
export const SCHEDULE_DOWNGRADE_URL = '/subscription/schedule-downgrade'
export const CANCEL_DOWNGRADE_URL = '/subscription/cancel-downgrade'

export const SUBSCRIPTION_STATUS = {
  active: 'active',
  incomplete: 'incomplete',
  incompleteExpired: 'incomplete_expired',
  trialing: 'trialing',
  pastDue: 'past_due',
  canceled: 'canceled',
  inpaid: 'unpaid',
}

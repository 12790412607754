import React from 'react'
import PropTypes from 'prop-types'
import MuiCard from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'

const Card = ({ children, ...rest }) => {
  const classes = useStyles()

  return (
    <MuiCard
      variant="outlined"
      classes={{
        root: classes.root,
      }}
      {...rest}
    >
      {children}
    </MuiCard>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,
  },
}))

Card.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Card

import React, { useState, useEffect, useRef } from 'react'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'

import { UserContainer } from 'shared/modules/User/components'

import { ButtonSecondary, Grid, Card, Alert } from 'web/components'
import UpdateUserDetailsForm from 'web/modules/User/components/UpdateUserDetailsForm'
import {
  UpdateEmailForm,
  UpdatePasswordForm,
} from 'web/modules/Auth/components'

const UserDetails = () => {
  const [showAccountEdit, setShowAccountEdit] = useState()
  const [showAccountSuccess, setShowAccountSuccess] = useState()
  const [showEmailEdit, setShowEmailEdit] = useState()
  const [showEmailSuccess, setShowEmailSuccess] = useState()
  const [showPasswordEdit, setShowPasswordEdit] = useState()
  const [showPasswordSuccess, setShowPasswordSuccess] = useState()
  const showAccountSuccessTimeoutRef = useRef()
  const showEmailSuccessTimeoutRef = useRef()
  const showPasswordSuccessTimeoutRef = useRef()

  const handleUserDetailsSuccess = () => {
    setShowAccountSuccess(true)
    setShowAccountEdit(false)
  }
  const handleEmailSuccess = () => {
    setShowEmailSuccess(true)
    setShowEmailEdit(false)
  }
  const handlePasswordSuccess = () => {
    setShowPasswordSuccess(true)
    setShowPasswordEdit(false)
  }

  const handleCancelUserDetails = () => setShowAccountEdit(false)
  const handleCancelEmail = () => setShowEmailEdit(false)
  const handleCancelPassword = () => setShowPasswordEdit(false)

  useEffect(() => {
    if (showAccountSuccess) {
      showAccountSuccessTimeoutRef.current = setTimeout(
        () => setShowAccountSuccess(false),
        5000,
      )
    }
    if (showEmailSuccess) {
      showEmailSuccessTimeoutRef.current = setTimeout(
        () => setShowEmailSuccess(false),
        5000,
      )
    }
    if (showPasswordSuccess) {
      showPasswordSuccessTimeoutRef.current = setTimeout(
        () => setShowPasswordSuccess(false),
        5000,
      )
    }
    return () => {
      if (showAccountSuccessTimeoutRef.current)
        clearTimeout(showAccountSuccessTimeoutRef.current)
      if (showEmailSuccessTimeoutRef.current)
        clearTimeout(showEmailSuccessTimeoutRef.current)
      if (showPasswordSuccessTimeoutRef.current)
        clearTimeout(showPasswordSuccessTimeoutRef.current)
    }
  }, [showAccountSuccess, showEmailSuccess, showPasswordSuccess])

  return (
    <UserContainer>
      {({ email, firstName, lastName }) => (
        <>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Personal Details" />
              <CardContent>
                <Collapse in={showAccountSuccess}>
                  <Alert severity="success" style={styles.successNotice}>
                    Your details have been updated successfully.
                  </Alert>
                </Collapse>

                <Typography variant="body1" gutterBottom>
                  <strong>First name:</strong> {firstName}
                </Typography>
                <Typography variant="body1">
                  <strong>Last name:</strong> {lastName}
                </Typography>

                <Collapse in={showAccountEdit}>
                  <Card style={styles.formCard}>
                    <CardContent>
                      <UpdateUserDetailsForm
                        initialValues={{ firstName, lastName }}
                        onSuccess={handleUserDetailsSuccess}
                        onCancel={handleCancelUserDetails}
                      />
                    </CardContent>
                  </Card>
                </Collapse>
              </CardContent>
              <CardActions>
                <ButtonSecondary
                  style={styles.editButton}
                  onClick={() => setShowAccountEdit(true)}
                >
                  Update Personal Details
                </ButtonSecondary>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader title="Private Details" />
              <CardContent>
                <Collapse in={showEmailSuccess}>
                  <Alert severity="success" style={styles.successNotice}>
                    Your Email address has been updated successfully.
                  </Alert>
                </Collapse>
                <Collapse in={showPasswordSuccess}>
                  <Alert severity="success" style={styles.successNotice}>
                    Your Password has been changed successfully.
                  </Alert>
                </Collapse>
                <Typography variant="body1" gutterBottom>
                  <strong>Email:</strong> {email}
                </Typography>
                <Typography variant="body1">
                  <strong>Password:</strong> ********
                </Typography>

                <Collapse in={showEmailEdit}>
                  <Card style={styles.formCard}>
                    <CardContent>
                      <UpdateEmailForm
                        onSuccess={handleEmailSuccess}
                        onCancel={handleCancelEmail}
                      />
                    </CardContent>
                  </Card>
                </Collapse>

                <Collapse in={showPasswordEdit}>
                  <Card style={styles.formCard}>
                    <CardContent>
                      <UpdatePasswordForm
                        onSuccess={handlePasswordSuccess}
                        onCancel={handleCancelPassword}
                      />
                    </CardContent>
                  </Card>
                </Collapse>
              </CardContent>
              <CardActions>
                <ButtonSecondary
                  onClick={() => {
                    setShowPasswordEdit(false)
                    setShowEmailEdit(true)
                  }}
                >
                  Update Email
                </ButtonSecondary>
                <ButtonSecondary
                  onClick={() => {
                    setShowEmailEdit(false)
                    setShowPasswordEdit(true)
                  }}
                >
                  Change Password
                </ButtonSecondary>
              </CardActions>
            </Card>
          </Grid>
        </>
      )}
    </UserContainer>
  )
}

const styles = {
  formCard: {
    marginTop: 30,
    maxWidth: 500,
  },
  successNotice: {
    marginBottom: '30px',
  },
}

export default UserDetails

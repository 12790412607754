import { call, put, takeLatest, spawn } from 'redux-saga/effects'

import * as database from 'shared/firebase/database'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import { transformPlans } from './utils'

export function* getPlans() {
  try {
    const response = yield call(database.getPlans)
    yield put(actions.getPlansSuccess(transformPlans(response)))
  } catch (error) {
    yield put(actions.getPlansError(error))
    yield spawn(throwSentryError, error)
  }
}

export default [takeLatest(actionTypes.GET_PLANS_REQUEST, getPlans)]

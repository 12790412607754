import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'

import { Grid, Card } from 'web/components'

import { Image } from './components'

const CardItemSummary = ({ children, title, name, image, cardActions }) => (
  <Card style={styles.card}>
    <Grid container>
      <Grid item xs={12} sm={6} style={styles.imageWrapper}>
        <Image
          alt={title || name}
          publicId={image.publicId}
          format={image.format}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CardContent style={styles.cardContent}>
          {title && (
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
          )}
          {children}
        </CardContent>
        {cardActions && <CardActions>{cardActions}</CardActions>}
      </Grid>
    </Grid>
  </Card>
)

const styles = {
  card: {
    maxWidth: '910px',
  },
  cardContent: {
    maxWidth: '380px',
  },
  imageWrapper: {
    position: 'relative',
    flex: 1,
    minHeight: 220,
  },
}

CardItemSummary.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.object.isRequired,
  cardActions: PropTypes.node,
}

export default CardItemSummary

import routes from 'web/routing/routes'

import { notAuthenticatedRoutes, authenticatedRoutes } from './messages'
import { NAV_HIDDEN_ROUTES } from './constants'

const notAuthenticatedUserRoutes = [
  {
    routePath: routes.SignInPage.routePath,
    name: notAuthenticatedRoutes.signInPage,
  },
  {
    routePath: routes.RegistrationUserDetailsPage.routePath,
    name: notAuthenticatedRoutes.RegistrationUserDetailsPage,
  },
]

const authenticatedUserRoutes = [
  {
    routePath: routes.DashboardPage.routePath,
    name: authenticatedRoutes.dashboardPage,
    icon: 'Home',
  },
  {
    routePath: routes.LiveSchedulePage.routePath,
    name: authenticatedRoutes.liveSchedulePage,
    icon: 'LiveTv',
  },
  {
    routePath: routes.WeeklyPlanPage.routePath,
    name: authenticatedRoutes.weeklyPlanPage,
    icon: 'Today',
  },
  {
    routePath: routes.WorkoutsPage.routePath,
    name: authenticatedRoutes.workoutsPage,
    icon: 'AccessibilityNew',
  },
  {
    routePath: routes.FavouritesPage.routePath,
    name: authenticatedRoutes.favourtiesPage,
    icon: 'Star',
  },
  {
    routePath: routes.AccountDetailsPage.routePath,
    name: authenticatedRoutes.accountDetailsPage,
    icon: 'AccountBox',
  },
]

export const getDrawerRoutes = isAuthenticated => {
  if (isAuthenticated) return authenticatedUserRoutes
  return notAuthenticatedUserRoutes
}

export const shouldHideNav = routePath => {
  if (NAV_HIDDEN_ROUTES.indexOf(routePath) !== -1) return true
  return false
}

import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import orderBy from 'lodash/orderBy'

import { getLiveScheduleRequest } from 'shared/modules/LiveSchedule/actions'
import {
  selectLiveScheduleLoading,
  selectLiveScheduleError,
  selectLiveSchedulePastItemsById,
  selectLiveScheduleFutureItemsById,
  selectLiveScheduleActiveItemsById,
} from 'shared/modules/LiveSchedule/selectors'

const LiveScheduleContainer = ({ children, render }) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => selectLiveScheduleLoading(state))
  const error = useSelector(state => selectLiveScheduleError(state))

  const pastLiveScheduleItemsById = useSelector(state =>
    selectLiveSchedulePastItemsById(state),
  )
  const futureLiveScheduleItemsById = useSelector(state =>
    selectLiveScheduleFutureItemsById(state),
  )
  const activeLiveScheduleItemsById = useSelector(state =>
    selectLiveScheduleActiveItemsById(state),
  )

  const pastLiveScheduleItems = pastLiveScheduleItemsById
    ? orderBy(
        Object.values(pastLiveScheduleItemsById),
        ['startDateTime'],
        ['desc'],
      )
    : undefined

  const futureLiveScheduleItems = futureLiveScheduleItemsById
    ? orderBy(
        Object.values(futureLiveScheduleItemsById),
        ['startDateTime'],
        ['asc'],
      )
    : undefined

  const activeLiveScheduleItems = activeLiveScheduleItemsById
    ? orderBy(
        Object.values(activeLiveScheduleItemsById),
        ['startDateTime'],
        ['desc'],
      )
    : undefined

  const renderProps = children || render

  useEffect(() => {
    dispatch(getLiveScheduleRequest())
  }, []) // eslint-disable-line

  return renderProps({
    pastLiveScheduleItems,
    futureLiveScheduleItems,
    activeLiveScheduleItems,
    loading,
    error,
  })
}

LiveScheduleContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
}

export default LiveScheduleContainer

import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import { useHistory } from 'react-router-dom'

import routes from 'web/routing/routes'
import { Card, Grid } from 'web/components'
import WorkoutsCarousel from 'web/modules/Workouts/components/WorkoutsCarousel'

import { Image } from './components'

// @TODO check this out to make sure it's performing correctly.

const WorkoutCategoriesList = ({ categories }) => {
  const history = useHistory()

  return (
    <Grid container>
      {categories.map(
        ({
          image,
          name,
          summary,
          slug,
          id,
          workoutsCount,
          workoutsDuration,
          children,
        }) =>
          children?.length ? (
            <Grid key={id} item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h3">{name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <WorkoutsCarousel workouts={children} />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid key={id} item xs={12} sm={6} lg={3}>
              <Card>
                <CardActionArea
                  onClick={() =>
                    history.push(
                      `${routes.WorkoutCategoryPage.routeName}${slug}`,
                    )
                  }
                >
                  <Image {...image} alt={name} />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      {name}
                    </Typography>
                    <Typography paragraph variant="body2" color="textSecondary">
                      {summary}
                    </Typography>
                    <Chip
                      size="small"
                      label={`${workoutsCount} workouts / ${workoutsDuration} minutes`}
                      style={styles.chip}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ),
      )}
    </Grid>
  )
}

const styles = {
  chip: {
    marginRight: 4,
  },
}

WorkoutCategoriesList.propTypes = {
  categories: PropTypes.array,
}

export default WorkoutCategoriesList

// @TODO update the displayed content to use Grid and translate copy
import React, { useState, useRef, useEffect } from 'react'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { FormattedMessage } from 'react-intl'
import { generatePath } from 'react-router-dom'

import { formatUnixDate } from 'shared/utils/DateTimeUtils'
import { formatPaymentCardExpiry } from 'shared/utils/StringUtils'
import { SubscriptionDetailsContainer } from 'shared/modules/Subscription/components'
import { ACCESS_LEVELS } from 'shared/modules/Plans/constants'
import { plansMessages } from 'shared/modules/Plans/messages'

import routes from 'web/routing/routes'
import {
  CircularProgress,
  ButtonSecondary,
  Card,
  Alert,
  Grid,
  FormattedMoney,
  Link,
} from 'web/components'
import { UpdatePaymentMethodForm } from 'web/modules/PaymentMethod/components'
import DowngradeScheduledAlert from 'web/modules/Subscription/components/DowngradeScheduledAlert'
import { UpcomingInvoiceAlert } from 'web/modules/Invoices/components'

import { CancelSubscriptionButton, AddPromotion } from './components'
import messages from './messages'

const SubscriptionDetails = () => {
  const [showUpdateCardForm, setShowUpdateCardForm] = useState()
  const [showUpdateCardSuccess, setShowUpdateCardSuccess] = useState()
  const showUpdateCardSuccessTimeoutRef = useRef()

  const handleUpdateCardSuccess = () => {
    setShowUpdateCardForm(false)
    setShowUpdateCardSuccess(true)
  }

  const handleCancelUpdateCardForm = () => setShowUpdateCardForm(false)

  useEffect(() => {
    if (showUpdateCardSuccess) {
      showUpdateCardSuccessTimeoutRef.current = setTimeout(
        () => setShowUpdateCardSuccess(false),
        5000,
      )
    }
    return () => {
      if (showUpdateCardSuccessTimeoutRef.current)
        clearTimeout(showUpdateCardSuccessTimeoutRef.current)
    }
  }, [showUpdateCardSuccess])

  return (
    <SubscriptionDetailsContainer>
      {({
        status,
        cancelAtPeriodEnd,
        currentPeriodEnd,
        currentPeriodStart,
        last4,
        expMonth,
        expYear,
        price,
        frequency,
        access,
        promotionCode,
        promotionName,
        promotionDescription,
        scheduleId,
        loading,
        error,
      }) => (
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Subscription and Billing Details" />
              <CardContent>
                <Grid container>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {error || !currentPeriodStart || !currentPeriodEnd ? (
                        <Grid item xs={12}>
                          <Alert severity={error ? 'error' : 'info'}>
                            {error ? (
                              <FormattedMessage
                                {...messages.subscriptionError}
                              />
                            ) : (
                              <FormattedMessage
                                {...messages.subscriptionPending}
                              />
                            )}
                          </Alert>
                        </Grid>
                      ) : (
                        <>
                          <Collapse in={showUpdateCardSuccess}>
                            <Alert severity="success">
                              <FormattedMessage
                                {...messages.cardDetailsUpdated}
                              />
                            </Alert>
                          </Collapse>
                          <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                              {!access || !status || !frequency || !price ? (
                                <FormattedMessage
                                  {...messages.subscriptionSummaryLoading}
                                  values={{
                                    strong: chunks => <strong>{chunks}</strong>,
                                  }}
                                />
                              ) : (
                                <FormattedMessage
                                  {...messages.subscriptionSummary}
                                  values={{
                                    ACCESS: (
                                      <FormattedMessage
                                        {...plansMessages[
                                          ACCESS_LEVELS[access]
                                        ]}
                                      />
                                    ),
                                    STATUS: status,
                                    FREQUENCY: frequency,
                                    PRICE: <FormattedMoney value={price} />,
                                    strong: chunks => <strong>{chunks}</strong>,
                                  }}
                                />
                              )}
                              {access < 20 && (
                                <>
                                  {' - '}
                                  <Link
                                    to={generatePath(
                                      routes.AccountUpgradePage.routePath,
                                    )}
                                  >
                                    <FormattedMessage
                                      {...messages.upgradeSubscription}
                                    />
                                  </Link>
                                </>
                              )}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              <strong>Last payment:</strong>{' '}
                              {formatUnixDate(currentPeriodStart)}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              <strong>Card number:</strong> **** **** ****{' '}
                              {last4}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                              <strong>Card Expiry:</strong>{' '}
                              {formatPaymentCardExpiry(expMonth, expYear)}
                            </Typography>
                          </Grid>
                          {promotionCode && (
                            <Grid item xs={12}>
                              <Alert severity="success">
                                <AlertTitle>Active Promotion</AlertTitle>
                                <strong>{promotionName}</strong>{' '}
                                {promotionDescription &&
                                  `- ${promotionDescription}`}
                              </Alert>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <UpcomingInvoiceAlert
                              status={status}
                              currentPeriodEnd={currentPeriodEnd}
                              cancelAtPeriodEnd={cancelAtPeriodEnd}
                            />
                          </Grid>
                        </>
                      )}
                      {scheduleId && (
                        <Grid item xs={12}>
                          <DowngradeScheduledAlert />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Collapse in={showUpdateCardForm}>
                          <Card>
                            <CardContent>
                              <UpdatePaymentMethodForm
                                onSuccess={handleUpdateCardSuccess}
                                onCancel={handleCancelUpdateCardForm}
                              />
                            </CardContent>
                          </Card>
                        </Collapse>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
              {!error && currentPeriodStart && currentPeriodEnd ? (
                <CardActions>
                  <ButtonSecondary onClick={() => setShowUpdateCardForm(true)}>
                    Update Card Details
                  </ButtonSecondary>
                  {!scheduleId && !cancelAtPeriodEnd && access > 10 && (
                    <ButtonSecondary
                      component={Link}
                      to={generatePath(routes.AccountDowngradePage.routePath)}
                    >
                      Downgrade Subscription
                    </ButtonSecondary>
                  )}
                  <CancelSubscriptionButton />
                </CardActions>
              ) : null}
            </Card>
          </Grid>

          {!promotionCode && (
            <Grid item xs={12}>
              <AddPromotion
                canceling={!!cancelAtPeriodEnd}
                downgrading={!!scheduleId}
              />
            </Grid>
          )}
        </Grid>
      )}
    </SubscriptionDetailsContainer>
  )
}

export default SubscriptionDetails

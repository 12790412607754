import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'

import routes from 'web/routing/routes'
import { CardItemSummary, ButtonSecondary } from 'web/components'
import ButtonToggleFavourite from 'web/modules/Workouts/components/ButtonToggleFavourite'

const CardWorkoutSummary = ({
  id,
  name,
  summary,
  image,
  duration,
  slug,
  publishAt,
}) => {
  const history = useHistory()
  return (
    <CardItemSummary
      title={name}
      image={image}
      cardActions={
        <>
          <ButtonSecondary
            onClick={() =>
              history.push(`${routes.WorkoutPage.routeName}${slug}`)
            }
          >
            View Workout
          </ButtonSecondary>
          <ButtonToggleFavourite workoutId={id} />
        </>
      }
    >
      <Typography paragraph variant="body2" color="textSecondary">
        {summary.length > 100 ? `${summary.substr(0, 100 - 1)}...` : summary}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {duration} minutes | Added {moment(publishAt).fromNow()}
      </Typography>
    </CardItemSummary>
  )
}

CardWorkoutSummary.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  duration: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  publishAt: PropTypes.number.isRequired,
}

export default CardWorkoutSummary

import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

import { PlansContainer } from 'shared/modules/Plans/components'
import { FREQUENCY_SINGULAR } from 'shared/modules/Plans/constants'

import { CircularProgress, Alert } from 'web/components'

const SelectedSubscriptionDetails = ({ priceId, freeTrial }) => (
  <PlansContainer>
    {({ plansByPriceId, loading, error }) => {
      if (loading && !plansByPriceId) return <CircularProgress />
      if (error)
        return <Alert severity="error">Oops, something went wrong.</Alert>
      const plan = plansByPriceId[priceId]
      return (
        <>
          {freeTrial ? (
            <Typography variant="body1" paragraph>
              You have opted to pay <strong>{plan.frequency}</strong> for your
              membership.
            </Typography>
          ) : (
            <Typography variant="body1" paragraph>
              You have opted to pay <strong>{plan.frequency}</strong> for your
              membership.
            </Typography>
          )}
          {freeTrial ? (
            <Alert severity="info">
              You will be charged{' '}
              <strong>
                £{plan.price} {plan.frequency}
              </strong>{' '}
              when your free trial period comes to an end in{' '}
              <strong>7 days</strong>. Remember, you can cancel anytime before
              or after your free trial period!
            </Alert>
          ) : (
            <Alert severity="info">
              You will be charged £{plan.price} now and the same each{' '}
              {FREQUENCY_SINGULAR[plan.frequency]}
            </Alert>
          )}
        </>
      )
    }}
  </PlansContainer>
)

SelectedSubscriptionDetails.propTypes = {
  priceId: PropTypes.string.isRequired,
  freeTrial: PropTypes.bool,
}

export default SelectedSubscriptionDetails

import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectAuthSignedIn } from 'shared/modules/Auth/selectors'

import routes from 'web/routing/routes'
import { Navigation as Nav } from 'web/modules/PageLayout/components'
import {
  SubscribedRoute,
  PublicOnlyRoute,
  ScrollIntoView,
  PrivateRoute,
} from 'web/modules/Navigation/components'

import DashboardPage from 'web/pages/DashboardPage'
import AccountDetailsPage from 'web/pages/AccountPages/AccountDetailsPage'
import AccountUpgradePage from 'web/pages/AccountPages/AccountUpgradePage'
import AccountDowngradePage from 'web/pages/AccountPages/AccountDowngradePage'
import WorkoutsPage from 'web/pages/WorkoutsPage'
import WorkoutPage from 'web/pages/WorkoutPage'
import LiveSchedulePage from 'web/pages/LiveSchedulePage'
import LiveScheduleItemPage from 'web/pages/LiveScheduleItemPage'
import FavouritesPage from 'web/pages/FavouritesPage'
import WeeklyPlanPage from 'web/pages/WeeklyPlanPage'
import WorkoutCategoryPage from 'web/pages/WorkoutCategoryPage'

import UserDetailsPage from 'web/pages/RegistrationPages/UserDetailsPage'
import PricePlanPage from 'web/pages/RegistrationPages/PricePlanPage'
import BillingDetailsPage from 'web/pages/RegistrationPages/BillingDetailsPage'

import FreeTrialUserDetailsPage from 'web/pages/RegistrationFreeTrialPages/UserDetailsPage'
import FreeTrialPricePlanPage from 'web/pages/RegistrationFreeTrialPages/PricePlanPage'
import FreeTrialBillingDetailsPage from 'web/pages/RegistrationFreeTrialPages/BillingDetailsPage'

import SignInPage from 'web/pages/SignInPage'
import ResetPasswordPage from 'web/pages/ResetPasswordPage'

import Error404Page from 'web/pages/Error404Page'

export const Navigation = () => {
  const signedIn = useSelector(state => selectAuthSignedIn(state))
  return (
    <ScrollIntoView>
      <Nav />
      <Switch>
        <Route
          exact
          path={routes.DefaultPage.routePath}
          component={() => {
            if (signedIn) {
              return (
                <Redirect
                  to={{
                    pathname: routes.DashboardPage.routePath,
                  }}
                />
              )
            }
            return (
              <Redirect
                to={{
                  pathname: routes.SignInPage.routePath,
                }}
              />
            )
          }}
        />
        <PublicOnlyRoute
          exact
          path={routes.SignInPage.routePath}
          component={SignInPage}
        />
        <PublicOnlyRoute
          exact
          path={routes.ResetPasswordPage.routePath}
          component={ResetPasswordPage}
        />
        <Route
          exact
          path={routes.RegistrationUserDetailsPage.routePath}
          component={UserDetailsPage}
        />
        <PrivateRoute
          exact
          path={routes.RegistrationPricePlanPage.routePath}
          component={PricePlanPage}
        />
        <PrivateRoute
          exact
          path={routes.RegistrationBillingDetailsPage.routePath}
          component={BillingDetailsPage}
        />
        <Route
          exact
          path={routes.RegistrationFreeTrialUserDetailsPage.routePath}
          component={FreeTrialUserDetailsPage}
        />
        <PrivateRoute
          exact
          path={routes.RegistrationFreeTrialPricePlanPage.routePath}
          component={FreeTrialPricePlanPage}
        />
        <PrivateRoute
          exact
          path={routes.RegistrationFreeTrialBillingDetailsPage.routePath}
          component={FreeTrialBillingDetailsPage}
        />
        <SubscribedRoute
          exact
          path={routes.AccountDetailsPage.routePath}
          component={AccountDetailsPage}
        />
        <SubscribedRoute
          exact
          path={routes.AccountUpgradePage.routePath}
          component={AccountUpgradePage}
        />
        <SubscribedRoute
          exact
          path={routes.AccountDowngradePage.routePath}
          component={AccountDowngradePage}
        />
        <SubscribedRoute
          exact
          path={routes.DashboardPage.routePath}
          component={DashboardPage}
        />
        <SubscribedRoute
          exact
          path={routes.WorkoutsPage.routePath}
          component={WorkoutsPage}
        />
        <SubscribedRoute
          exact
          path={routes.WorkoutPreviewPage.routePath}
          component={WorkoutPage}
        />
        <SubscribedRoute
          exact
          path={routes.WorkoutPage.routePath}
          component={WorkoutPage}
        />
        <SubscribedRoute
          exact
          path={routes.LiveSchedulePage.routePath}
          component={LiveSchedulePage}
        />
        <SubscribedRoute
          exact
          path={routes.LiveScheduleItemPage.routePath}
          component={LiveScheduleItemPage}
        />
        <SubscribedRoute
          exact
          path={routes.FavouritesPage.routePath}
          component={FavouritesPage}
        />
        <SubscribedRoute
          exact
          path={routes.WeeklyPlanPage.routePath}
          component={WeeklyPlanPage}
        />
        <SubscribedRoute
          exact
          path={routes.WorkoutCategoryPage.routePath}
          component={WorkoutCategoryPage}
        />
        <Route component={Error404Page} />
      </Switch>
    </ScrollIntoView>
  )
}

export default Navigation

import { createSelector } from 'reselect'

import { NAME } from './constants'

export const selectInvoicesState = state => state[NAME]

const selectInvoices = createSelector(
  selectInvoicesState,
  invoicesState => invoicesState,
)

// Upcoming invoice...

const selectInvoicesUpcoming = createSelector(
  selectInvoices,
  invoices => invoices?.upcoming,
)

export const selectInvoicesUpcomingLastLoaded = createSelector(
  selectInvoicesUpcoming,
  invoiceUpcoming => invoiceUpcoming?.lastLoaded,
)

export const selectInvoicesUpcomingTotal = createSelector(
  selectInvoicesUpcoming,
  invoiceUpcoming => invoiceUpcoming?.total,
)

export const selectInvoicesUpcomingSubtotal = createSelector(
  selectInvoicesUpcoming,
  invoiceUpcoming => invoiceUpcoming?.subtotal,
)

export const selectInvoicesUpcomingNextPaymentAttempt = createSelector(
  selectInvoicesUpcoming,
  invoiceUpcoming => invoiceUpcoming?.nextPaymentAttempt,
)

export const selectInvoicesUpcomingLoading = createSelector(
  selectInvoicesUpcoming,
  invoiceUpcoming => invoiceUpcoming?.loading,
)

export const selectInvoicesUpcomingError = createSelector(
  selectInvoicesUpcoming,
  invoiceUpcoming => invoiceUpcoming?.error,
)

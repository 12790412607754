import React from 'react'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import { FormattedMessage } from 'react-intl'

import routes from 'web/routing/routes'
import {
  HelmetLocalised,
  FullPageBgFade,
  Image,
  Grid,
  Link,
  Card,
} from 'web/components'
import logo from 'web/images/evolve-logo.png'
import { ResetPasswordForm } from 'web/modules/Auth/components'

import messages from './messages'

const ResetPasswordPage = () => (
  <>
    <HelmetLocalised
      title={messages.title}
      description={messages.description}
    />
    <FullPageBgFade type="second">
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={0}
        style={styles.wrapper}
      >
        <Grid item xs={11} md={6} lg={4}>
          <Grid container justify="center">
            <Grid item xs={9} sm={7} md={8}>
              <Image src={logo} alt="Evolve" />
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={10}>
              <Typography
                gutterBottom
                variant="h3"
                component="h1"
                align="center"
              >
                <FormattedMessage {...messages.bodyTitle} />
              </Typography>
              <Card style={styles.footer}>
                <CardContent>
                  <ResetPasswordForm />
                  <Typography
                    variant="body2"
                    align="center"
                    style={styles.subText}
                  >
                    <Link to={routes.SignInPage.routePath}>
                      <FormattedMessage {...messages.backToSignIn} />
                    </Link>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullPageBgFade>
  </>
)

const styles = {
  wrapper: {
    display: 'flex',
    height: '100vh',
  },
  image: {
    mixBlendMode: 'luminosity',
    width: '100%',
  },
  subText: {
    marginTop: '15px',
  },
  footer: {
    marginBottom: '40px',
  },
}

export default ResetPasswordPage

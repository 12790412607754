export const transformPaymentMethod = response => {
  if (!response) return null
  return response.data()
}

export const transformStripePaymentMethod = paymentMethod => {
  if (!paymentMethod) return null
  const {
    exp_month: expMonth,
    exp_year: expYear,
    brand,
    last4,
  } = paymentMethod.card
  return {
    expMonth,
    expYear,
    brand,
    last4,
  }
}

import Styled from 'styled-components'

const StyledWrapper = Styled.div`
  width: ${props => (props.size ? props.size : '80')}px;
  height: ${props => (props.size ? props.size : '80')}px;
  display: table-cell;
  vertical-align: middle;
`

export default StyledWrapper

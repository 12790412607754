import { defineMessages } from 'react-intl'

export default defineMessages({
  emailLabel: {
    id: 'web.Auth.SignInForm.emailLabel',
    defaultMessage: 'Email Address',
  },
  submitButton: {
    id: 'web.Auth.SignInForm.submitButton',
    defaultMessage: 'Submit',
  },
  success: {
    id: 'web.Auth.SignInForm.success',
    defaultMessage:
      'A link has been sent to your email address that will allow you to reset your password. Please allow 5 minutes for the email to arrive.',
  },
})

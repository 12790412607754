import moment from 'moment'
import * as actionTypes from './actionTypes'

const initialState = {
  brand: undefined,
  expMonth: undefined,
  expYear: undefined,
  last4: undefined,
  lastLoaded: undefined,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_METHOD_REQUEST:
    case actionTypes.UPDATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GET_PAYMENT_METHOD_SUCCESS:
    case actionTypes.UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        brand: action.brand,
        expMonth: action.expMonth,
        expYear: action.expYear,
        last4: action.last4,
        lastLoaded: moment().valueOf(),
        loading: false,
      }
    case actionTypes.GET_PAYMENT_METHOD_ERROR:
    case actionTypes.UPDATE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

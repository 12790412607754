import { call, put, takeLatest, select, spawn } from 'redux-saga/effects'

import { authenticatedRequest } from 'shared/utils/RequestUtils'
import { envConfig } from 'shared/config'
import * as database from 'shared/firebase/database'
import { selectAuthUid, selectAuthEmail } from 'shared/modules/Auth/selectors'
import {
  selectUserFirstName,
  selectUserLastName,
} from 'shared/modules/User/selectors'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import { LIVE_SIGNATURE_URL, REGISTER_USER_URL } from './constants'
import { transformLiveSchedule, transformLiveScheduleItem } from './utils'

function* getLiveSignature({ meetingNumber, onSuccess, onError }) {
  try {
    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${LIVE_SIGNATURE_URL}${meetingNumber}`,
    )
    if (onSuccess) onSuccess(response)
  } catch (error) {
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* getLiveSchedule() {
  try {
    const response = yield call(database.getLiveSchedule)
    yield put(actions.getLiveScheduleSuccess(transformLiveSchedule(response)))
  } catch (error) {
    yield put(actions.getLiveScheduleError(error))
    yield spawn(throwSentryError, error)
  }
}

function* getLiveScheduleItem({ id, onSuccess, onError }) {
  try {
    const response = yield call(database.getLiveScheduleItem, id)
    yield put(
      actions.getLiveScheduleItemSuccess(
        transformLiveScheduleItem(response, id),
      ),
    )
    if (onSuccess) onSuccess()
  } catch (error) {
    if (onError) onError()
    yield spawn(throwSentryError, error)
  }
}

function* registerUserForLiveEvent({
  meetingNumber,
  liveScheduleId,
  onSuccess,
  onError,
}) {
  try {
    const uid = yield select(selectAuthUid)
    const email = yield select(selectAuthEmail)
    const firstName = yield select(selectUserFirstName)
    const lastName = yield select(selectUserLastName)
    const options = {
      method: 'POST',
      body: JSON.stringify({ email, firstName, lastName }),
    }

    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${REGISTER_USER_URL}${meetingNumber}`,
      options,
    )
    if (response.meeting && response.meeting.join_url) {
      const joinUrl = response.meeting.join_url
      yield call(
        database.addUserLiveScheduleRegistration,
        uid,
        liveScheduleId,
        joinUrl,
        meetingNumber,
      )
      if (onSuccess) onSuccess()
      yield put(
        actions.registerUserForLiveEventSuccess(
          meetingNumber,
          liveScheduleId,
          joinUrl,
        ),
      )
    } else {
      throw Error('Something went wrong')
    }
  } catch (error) {
    if (onError) onError()
    yield spawn(throwSentryError, error)
  }
}

export default [
  takeLatest(actionTypes.GET_LIVE_SIGNATURE_REQUEST, getLiveSignature),
  takeLatest(actionTypes.GET_LIVE_SCHEDULE_REQUEST, getLiveSchedule),
  takeLatest(
    actionTypes.REGISTER_USER_FOR_LIVE_EVENT_REQUEST,
    registerUserForLiveEvent,
  ),
  takeLatest(actionTypes.GET_LIVE_SCHEDULE_ITEM_REQUEST, getLiveScheduleItem),
]

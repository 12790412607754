import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.ResetPasswordPage.title',
    defaultMessage: 'Reset Password',
  },
  description: {
    id: 'web.Pages.ResetPasswordPage.description',
    defaultMessage: 'Enter your email to reset your password',
  },
  bodyTitle: {
    id: 'web.Pages.ResetPasswordPage.bodyTitle',
    defaultMessage: 'Reset your Password',
  },
  backToSignIn: {
    id: 'web.Pages.ResetPasswordPage.backToSignIn',
    defaultMessage: 'Back to Sign in',
  },
})

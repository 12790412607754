import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { colors } from 'web/theme'
import { ButtonSecondary } from 'web/components'
import { cancelSubscriptionAtPeriodEndRequest } from 'shared/modules/Subscription/actions'
import { selectSubscriptionCancelAtPeriodEnd } from 'shared/modules/Subscription/selectors'

const CancelSubscriptionButton = () => {
  const dispatch = useDispatch()
  const cancelAtPeriodEnd = useSelector(state =>
    selectSubscriptionCancelAtPeriodEnd(state),
  )

  const handleClick = () => {
    dispatch(cancelSubscriptionAtPeriodEndRequest(!cancelAtPeriodEnd))
  }

  return (
    <ButtonSecondary
      onClick={handleClick}
      style={{ ...(!cancelAtPeriodEnd ? styles.cancelButton : null) }}
    >
      {cancelAtPeriodEnd ? 'Reactivate Subscription' : 'Cancel Subscription'}
    </ButtonSecondary>
  )
}

const styles = {
  cancelButton: {
    color: colors.textError,
  },
}

export default CancelSubscriptionButton

import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

const InputGroup = ({ Component, validate, ...rest }) => (
  <Field component={Component} validate={validate} {...rest} />
)

InputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  Component: PropTypes.func.isRequired,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
}

export default InputGroup

import { createSelector } from 'reselect'
import get from 'lodash/get'
import moment from 'moment'

import { selectWorkoutsById } from 'shared/modules/Workouts/selectors'

import { NAME } from './constants'

const selectWeeklyPlanState = state => state[NAME]

const selectWeekPlan = createSelector(
  selectWeeklyPlanState,
  weeklyPlan => weeklyPlan,
)

export const selectWeeklyPlanLoading = createSelector(
  selectWeekPlan,
  weeklyPlan => get(weeklyPlan, 'loading'),
)

export const selectWeeklyPlanError = createSelector(
  selectWeekPlan,
  weeklyPlan => get(weeklyPlan, 'error'),
)

export const selectWeeklyPlanByDay = createSelector(
  selectWeekPlan,
  weeklyPlan => get(weeklyPlan, 'weeklyPlanByDay'),
)

export const selectWeeklyPlanByDayWorkouts = createSelector(
  selectWeeklyPlanByDay,
  selectWorkoutsById,
  (weeklyPlanByDay, workoutsById) => {
    if (!weeklyPlanByDay || !workoutsById) return null
    return Object.keys(weeklyPlanByDay).reduce((acc, day) => {
      const workout = get(workoutsById, weeklyPlanByDay[day])
      if (workout) {
        return {
          ...acc,
          [day]: workout,
        }
      }
      return acc
    }, {})
  },
)

export const selectWeeklyPlanTodaysWorkout = createSelector(
  selectWeeklyPlanByDayWorkouts,
  workouts => {
    if (!workouts) return null
    const today = moment()
      .format('dddd')
      .toLowerCase()
    return workouts[today]
  },
)

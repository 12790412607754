import React from 'react'
import { useSelector } from 'react-redux'

import { selectWorkoutCategoriesByIdArray } from 'shared/modules/WorkoutCategories/selectors'
import { WorkoutsContainer } from 'shared/modules/Workouts/components'
import { WeeklyMantraContainer } from 'shared/modules/WeeklyMantra/components'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { HelmetLocalised, CircularProgress, Grid } from 'web/components'
import {
  LatestWorkout,
  LatestFavouriteWorkout,
  TodaysWorkout,
} from 'web/modules/Workouts/components'
import { WelcomeMessage } from 'web/modules/User/components'
import { WeeklyMantra } from 'web/modules/WeeklyMantra/components'

import messages from './messages'

const DashboardPage = () => {
  const workoutCategories = useSelector(state =>
    selectWorkoutCategoriesByIdArray(state),
  )

  return (
    <>
      <HelmetLocalised
        title={messages.title}
        description={messages.description}
      />
      <PageWrapper>
        <Grid container>
          <WelcomeMessage />
          <WeeklyMantraContainer>
            {({ mantra, variant }) =>
              mantra ? (
                <Grid item xs={12}>
                  <WeeklyMantra mantra={mantra} variant={variant} />
                </Grid>
              ) : null
            }
          </WeeklyMantraContainer>
          <Grid item xs={12}>
            <WorkoutsContainer>
              {({ loading, error }) => {
                if (loading || error || !workoutCategories)
                  return <CircularProgress />
                return (
                  <>
                    <LatestWorkout />
                    <LatestFavouriteWorkout />
                    <TodaysWorkout />
                  </>
                )
              }}
            </WorkoutsContainer>
          </Grid>
        </Grid>
      </PageWrapper>
    </>
  )
}

export default DashboardPage

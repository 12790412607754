import * as actionTypes from './actionTypes'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_UI_VALUE:
      return {
        [action.key]: action.value,
      }
    default:
      return state
  }
}

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.SignInPage.title',
    defaultMessage: 'Sign in',
  },
  description: {
    id: 'web.Pages.SignInPage.description',
    defaultMessage: 'Sign in now to access premium content',
  },
  bodyTitle: {
    id: 'web.Pages.SignInPage.bodyTitle',
    defaultMessage: 'Welcome back.',
  },
  signUpCallToAction: {
    id: 'web.Pages.SignInPage.signUpCallToAction',
    defaultMessage: 'Are you new? {SIGN_UP_LINK}',
  },
  signUpCallToActionLink: {
    id: 'web.Pages.SignInPage.signUpCallToActionLink',
    defaultMessage: 'Start your 7 day Free Trial today',
  },
  forgotPassword: {
    id: 'web.Pages.SignInPage.forgotPassword',
    defaultMessage: 'Forgot your password?',
  },
})

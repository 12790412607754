import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import { formatLongDateTime } from 'shared/utils/DateTimeUtils'

import routes from 'web/routing/routes'
import { CardItemSummary, ButtonSecondary } from 'web/components'
import ButtonRegisterOrJoin from 'web/modules/LiveSchedule/components/ButtonRegisterOrJoin'

const CardLiveScheduleItemSummary = ({
  id,
  meetingNumber,
  name,
  image,
  summary,
  startDateTime,
  endDateTime,
  isPast,
}) => {
  const history = useHistory()

  return (
    <CardItemSummary
      key={id}
      title={name}
      image={image}
      cardActions={
        <>
          <ButtonSecondary
            onClick={() =>
              history.push(`${routes.LiveScheduleItemPage.routeName}${id}`)
            }
          >
            View Class
          </ButtonSecondary>
          {!isPast && (
            <ButtonRegisterOrJoin
              meetingNumber={meetingNumber}
              liveScheduleId={id}
            />
          )}
        </>
      }
    >
      <Typography paragraph variant="body2" color="textSecondary">
        {summary.length > 100 ? `${summary.substr(0, 100 - 1)}...` : summary}
      </Typography>
      <Typography variant="body2">
        {isPast
          ? `Ended ${moment(endDateTime).fromNow()}`
          : `Starts ${moment(startDateTime).fromNow()}`}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {formatLongDateTime(startDateTime)}
      </Typography>
    </CardItemSummary>
  )
}

CardLiveScheduleItemSummary.propTypes = {
  id: PropTypes.string.isRequired,
  meetingNumber: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  summary: PropTypes.string.isRequired,
  startDateTime: PropTypes.number.isRequired,
  endDateTime: PropTypes.number.isRequired,
  isPast: PropTypes.bool,
}

export default CardLiveScheduleItemSummary

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'

import { validateRequired, composeValidators } from 'shared/utils/FormUtils'
import { InputGroup } from 'shared/components'
import { updateUserRequest } from 'shared/modules/User/actions'

import {
  Grid,
  FormInputText,
  ButtonPrimary,
  ButtonSecondary,
  FormErrorDisplay,
} from 'web/components'

import messages from './messages'

const UpdateUserDetailsForm = ({
  initialValues,
  onSuccess,
  onCancel,
  loading,
}) => {
  const [displayError, setDisplayError] = useState()
  const dispatch = useDispatch()

  const onError = error => setDisplayError(error)

  return (
    <Form
      onSubmit={({ firstName, lastName }) => {
        setDisplayError(false)
        dispatch(updateUserRequest({ firstName, lastName }, onSuccess, onError))
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <Grid
          container
          component="form"
          justify="center"
          onSubmit={handleSubmit}
          noValidate
        >
          {displayError && (
            <Grid item xs={12}>
              <FormErrorDisplay error={displayError} />
            </Grid>
          )}
          <Grid item xs={12}>
            <InputGroup
              name="firstName"
              Component={FormInputText}
              validate={composeValidators(validateRequired)}
              label={<FormattedMessage {...messages.firstNameLabel} />}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <InputGroup
              name="lastName"
              Component={FormInputText}
              label={<FormattedMessage {...messages.lastNameLabel} />}
              validate={validateRequired}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonPrimary
              type="submit"
              onClick={handleSubmit}
              loading={loading}
            >
              <FormattedMessage {...messages.submitButton} />
            </ButtonPrimary>
            <ButtonSecondary onClick={onCancel}>
              <FormattedMessage {...messages.cancelButton} />
            </ButtonSecondary>
          </Grid>
        </Grid>
      )}
    </Form>
  )
}

UpdateUserDetailsForm.propTypes = {
  initialValues: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
}

export default UpdateUserDetailsForm

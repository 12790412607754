import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'
import { generatePath, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { scheduleSubscriptionDowngradeRequest } from 'shared/modules/Subscription/actions'
import {
  selectSubscriptionAccess,
  selectSubscriptionPromotionCode,
} from 'shared/modules/Subscription/selectors'

import routes from 'web/routing/routes'
import { Grid, ButtonSecondary, Alert } from 'web/components'

import messages from './messages'

const SubscriptionCanDowngrade = ({ subscriptionScheduleId }) => {
  const [loading, setLoading] = useState()
  const [displaySuccess, setDisplaySuccess] = useState()
  const [displayError, setDisplayError] = useState()
  const [scheduleId] = useState(subscriptionScheduleId)

  const dispatch = useDispatch()

  const access = useSelector(state => selectSubscriptionAccess(state))

  const promotionCode = useSelector(state =>
    selectSubscriptionPromotionCode(state),
  )

  const handleScheduleDowngradeSuccess = () => {
    setLoading(false)
    setDisplaySuccess(true)
  }

  const handleScheduleDowngradeError = () => {
    setLoading(false)
    setDisplayError(true)
  }

  const handleScheduleDowngrade = () => {
    setLoading(true)
    dispatch(
      scheduleSubscriptionDowngradeRequest(
        handleScheduleDowngradeSuccess,
        handleScheduleDowngradeError,
      ),
    )
  }

  if (scheduleId)
    return (
      <>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h1" component="h1" align="center">
            <FormattedMessage {...messages.downgradeScheduledTitle} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Typography align="center" variant="h3" gutterBottom>
            <FormattedMessage {...messages.downgradeScheduledSubtitle} />
          </Typography>
        </Grid>
      </>
    )

  return access === 10 ? (
    <>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h1" component="h1" align="center">
          <FormattedMessage {...messages.downgradedTitle} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Typography align="center" variant="h3" gutterBottom>
          <FormattedMessage {...messages.downgradedSubtitle} />
        </Typography>
      </Grid>
    </>
  ) : (
    <>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h1" component="h1" align="center">
          <FormattedMessage {...messages.bodyTitle} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Typography align="center" variant="h3" gutterBottom>
          <FormattedMessage {...messages.subtitle} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Typography align="center" gutterBottom>
          <FormattedMessage {...messages.body} />
        </Typography>
        <Typography align="center" gutterBottom>
          <strong>
            <FormattedMessage {...messages.body2} />
          </strong>
        </Typography>
        <Typography align="center" variant="body2">
          <FormattedMessage {...messages.body3} />
        </Typography>
      </Grid>
      {promotionCode && (
        <Grid item>
          <Alert severity="warning">
            <FormattedMessage {...messages.promotionWarning} />
          </Alert>
        </Grid>
      )}
      {displaySuccess || displayError ? (
        <Grid item>
          {displaySuccess && (
            <Alert
              action={
                <Button
                  color="inherit"
                  component={Link}
                  to={generatePath(routes.AccountDetailsPage.routePath)}
                >
                  <FormattedMessage {...messages.successMessageContinue} />
                </Button>
              }
            >
              <FormattedMessage {...messages.successMessage} />
            </Alert>
          )}
          {displayError && (
            <Alert severity="error">
              <FormattedMessage {...messages.errorMessage} />
            </Alert>
          )}
        </Grid>
      ) : (
        <Grid item>
          <ButtonSecondary onClick={handleScheduleDowngrade} loading={loading}>
            <FormattedMessage {...messages.downgradeSubscriptionButton} />
          </ButtonSecondary>
        </Grid>
      )}
    </>
  )
}

SubscriptionCanDowngrade.propTypes = {
  subscriptionScheduleId: PropTypes.string,
}

export default SubscriptionCanDowngrade

import { call, put, takeLatest, select, spawn } from 'redux-saga/effects'

import { authenticatedRequest } from 'shared/utils/RequestUtils'
import { envConfig } from 'shared/config'
import * as database from 'shared/firebase/database'
import { selectAuthUid } from 'shared/modules/Auth/selectors'
import { selectUserStripeCustomerId } from 'shared/modules/User/selectors'
import { throwSentryError } from 'shared/utils/ErrorUtils'
import { removePromotion } from 'shared/modules/Promotion/actions'
import { getPlans } from 'shared/modules/Plans/sagas'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import * as selectors from './selectors'
import {
  SUBSCRIPTION_STATUS,
  CREATE_SUBSCRIPTION_URL,
  RETRY_SUBSCRIPTION_URL,
  CANCEL_SUBSCRIPTION_AT_PERIOD_END_URL,
  ADD_SUBSCRIPTION_PROMOTION_URL,
  UPDATE_SUBSCRIPTION_PRICE_URL,
  PREVIEW_PRORATION_URL,
  SCHEDULE_DOWNGRADE_URL,
  CANCEL_DOWNGRADE_URL,
} from './constants'
import {
  transformSubscription,
  handlePaymentThatRequiresCustomerAction,
  handleRequiresPaymentMethod,
  transformStripeSubscription,
  getUpgradePricesFromPreview,
} from './utils'

function* createSubscription({
  stripe,
  customerId,
  paymentMethodId,
  priceId,
  trialEnd,
  promotionId,
  onSuccess,
  onError,
}) {
  try {
    const uid = yield select(selectAuthUid)
    if (!uid) throw Error('No UID defined')
    const options = {
      method: 'POST',
      body: JSON.stringify({
        uid,
        customerId,
        paymentMethodId,
        priceId,
        promotionId,
        trialEnd,
      }),
    }

    const subscriptionResponse = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${CREATE_SUBSCRIPTION_URL}`,
      options,
      // @NOTE allow 402 (card declined) responses so we can normalise the response and error
      { addResponseCodes: [402] },
    )

    if (subscriptionResponse.error) {
      const errorPayload = {
        error: subscriptionResponse.error,
        priceId,
      }
      throw errorPayload
    }

    const requiresCustomerAction = yield call(
      handlePaymentThatRequiresCustomerAction,
      {
        stripe,
        paymentMethodId,
        priceId,
        subscription: subscriptionResponse.subscription,
      },
    )

    const requiresPaymentMethod = yield call(
      handleRequiresPaymentMethod,
      requiresCustomerAction,
    )

    if (requiresPaymentMethod.error) {
      const errorPayload = {
        error: requiresPaymentMethod.error,
        id: requiresPaymentMethod.id,
        latestInvoiceId: requiresPaymentMethod.latestInvoiceId,
        latestInvoicePaymentIntentStatus:
          requiresPaymentMethod.latestInvoicePaymentIntentStatus,
        status: 'incomplete', // @TODO might need updating...
        priceId,
      }
      throw errorPayload
    }

    if (
      requiresPaymentMethod.subscription.status ===
        SUBSCRIPTION_STATUS.active ||
      requiresPaymentMethod.subscription.status === SUBSCRIPTION_STATUS.trialing
    ) {
      yield put(
        actions.createSubscriptionSuccess({
          id: requiresPaymentMethod?.subscription?.id,
          latestInvoiceId: requiresPaymentMethod.subscription.latest_invoice.id,
          ...(requiresPaymentMethod.subscription.status ===
          SUBSCRIPTION_STATUS.trialing
            ? {
                latestInvoicePaymentIntentStatus: 'succeeded', // Auto set to success for trialing users as NOT returned in the subscription response
              }
            : {
                latestInvoicePaymentIntentStatus:
                  requiresPaymentMethod.subscription.latest_invoice
                    .payment_intent.status,
              }),
          status: requiresPaymentMethod.subscription.status,
          priceId,
          currentPeriodEnd:
            requiresPaymentMethod?.subscription?.current_period_end,
          currentPeriodStart:
            requiresPaymentMethod?.subscription?.current_period_start,
          ...(requiresPaymentMethod?.subscription?.discount
            ? {
                promotion: {
                  promotionCode:
                    requiresPaymentMethod?.subscription?.discount
                      .promotion_code,
                  start: requiresPaymentMethod?.subscription?.discount.start,
                  end: requiresPaymentMethod?.subscription?.discount.end,
                  name:
                    requiresPaymentMethod?.subscription?.discount.coupon.name,
                  description:
                    requiresPaymentMethod?.subscription?.discount.coupon
                      .metadata.description || '',
                  valid:
                    requiresPaymentMethod?.subscription?.discount.coupon.valid,
                },
              }
            : null),
        }),
      )
    }

    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.createSubscriptionError(error))
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* retryInvoice({
  stripe,
  customerId,
  paymentMethodId,
  invoiceId,
  priceId,
  onSuccess,
  onError,
}) {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify({ customerId, paymentMethodId, invoiceId }),
    }

    const retryResponse = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${RETRY_SUBSCRIPTION_URL}`,
      options,
      // @NOTE allow 402 (card declined) responses so we can normalise the response and error
      { addResponseCodes: [402] },
    )

    if (retryResponse.error) {
      const errorPayload = {
        error: retryResponse.error,
        priceId,
      }
      throw errorPayload
    }

    const requiresCustomerAction = yield call(
      handlePaymentThatRequiresCustomerAction,
      {
        stripe,
        invoice: retryResponse.invoice,
        paymentMethodId,
        priceId,
        isRetry: true,
      },
    )

    if (
      requiresCustomerAction.subscription.status ===
        SUBSCRIPTION_STATUS.active ||
      requiresCustomerAction.subscription.status ===
        SUBSCRIPTION_STATUS.trialing
    ) {
      yield put(
        actions.retryInvoiceSuccess({
          latestInvoiceId: requiresCustomerAction.invoiceId,
          latestInvoicePaymentIntentStatus: 'succeeded',
          status: requiresCustomerAction.subscription.status,
          priceId,
        }),
      )
    }

    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.retryInvoiceError(error))
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* getSubscription() {
  try {
    const stripeCustomerId = yield select(selectUserStripeCustomerId)
    if (!stripeCustomerId) throw Error('No customer found.')
    const response = yield call(database.getSubscription, stripeCustomerId)
    // Plans are required for subscriptions to work so we should make sure they are up to date.
    // Call the saga directly to stop "loading" issues.
    yield call(getPlans)
    yield put(actions.getSubscriptionSuccess(transformSubscription(response)))
  } catch (error) {
    yield put(actions.getSubscriptionError(error))
    yield spawn(throwSentryError, error)
  }
}

// @NOTE Used to update the value to cancel or not.
function* cancelSubscriptionAtPeriodEnd({
  cancelAtPeriodEnd,
  onSuccess,
  onError,
}) {
  try {
    const subscriptionId = yield select(selectors.selectSubscriptionId)
    if (!subscriptionId) throw Error('No subscription found.')

    // release the subscription schedule/cancel downgrade first
    const scheduleId = yield select(selectors.selectSubscriptionScheduleId)
    if (scheduleId) {
      yield call(cancelSubscriptionDowngrade)
    }

    const options = {
      method: 'POST',
      body: JSON.stringify({ subscriptionId, cancelAtPeriodEnd }),
    }

    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${CANCEL_SUBSCRIPTION_AT_PERIOD_END_URL}`,
      options,
    )

    if (response.error || !response.subscription) throw response.error

    yield put(
      actions.cancelSubscriptionAtPeriodEndSuccess(
        transformStripeSubscription(response.subscription),
      ),
    )
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.cancelSubscriptionAtPeriodEndError(error))
    if (onError) onError()
    yield spawn(throwSentryError, error)
  }
}

function* addSubscriptionPromotion({ promotionId, onError }) {
  try {
    const subscriptionId = yield select(selectors.selectSubscriptionId)
    if (!subscriptionId) throw Error('No subscription found.')
    const options = {
      method: 'POST',
      body: JSON.stringify({ subscriptionId, promotionId }),
    }

    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${ADD_SUBSCRIPTION_PROMOTION_URL}`,
      options,
    )

    if (response.error) throw response.error

    yield put(
      actions.addSubscriptionPromotionSuccess(
        transformStripeSubscription(response.subscription),
      ),
    )
    yield put(removePromotion())
  } catch (error) {
    yield put(actions.addSubscriptionPromotionError(error))
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* updateSubscriptionPrice({ priceId, onSuccess, onError }) {
  try {
    const subscriptionId = yield select(selectors.selectSubscriptionId)
    if (!subscriptionId) throw Error('No subscription found.')
    // select the subscription status - add a trialEnd if the status is trialing.
    const subscriptionStatus = yield select(selectors.selectSubscriptionStatus)
    const currentPeriodEnd = yield select(
      selectors.selectSubscriptionCurrentPeriodEnd,
    )
    const options = {
      method: 'POST',
      body: JSON.stringify({
        subscriptionId,
        priceId,
        ...(subscriptionStatus === SUBSCRIPTION_STATUS.trialing
          ? { trialEnd: currentPeriodEnd }
          : null),
      }),
    }

    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${UPDATE_SUBSCRIPTION_PRICE_URL}`,
      options,
    )

    if (response.error) throw response.error

    yield put(
      actions.updateSubscriptionPriceSuccess(
        transformStripeSubscription(response.subscription),
      ),
    )

    if (onSuccess) onSuccess(response.subscription)
  } catch (error) {
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* previewSubscriptionProration({
  requestedPriceId,
  onSuccess,
  onError,
}) {
  try {
    const subscriptionId = yield select(selectors.selectSubscriptionId)
    if (!subscriptionId) throw Error('No subscription found.')
    const options = {
      method: 'POST',
      body: JSON.stringify({ subscriptionId, requestedPriceId }),
    }

    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${PREVIEW_PRORATION_URL}`,
      options,
    )

    if (response.error || !response?.invoice) throw response.error
    onSuccess(getUpgradePricesFromPreview(response.invoice))
  } catch (error) {
    onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* scheduleSubscriptionDowngrade({ onSuccess, onError } = {}) {
  try {
    const subscriptionId = yield select(selectors.selectSubscriptionId)
    if (!subscriptionId) throw Error('No customer ID found.')
    // select the subscription status - add a trialEnd if the status is trialing.
    const subscriptionStatus = yield select(selectors.selectSubscriptionStatus)
    const currentPeriodEnd = yield select(
      selectors.selectSubscriptionCurrentPeriodEnd,
    )
    const currentPeriodStart = yield select(
      selectors.selectSubscriptionCurrentPeriodStart,
    )
    const currentPriceId = yield select(selectors.selectSubscriptionPriceId)
    const downgradePriceId = yield select(
      selectors.selectSubscriptionDowngradePriceId,
    )

    const options = {
      method: 'POST',
      body: JSON.stringify({
        subscriptionId,
        currentPeriodStart,
        currentPeriodEnd,
        currentPriceId,
        downgradePriceId,
        ...(subscriptionStatus === SUBSCRIPTION_STATUS.trialing
          ? { trialEnd: currentPeriodEnd }
          : null),
      }),
    }

    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${SCHEDULE_DOWNGRADE_URL}`,
      options,
    )

    if (response.error || !response?.schedule) throw response.error

    yield put(
      actions.scheduleSubscriptionDowngradeSuccess(response.schedule.id),
    )

    onSuccess()
  } catch (error) {
    onError(error)
    yield spawn(throwSentryError, error)
  }
}

function* cancelSubscriptionDowngrade({ onSuccess, onError } = {}) {
  try {
    const scheduleId = yield select(selectors.selectSubscriptionScheduleId)
    const options = {
      method: 'POST',
      body: JSON.stringify({ scheduleId }),
    }

    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${CANCEL_DOWNGRADE_URL}`,
      options,
    )

    if (response.error) throw response.error

    yield put(actions.cancelSubscriptionDowngradeSuccess())
    if (onSuccess) onSuccess()
  } catch (error) {
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

export default [
  takeLatest(actionTypes.CREATE_SUBSCRIPTION_REQUEST, createSubscription),
  takeLatest(
    actionTypes.CANCEL_SUBSCRIPTION_AT_PERIOD_END_REQUEST,
    cancelSubscriptionAtPeriodEnd,
  ),
  takeLatest(actionTypes.GET_SUBSCRIPTION_REQUEST, getSubscription),
  takeLatest(actionTypes.RETRY_INVOICE_REQUEST, retryInvoice),
  takeLatest(
    actionTypes.ADD_SUBSCRIPTION_PROMOTION_REQUEST,
    addSubscriptionPromotion,
  ),
  takeLatest(
    actionTypes.UPDATE_SUBSCRIPTION_PRICE_REQUEST,
    updateSubscriptionPrice,
  ),
  takeLatest(
    actionTypes.PREVIEW_SUBSCRIPTION_PRORATION_REQUEST,
    previewSubscriptionProration,
  ),
  takeLatest(
    actionTypes.SCHEDULE_SUBSCRIPTION_DOWNGRADE_REQUEST,
    scheduleSubscriptionDowngrade,
  ),
  takeLatest(
    actionTypes.CANCEL_SUBSCRIPTION_DOWNGRADE_REQUEST,
    cancelSubscriptionDowngrade,
  ),
]

import React from 'react'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'

import { addSubscriptionPriceId } from 'shared/modules/Subscription/actions'
import { selectUserFreeTrialUsed } from 'shared/modules/User/selectors'

import routes from 'web/routing/routes'
import {
  HelmetLocalised,
  FullPageBgFade,
  Image,
  Grid,
  Alert,
} from 'web/components'
import { PlanSelect } from 'web/modules/Plans/components'
import { RegisteringAs } from 'web/modules/Subscription/components'
import logo from 'web/images/evolve-logo.png'

import messages from './messages'

const PricePlanPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const freeTrialUsed = useSelector(state => selectUserFreeTrialUsed(state))

  const handlePlanSelect = priceId => {
    dispatch(addSubscriptionPriceId(priceId))
    history.push(routes.RegistrationFreeTrialBillingDetailsPage.routePath)
  }

  if (freeTrialUsed) {
    return (
      <Redirect
        to={{
          pathname: routes.RegistrationPricePlanPage.routePath,
          state: { message: 'freeTrialUsed' },
        }}
      />
    )
  }

  return (
    <>
      <HelmetLocalised
        title={messages.title}
        description={messages.description}
      />
      <FullPageBgFade type="second">
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={0}
          style={styles.wrapper}
        >
          <Grid item xs={11} md={6} lg={4}>
            <Grid container justify="center">
              <Grid item xs={5} sm={3} md={3}>
                <Image src={logo} alt="Evolve" />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="h1"
                      align="center"
                    >
                      <FormattedMessage {...messages.bodyTitle} />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      You will not be charged until your free trial period ends
                      in <strong>7 days</strong>. You can cancel anytime up
                      until your free trial period ends without being charged.
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <PlanSelect onSelect={handlePlanSelect} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <RegisteringAs />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FullPageBgFade>
    </>
  )
}

const styles = {
  wrapper: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  image: {
    mixBlendMode: 'luminosity',
    width: '100%',
  },
  subText: {
    marginTop: '15px',
  },
  footer: {
    marginBottom: '40px',
  },
}

export default PricePlanPage

import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { getUserRequest } from 'shared/modules/User/actions'
import {
  selectUserFirstName,
  selectUserLastName,
  selectUserLoading,
  selectUserError,
} from 'shared/modules/User/selectors'
import { selectAuthEmail } from 'shared/modules/Auth/selectors'

const UserContainer = ({ children, render }) => {
  const dispatch = useDispatch()
  const email = useSelector(state => selectAuthEmail(state))
  const firstName = useSelector(state => selectUserFirstName(state))
  const lastName = useSelector(state => selectUserLastName(state))
  const loading = useSelector(state => selectUserLoading(state))
  const error = useSelector(state => selectUserError(state))

  const renderProps = children || render

  const getUser = useCallback(() => {
    dispatch(getUserRequest())
  }, [dispatch])

  return renderProps({
    getUser,
    email,
    firstName,
    lastName,
    loading,
    error,
  })
}

UserContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
}

export default UserContainer

import { createSelector } from 'reselect'
import get from 'lodash/get'
import { NAME } from './constants'

const selectUserState = state => state[NAME]
const getUserLiveScheduleItemByLiveScheduleId = (state, liveScheduleId) =>
  get(state[NAME], `liveScheduleById[${liveScheduleId}]`)
const getUserWorkoutFavouriteByWorkoutId = (state, workoutId) =>
  get(state[NAME], `workoutFavouritesById[${workoutId}]`)

const selectUser = createSelector(selectUserState, userState => userState)

export const selectUserLoading = createSelector(
  selectUser,
  user => user.loading,
)

export const selectUserError = createSelector(selectUser, user => user.error)

export const selectUserFirstName = createSelector(
  selectUser,
  user => user.firstName,
)

export const selectUserLastName = createSelector(
  selectUser,
  user => user.lastName,
)

export const selectUserStripeCustomerId = createSelector(
  selectUser,
  user => user.stripeCustomerId,
)

export const selectUserLiveScheduleById = createSelector(selectUser, user =>
  get(user, 'liveScheduleById'),
)

export const selectUserLiveScheduleItemByLiveScheduleId = createSelector(
  getUserLiveScheduleItemByLiveScheduleId,
  userLiveScheduleById => userLiveScheduleById,
)

export const selectUserLiveScheduleItemByLiveScheduleIdJoinUrl = createSelector(
  selectUserLiveScheduleItemByLiveScheduleId,
  userLiveScheduleById =>
    userLiveScheduleById ? userLiveScheduleById.joinUrl : undefined,
)

export const selectUserWorkoutFavouritesById = createSelector(
  selectUser,
  user => get(user, 'workoutFavouritesById'),
)

export const selectUserWorkoutFavouriteByWorkoutId = createSelector(
  getUserWorkoutFavouriteByWorkoutId,
  userWorkoutFavourite => userWorkoutFavourite,
)

export const selectUserWorkoutFavouriteByWorkoutIdTimestamp = createSelector(
  selectUserWorkoutFavouriteByWorkoutId,
  userWorkoutFavourite => get(userWorkoutFavourite, 'timestamp'),
)

export const selectUserFreeTrialUsed = createSelector(selectUser, user =>
  get(user, 'freeTrialUsed'),
)

import React, { useState } from 'react'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'

import { selectSubscriptionCurrentPeriodEnd } from 'shared/modules/Subscription/selectors'
import { cancelSubscriptionDowngradeRequest } from 'shared/modules/Subscription/actions'

import { Alert } from 'web/components'

import messages from './messages'

const DowngradeScheduledAlert = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState()
  const currentPeriodEnd = useSelector(state =>
    selectSubscriptionCurrentPeriodEnd(state),
  )

  const handleClick = () => {
    setLoading(true)
    dispatch(cancelSubscriptionDowngradeRequest())
  }

  return (
    <Alert
      severity="warning"
      action={
        <Button disabled={loading} onClick={handleClick}>
          <FormattedMessage {...messages.cancelDowngradeButton} />
        </Button>
      }
    >
      <FormattedMessage
        {...messages.downgradeScheduled}
        values={{
          END: <strong>{moment.unix(currentPeriodEnd).fromNow()}</strong>,
        }}
      />
    </Alert>
  )
}

export default DowngradeScheduledAlert

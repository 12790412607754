import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

import { CircularProgress, Grid, Alert } from 'web/components'
import LiveScheduleList from 'web/modules/LiveSchedule/components/LiveScheduleList'
import CardLiveScheduleItemNowSummary from 'web/modules/LiveSchedule/components/CardLiveScheduleItemNowSummary'

const LiveSchedule = ({
  pastLiveScheduleItems,
  futureLiveScheduleItems,
  activeLiveScheduleItems,
  loading,
  error,
}) => {
  if (error)
    return (
      <Alert severity="error">
        Oops, something went wrong trying to fetch the Live Schedule - please
        try refreshing the page.
      </Alert>
    )

  if (
    loading &&
    !pastLiveScheduleItems &&
    !futureLiveScheduleItems &&
    !activeLiveScheduleItems
  )
    return <CircularProgress />

  return (
    <Grid container>
      {activeLiveScheduleItems && activeLiveScheduleItems.length ? (
        <Grid item xs={12}>
          <CardLiveScheduleItemNowSummary {...activeLiveScheduleItems[0]} />
        </Grid>
      ) : null}
      {futureLiveScheduleItems && futureLiveScheduleItems.length ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h3">Upcoming Classes</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Register now to take part
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LiveScheduleList items={futureLiveScheduleItems} />
          </Grid>
        </>
      ) : null}
      {pastLiveScheduleItems && pastLiveScheduleItems.length ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h3">Past Classes</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Catch up with last weeks live classes by watching the recording
              back
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LiveScheduleList items={pastLiveScheduleItems} isPast />
          </Grid>
        </>
      ) : null}
    </Grid>
  )
}

LiveSchedule.propTypes = {
  pastLiveScheduleItems: PropTypes.array,
  futureLiveScheduleItems: PropTypes.array,
  activeLiveScheduleItems: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

export default LiveSchedule

import React from 'react'
import PropTypes from 'prop-types'

const Image = ({ src, alt, width, ...rest }) => {
  const styles = { width }
  return <img style={styles} src={src} alt={alt} {...rest} />
}

Image.defaultProps = {
  width: '100%',
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Image

import React from 'react'
import ReactDom from 'react-dom'

import { envConfig, appConfig } from 'shared/config'
import { Sentry } from 'shared/utils/ErrorUtils'
import { Integrations } from '@sentry/tracing'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import HybridApp from './HybridApp.js'

Sentry.init({
  dsn: appConfig.sentryWebDsn,
  environment: envConfig.ENV,
  release: process.env.REACT_APP_VERSION,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const stripePromise = loadStripe(envConfig.STRIPE_PUBLISHABLE_KEY)

ReactDom.render(
  <Elements
    stripe={stripePromise}
    options={{
      fonts: [
        {
          cssSrc:
            'https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;0,500;1,400;1,500&display=swap',
        },
      ],
    }}
  >
    <HybridApp />
  </Elements>,
  document.getElementById('root'),
)

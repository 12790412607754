import React from 'react'
import Typography from '@material-ui/core/Typography'

import { WorkoutsContainer } from 'shared/modules/Workouts/components'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { HelmetLocalised, Grid } from 'web/components'
import { Workouts } from 'web/modules/Workouts/components'

import messages from './messages'

const WorkoutsPage = () => (
  <>
    <HelmetLocalised
      title={messages.title}
      description={messages.description}
    />
    <PageWrapper>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">All Workouts</Typography>
        </Grid>
        <Grid item xs={12}>
          <WorkoutsContainer>
            {({ workouts, loading, error }) => (
              <Workouts workouts={workouts} loading={loading} error={error} />
            )}
          </WorkoutsContainer>
        </Grid>
      </Grid>
    </PageWrapper>
  </>
)

export default WorkoutsPage

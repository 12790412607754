import { call, put, takeLatest, spawn } from 'redux-saga/effects'

import { envConfig } from 'shared/config'
import { authenticatedRequest } from 'shared/utils/RequestUtils'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import { GET_PROMOTION_URL } from './constants'
import { transformPromotion } from './utils'

function* getPromotion({ code, onSuccess, onError }) {
  try {
    const response = yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${GET_PROMOTION_URL}${code}`,
    )
    const promotion = transformPromotion(response)
    if (!promotion || (promotion && !promotion.active))
      throw Error('promotion-not-found')
    yield put(actions.addPromotion(promotion))
    if (onSuccess) onSuccess(promotion)
  } catch (error) {
    if (onError) onError(error)
    yield spawn(throwSentryError, error)
  }
}

export default [takeLatest(actionTypes.GET_PROMOTION_REQUEST, getPromotion)]

export const transformWeeklyMantra = (response, id) => {
  if (!response)
    return {
      id,
    }

  return {
    id,
    ...response.data(),
  }
}

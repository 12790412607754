import { persistCombineReducers, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { NAME as LANGUAGE_PROVIDER_NAME } from 'shared/modules/LanguageProvider/constants'
import { NAME as AUTH_NAME } from 'shared/modules/Auth/constants'
import { NAME as SUBSCRIPTION_NAME } from 'shared/modules/Subscription/constants'
import { NAME as PLANS_NAME } from 'shared/modules/Plans/constants'
import { NAME as PAYMENT_METHOD_NAME } from 'shared/modules/PaymentMethod/constants'
import { NAME as USER_NAME } from 'shared/modules/User/constants'
import { NAME as WORKOUTS_NAME } from 'shared/modules/Workouts/constants'
import { NAME as WORKOUT_CATEGORIES_NAME } from 'shared/modules/WorkoutCategories/constants'
import { NAME as EQUIPMENT_NAME } from 'shared/modules/Equipment/constants'
import { NAME as MUSCLE_GROUPS_NAME } from 'shared/modules/MuscleGroups/constants'
import { NAME as LIVE_SCHEDULE_NAME } from 'shared/modules/LiveSchedule/constants'
import { NAME as UI_NAME } from 'shared/modules/UI/constants'
import { NAME as WEEKLY_PLAN_NAME } from 'shared/modules/WeeklyPlan/constants'
import { NAME as PROMOTION_NAME } from 'shared/modules/Promotion/constants'
import { NAME as INVOICES_NAME } from 'shared/modules/Invoices/constants'
import { NAME as WEEKLY_MANTRA_NAME } from 'shared/modules/WeeklyMantra/constants'

import languageProviderReducer from 'shared/modules/LanguageProvider/reducer'
import authReducer from 'shared/modules/Auth/reducer'
import subscriptionReducer from 'shared/modules/Subscription/reducer'
import plansReducer from 'shared/modules/Plans/reducer'
import paymentMethodReducer from 'shared/modules/PaymentMethod/reducer'
import userReducer from 'shared/modules/User/reducer'
import workoutsReducer from 'shared/modules/Workouts/reducer'
import workoutCategoriesReducer from 'shared/modules/WorkoutCategories/reducer'
import equipmentReducer from 'shared/modules/Equipment/reducer'
import muscleGroupsReducer from 'shared/modules/MuscleGroups/reducer'
import liveScheduleReducer from 'shared/modules/LiveSchedule/reducer'
import uiReducer from 'shared/modules/UI/reducer'
import weeklyPlanReducer from 'shared/modules/WeeklyPlan/reducer'
import promotionReducer from 'shared/modules/Promotion/reducer'
import invoicesReducer from 'shared/modules/Invoices/reducer'
import weeklyMantraReducer from 'shared/modules/WeeklyMantra/reducer'

import { SIGN_OUT_SUCCESS } from 'shared/modules/Auth/actionTypes'

import { reducers } from './reducers'

const migrations = {}

const persistConfig = {
  key: 'App',
  blacklist: ['Navigation', 'router'],
  storage,
  version: 1,
  migrate: createMigrate(migrations, { debug: false }),
}

export const sharedReducers = {
  [LANGUAGE_PROVIDER_NAME]: languageProviderReducer,
  [AUTH_NAME]: authReducer,
  [SUBSCRIPTION_NAME]: subscriptionReducer,
  [PLANS_NAME]: plansReducer,
  [PAYMENT_METHOD_NAME]: paymentMethodReducer,
  [USER_NAME]: userReducer,
  [WORKOUTS_NAME]: workoutsReducer,
  [WORKOUT_CATEGORIES_NAME]: workoutCategoriesReducer,
  [EQUIPMENT_NAME]: equipmentReducer,
  [MUSCLE_GROUPS_NAME]: muscleGroupsReducer,
  [LIVE_SCHEDULE_NAME]: liveScheduleReducer,
  [UI_NAME]: uiReducer,
  [WEEKLY_PLAN_NAME]: weeklyPlanReducer,
  [PROMOTION_NAME]: promotionReducer,
  [INVOICES_NAME]: invoicesReducer,
  [WEEKLY_MANTRA_NAME]: weeklyMantraReducer,
}

const appReducer = persistCombineReducers(persistConfig, {
  ...sharedReducers,
  ...reducers,
})

export const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT_SUCCESS) {
    const clearedState = {
      _persist: state._persist, // eslint-disable-line
    }
    storage.removeItem('persist:App')
    if (state.UI) clearedState.UI = state.UI
    return appReducer(clearedState, action)
  }
  return appReducer(state, action)
}

import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'

import { selectAuthEmail } from 'shared/modules/Auth/selectors'
import { selectUserStripeCustomerId } from 'shared/modules/User/selectors'
import {
  createSubscriptionRequest,
  retryInvoiceRequest,
} from 'shared/modules/Subscription/actions'
import {
  selectSubscriptionLoading,
  selectSubscriptionLatestInvoicePaymentIntentStatus,
  selectSubscriptionLatestInvoiceId,
  selectSubscriptionPriceId,
} from 'shared/modules/Subscription/selectors'
import { useAuth } from 'shared/modules/Auth/hooks'
import { selectPromotionId } from 'shared/modules/Promotion/selectors'

import routes from 'web/routing/routes'
import { Grid, Alert } from 'web/components'
import StripeElementsCardDetailsForm from 'web/modules/Subscription/components/StripeElementsCardDetailsForm'
import {
  BillingDetailsPromotionCode,
  ActivePromotionCodes,
} from 'web/modules/Promotion/components'

import { SelectedSubscriptionDetails } from './components'

const BillingDetailsForm = ({ trialEnd }) => {
  const [loading, setLoading] = useState()
  const [formError, setFormError] = useState()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const history = useHistory()
  const { signedIn } = useAuth()

  const stripeCustomerId = useSelector(state =>
    selectUserStripeCustomerId(state),
  )
  const subscriptionLoading = useSelector(state =>
    selectSubscriptionLoading(state),
  )
  const latestInvoicePaymentIntentStatus = useSelector(state =>
    selectSubscriptionLatestInvoicePaymentIntentStatus(state),
  )
  const latestInvoiceId = useSelector(state =>
    selectSubscriptionLatestInvoiceId(state),
  )
  const priceId = useSelector(state => selectSubscriptionPriceId(state))
  const email = useSelector(state => selectAuthEmail(state))
  const promotionId = useSelector(state => selectPromotionId(state))

  const onSuccess = () => {
    setLoading(false)
    history.push(routes.DashboardPage.routePath)
  }

  const onError = ({ error }) => {
    setLoading(false)
    setFormError(error.code || error.statusCode || 'default')
  }

  const handleSubmit = async event => {
    setLoading(true)
    event.preventDefault()

    if (!stripe || !elements) {
      setLoading(false)
      return null
    }

    const cardElement = elements.getElement(CardNumberElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      setLoading(false)
      setFormError(error.code)
    } else {
      const paymentMethodId = paymentMethod.id
      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        dispatch(
          retryInvoiceRequest(
            stripe,
            stripeCustomerId,
            paymentMethodId,
            latestInvoiceId,
            priceId,
            onSuccess,
            onError,
          ),
        )
      } else {
        dispatch(
          createSubscriptionRequest(
            stripe,
            stripeCustomerId,
            paymentMethodId,
            priceId,
            trialEnd,
            promotionId,
            onSuccess,
            onError,
          ),
        )
      }
    }

    return null
  }

  const handleChange = () => setFormError(undefined)

  // @TODO redirect according to whether the user has used free trial or not.

  if (!signedIn)
    return (
      <Redirect
        to={{
          pathname: routes.SignInPage.routePath,
        }}
      />
    )

  if (!priceId)
    return (
      <Redirect
        to={{
          pathname: routes.RegistrationPricePlanPage.routePath,
        }}
      />
    )

  return (
    <Grid container>
      {!stripeCustomerId ? (
        <Alert severity="error">
          Oops.. something went wrong with your details. Please contact us.
        </Alert>
      ) : (
        <>
          <Grid item xs={12}>
            <SelectedSubscriptionDetails
              priceId={priceId}
              email={email}
              freeTrial={!!trialEnd}
            />
          </Grid>
          <BillingDetailsPromotionCode />
          <ActivePromotionCodes />
          <Grid item xs={12}>
            <StripeElementsCardDetailsForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              error={formError}
              disabled={!stripe}
              loading={loading || subscriptionLoading}
              submitButtonText={
                trialEnd ? 'Start 7 Day Free Trial' : 'Pay Now and Subscribe'
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

BillingDetailsForm.propTypes = {
  trialEnd: PropTypes.number,
}

export default memo(BillingDetailsForm)

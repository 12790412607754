import { defineMessages } from 'react-intl'

export default defineMessages({
  feedbackLabel: {
    id: 'web.Workouts.WorkoutFeedbackForm.feedbackLabel',
    defaultMessage: 'Feedback',
  },
  feedbackPlaceholder: {
    id: 'web.Workouts.WorkoutFeedbackForm.feedbackPlaceholder',
    defaultMessage:
      'Let us know what you enjoyed most, what you would like to see more of and how you feel after completing the workout. We want to hear from you!',
  },
  submitButton: {
    id: 'web.Workouts.WorkoutFeedbackForm.submitButton',
    defaultMessage: 'Submit your feedback',
  },
})

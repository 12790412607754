import * as actionTypes from './actionTypes'

export const getPlansRequest = () => ({
  type: actionTypes.GET_PLANS_REQUEST,
})

export const getPlansSuccess = plansByPriceId => ({
  type: actionTypes.GET_PLANS_SUCCESS,
  plansByPriceId,
})

export const getPlansError = error => ({
  type: actionTypes.GET_PLANS_ERROR,
  error,
})

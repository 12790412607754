import * as actionTypes from './actionTypes'

export const getInvoiceUpcomingRequest = (onSuccess, onError) => ({
  type: actionTypes.GET_INVOICE_UPCOMING_REQUEST,
  onSuccess,
  onError,
})

export const getInvoiceUpcomingSuccess = payload => ({
  type: actionTypes.GET_INVOICE_UPCOMING_SUCCESS,
  payload,
})

export const getInvoiceUpcomingError = error => ({
  type: actionTypes.GET_INVOICE_UPCOMING_ERROR,
  error,
})

import * as actionTypes from './actionTypes'

export const getWeeklyPlanRequest = () => ({
  type: actionTypes.GET_WEEKLY_PLAN_REQUEST,
})

export const getWeeklyPlanSuccess = weeklyPlan => ({
  type: actionTypes.GET_WEEKLY_PLAN_SUCCESS,
  weeklyPlan,
})

export const getWeeklyPlanError = error => ({
  type: actionTypes.GET_WEEKLY_PLAN_ERROR,
  error,
})

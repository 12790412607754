import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'

import { selectWorkoutsByCategoryIdArray } from 'shared/modules/Workouts/selectors'
import {
  selectWorkoutCategoryByIdName,
  selectWorkoutCategoryByIdSlug,
} from 'shared/modules/WorkoutCategories/selectors'

import routes from 'web/routing/routes'
import { Grid, ButtonPrimary } from 'web/components'
import WorkoutsCarousel from 'web/modules/Workouts/components/WorkoutsCarousel'

import { StyledGrid } from './components'

const WorkoutsCategoryList = ({ id }) => {
  const history = useHistory()
  const name = useSelector(state => selectWorkoutCategoryByIdName(state, id))
  const slug = useSelector(state => selectWorkoutCategoryByIdSlug(state, id))
  const workoutsArray = useSelector(state =>
    selectWorkoutsByCategoryIdArray(state, id),
  )

  if (!workoutsArray || !workoutsArray.length) return null

  const workouts = workoutsArray.splice(0, 5)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h3">{name}</Typography>
          </Grid>
          <StyledGrid item>
            <ButtonPrimary
              onClick={() =>
                history.push(`${routes.WorkoutCategoryPage.routeName}${slug}`)
              }
            >
              View Category
            </ButtonPrimary>
          </StyledGrid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <WorkoutsCarousel workouts={workouts} />
      </Grid>
    </Grid>
  )
}

WorkoutsCategoryList.propTypes = {
  id: PropTypes.string.isRequired,
}

export default WorkoutsCategoryList

import React from 'react'
import Typography from '@material-ui/core/Typography'

import { Grid } from 'web/components'

const ErrorComponent = () => (
  <Grid container justify="center" alignItems="center">
    <Grid item xs={10}>
      <Grid container>
        <Grid item xs={12}>
          <Typography>Looks like something went wrong.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Something might have been moved or deleted. Please try refreshing
            the page.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Contact us if this continues. This error has been recorded.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

export default ErrorComponent

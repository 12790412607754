import { useSelector } from 'react-redux'

import { selectWorkoutCategoryBySlug } from 'shared/modules/WorkoutCategories/selectors'
import {
  selectWorkoutsByCategoryIdArray,
  selectWorkoutsByWorkoutParentCategoryWithWorkoutsArray,
} from 'shared/modules/Workouts/selectors'

const WorkoutCategoryContainer = ({ children, render, slug }) => {
  const workoutCategory = useSelector(state =>
    selectWorkoutCategoryBySlug(state, slug),
  )

  const workoutCategoriesWithWorkouts = useSelector(state =>
    selectWorkoutsByWorkoutParentCategoryWithWorkoutsArray(
      state,
      workoutCategory.id,
    ),
  )

  const workoutsArray = useSelector(state =>
    selectWorkoutsByCategoryIdArray(state, workoutCategory?.id),
  )

  const renderProps = children || render

  return renderProps({
    workoutsArray,
    name: workoutCategory.name,
    summary: workoutCategory.summary,
    workoutCategoriesWithWorkouts,
  })
}

export default WorkoutCategoryContainer

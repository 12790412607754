import { createSelector } from 'reselect'

import { NAME } from './constants'

const selectWeeklyMantraState = state => state[NAME]

const selectWeekMantra = createSelector(
  selectWeeklyMantraState,
  weeklyMantra => weeklyMantra,
)

export const selectWeeklyMantraLoading = createSelector(
  selectWeekMantra,
  weeklyMantra => weeklyMantra?.loading,
)

export const selectWeeklyMantraError = createSelector(
  selectWeekMantra,
  weeklyMantra => weeklyMantra?.error,
)

export const selectWeeklyMantraMantra = createSelector(
  selectWeekMantra,
  weeklyMantra => weeklyMantra?.mantra,
)

export const selectWeeklyMantraVariant = createSelector(
  selectWeekMantra,
  weeklyMantra => weeklyMantra?.variant,
)

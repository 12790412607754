import { defineMessages } from 'react-intl'

export default defineMessages({
  nextPayment: {
    id: 'web.Invoices.UpcomingInvoiceAlert.nextPayment',
    defaultMessage:
      'We will attempt to collect your next payment of {TOTAL} on the {DATE}.',
  },
  trialing: {
    id: 'web.Invoices.UpcomingInvoiceAlert.trialing',
    defaultMessage:
      'Your Free Trial Period ends {FROM_NOW}. We will attempt to collect payment of {TOTAL} on the {DATE} from the card details provided during sign up to continue your subscription.',
  },
  canceling: {
    id: 'web.Invoices.UpcomingInvoiceAlert.canceling',
    defaultMessage:
      'Your subscription has been cancelled and will end on {DATE}. You will not be charged again. If you change your mind, just click Reactivate anytime!',
  },
  cancelingTrial: {
    id: 'web.Invoices.UpcomingInvoiceAlert.cancelingTrial',
    defaultMessage:
      'Your Free Trial Period has been cancelled. You will not be charged when it ends {FROM_NOW}. If you change your mind, just click Reactivate anytime!',
  },
  errorMessage: {
    id: 'web.Invoices.UpcomingInvoiceAlert.errorMessage',
    defaultMessage:
      'There was a problem fetching your upcoming invoice. Try refreshing and if the problem persists, please contact us.',
  },
})

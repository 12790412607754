import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.RegistrationPages.BillingDetailsPage.title',
    defaultMessage: 'Billing Details',
  },
  description: {
    id: 'web.Pages.RegistrationPages.BillingDetailsPage.description',
    defaultMessage:
      'Securely enter your payment details to complete your registration',
  },
  bodyTitle: {
    id: 'web.Pages.RegistrationPages.BillingDetailsPage.bodyTitle',
    defaultMessage: 'Complete your Subscription',
  },
})

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-final-form'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useHistory } from 'react-router-dom'

import {
  validateRequired,
  validateEmailAddress,
  composeValidators,
  parseEmailAddress,
} from 'shared/utils/FormUtils'
import { InputGroup } from 'shared/components'
import { useAuth } from 'shared/modules/Auth/hooks'

import routes from 'web/routing/routes'
import {
  Grid,
  MaterialIcon,
  FormInputText,
  FormInputPasswordToggle,
  ButtonPrimary,
  FormErrorDisplay,
} from 'web/components'
import { colors } from 'web/theme'

import messages from './messages'

const SignInForm = () => {
  const [displayError, setDisplayError] = useState()
  const { signIn, loading } = useAuth()
  const history = useHistory()

  const onSuccess = () => history.push(routes.DashboardPage.routePath)

  const onError = error => setDisplayError(error)

  return (
    <Form
      onSubmit={({ email, password }) => {
        setDisplayError(undefined)
        signIn(email, password, onSuccess, onError)
      }}
    >
      {({ handleSubmit }) => (
        <>
          <Grid container component="form" onSubmit={handleSubmit} noValidate>
            {displayError && (
              <Grid item xs={12}>
                <FormErrorDisplay error={displayError} />
              </Grid>
            )}

            <Grid item xs={12}>
              <InputGroup
                name="email"
                Component={FormInputText}
                validate={composeValidators(
                  validateRequired,
                  validateEmailAddress,
                )}
                parse={parseEmailAddress}
                placeholder={messages.emailLabel}
                label={<FormattedMessage {...messages.emailLabel} />}
                required
                hiddenLabel
                startAdornment={
                  <InputAdornment position="start">
                    <MaterialIcon
                      color={colors.formIcon}
                      icon="Person"
                      size={24}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InputGroup
                name="password"
                Component={FormInputPasswordToggle}
                label={<FormattedMessage {...messages.passwordLabel} />}
                validate={validateRequired}
                placeholder={messages.passwordLabel}
                hiddenLabel
                required
                type="password"
                startAdornment={
                  <InputAdornment position="start">
                    <MaterialIcon
                      color={colors.formIcon}
                      icon="Lock"
                      size={24}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} style={styles.buttonGrid}>
              <ButtonPrimary
                type="submit"
                onClick={handleSubmit}
                loading={loading}
              >
                <FormattedMessage {...messages.signInButton} />
              </ButtonPrimary>
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  )
}

const styles = {
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
}

export default SignInForm

import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import configureStore from './shared/redux/store'
import AppNavigation from './shared/routing/AppNavigation'
import { translationMessages } from './shared/i18n'
import './shared/config'

import { LanguageProviderContainer } from './shared/modules/LanguageProvider/components'

const { persistor, store } = configureStore()

const HybridApp = () => (
  <Provider store={store}>
    <LanguageProviderContainer messages={translationMessages}>
      <PersistGate persistor={persistor}>
        <AppNavigation />
      </PersistGate>
    </LanguageProviderContainer>
  </Provider>
)

export default HybridApp

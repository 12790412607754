import { defineMessages } from 'react-intl'

export default defineMessages({
  firstNameLabel: {
    id: 'web.User.UpdateUserDetailsForm.firstNameLabel',
    defaultMessage: 'First Name',
  },
  lastNameLabel: {
    id: 'web.User.UpdateUserDetailsForm.lastNameLabel',
    defaultMessage: 'Last Name',
  },
  submitButton: {
    id: 'web.User.UpdateUserDetailsForm.submitButton',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'web.User.UpdateUserDetailsForm.submitButton',
    defaultMessage: 'Cancel',
  },
})

export const transformEquipment = response => {
  if (!response || response.empty) return null

  let equipmentById = {}

  response.forEach(doc => {
    equipmentById = {
      ...equipmentById,
      [doc.id]: {
        ...doc.data(),
        id: doc.id,
      },
    }
  })

  return equipmentById
}

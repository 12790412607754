import React from 'react'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { addSubscriptionPriceId } from 'shared/modules/Subscription/actions'

import routes from 'web/routing/routes'
import { HelmetLocalised, FullPageBgFade, Image, Grid } from 'web/components'
import { PlanSelect } from 'web/modules/Plans/components'
import { RegisteringAs } from 'web/modules/Subscription/components'
import logo from 'web/images/evolve-logo.png'

import { RedirectionMessage } from './components'
import messages from './messages'

const PricePlanPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const handlePlanSelect = priceId => {
    dispatch(addSubscriptionPriceId(priceId))
    history.push(routes.RegistrationBillingDetailsPage.routePath)
  }

  const redirectionMessage = get(location, 'state.message')

  return (
    <>
      <HelmetLocalised
        title={messages.title}
        description={messages.description}
      />
      <FullPageBgFade type="second">
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={0}
          style={styles.wrapper}
        >
          <Grid item xs={11} md={6} lg={4}>
            <Grid container justify="center">
              <Grid item xs={5} sm={3} md={3}>
                <Image src={logo} alt="Evolve" />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      variant="h3"
                      component="h1"
                      align="center"
                    >
                      <FormattedMessage {...messages.bodyTitle} />
                    </Typography>
                  </Grid>
                  {redirectionMessage && (
                    <Grid item xs={12}>
                      <RedirectionMessage message={redirectionMessage} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PlanSelect onSelect={handlePlanSelect} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <RegisteringAs />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FullPageBgFade>
    </>
  )
}

const styles = {
  wrapper: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  image: {
    mixBlendMode: 'luminosity',
    width: '100%',
  },
  subText: {
    marginTop: '15px',
  },
  footer: {
    marginBottom: '40px',
  },
}

export default PricePlanPage

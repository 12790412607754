import { NAME } from './constants'

export const GET_USER_REQUEST = `${NAME}/GET_USER_REQUEST`
export const GET_USER_SUCCESS = `${NAME}/GET_USER_SUCCESS`
export const GET_USER_ERROR = `${NAME}/GET_USER_ERROR`

export const UPDATE_USER_REQUEST = `${NAME}/UPDATE_USER_REQUEST`
export const UPDATE_USER_SUCCESS = `${NAME}/UPDATE_USER_SUCCESS`
export const UPDATE_USER_ERROR = `${NAME}/UPDATE_USER_ERROR`

export const ADD_WORKOUT_TO_FAVOURITES_REQUEST = `${NAME}/ADD_WORKOUT_TO_FAVOURITES_REQUEST`
export const ADD_WORKOUT_TO_FAVOURITES_SUCCESS = `${NAME}/ADD_WORKOUT_TO_FAVOURITES_SUCCESS`

export const REMOVE_WORKOUT_FROM_FAVOURITES_REQUEST = `${NAME}/REMOVE_WORKOUT_FROM_FAVOURITES_REQUEST`
export const REMOVE_WORKOUT_FROM_FAVOURITES_SUCCESS = `${NAME}/REMOVE_WORKOUT_FROM_FAVOURITES_SUCCESS`

export const INCREMENT_WORKOUT_WATCHED_COUNT = `${NAME}/INCREMENT_WORKOUT_WATCHED_COUNT`

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'

import {
  validateRequired,
  validateEmailAddress,
  composeValidators,
  parseEmailAddress,
} from 'shared/utils/FormUtils'
import { InputGroup } from 'shared/components'
import { useAuth } from 'shared/modules/Auth/hooks'
import { updateEmailRequest } from 'shared/modules/Auth/actions'

import {
  Grid,
  FormInputText,
  FormInputPasswordToggle,
  ButtonPrimary,
  ButtonSecondary,
  FormErrorDisplay,
} from 'web/components'

import messages from './messages'

const UpdateEmailForm = ({ onSuccess, onCancel }) => {
  const [displayError, setDisplayError] = useState()
  const dispatch = useDispatch()
  const { loading } = useAuth()

  const onError = error => setDisplayError(error)

  return (
    <Form
      onSubmit={({ email, emailPassword: password }) => {
        setDisplayError(undefined)
        dispatch(updateEmailRequest(email, password, onSuccess, onError))
      }}
    >
      {({ handleSubmit }) => (
        <>
          <Grid container component="form" onSubmit={handleSubmit} noValidate>
            {displayError && (
              <Grid item xs={12}>
                <FormErrorDisplay error={displayError} />
              </Grid>
            )}
            <Grid item xs={12}>
              <InputGroup
                name="email"
                Component={FormInputText}
                validate={composeValidators(
                  validateRequired,
                  validateEmailAddress,
                )}
                parse={parseEmailAddress}
                label={<FormattedMessage {...messages.emailLabel} />}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputGroup
                name="emailPassword"
                Component={FormInputPasswordToggle}
                validate={validateRequired}
                label={<FormattedMessage {...messages.passwordLabel} />}
                required
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonPrimary
                type="submit"
                onClick={handleSubmit}
                loading={loading}
              >
                <FormattedMessage {...messages.submitButton} />
              </ButtonPrimary>
              <ButtonSecondary onClick={onCancel}>
                <FormattedMessage {...messages.cancelButton} />
              </ButtonSecondary>
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  )
}

UpdateEmailForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default UpdateEmailForm

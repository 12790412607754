import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { selectPromotionPromotion } from 'shared/modules/Promotion/selectors'

import { Grid, ButtonSecondary } from 'web/components'
import { PromotionCodeForm } from 'web/modules/Promotion/components'

import messages from './messages'

const BillingDetailsPromotionCode = () => {
  const [showForm, setShowForm] = useState()
  const promotion = useSelector(state => selectPromotionPromotion(state))

  return (
    <>
      {!showForm && !promotion && (
        <Grid container justify="center">
          <Grid item>
            <ButtonSecondary
              size="small"
              onClick={() => setShowForm(!showForm)}
            >
              <FormattedMessage {...messages.enterCodeButton} />
            </ButtonSecondary>
          </Grid>
        </Grid>
      )}
      {showForm && !promotion && (
        <Grid item xs={12}>
          <PromotionCodeForm />
        </Grid>
      )}
    </>
  )
}

export default BillingDetailsPromotionCode

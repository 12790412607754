import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  selectSubscriptionPriceId,
  selectSubscriptionLoading,
} from 'shared/modules/Subscription/selectors'
import {
  selectPlanByPriceIdAccess,
  selectPlansLoading,
} from 'shared/modules/Plans/selectors'

import { CircularProgress } from 'web/components'

import { UpgradeOptions } from './components'

const AccessLevelRequired = ({ children, requiredAccessLevel }) => {
  const [planPriceAccessLevel, setPlanPriceAccessLevel] = useState()
  const subscriptionPriceId = useSelector(state =>
    selectSubscriptionPriceId(state),
  )
  const currentPlanPriceAccessLevel = useSelector(state =>
    selectPlanByPriceIdAccess(state, subscriptionPriceId),
  )
  const subscriptionLoading = useSelector(state =>
    selectSubscriptionLoading(state),
  )
  const plansLoading = useSelector(state => selectPlansLoading(state))

  useEffect(() => {
    if (!planPriceAccessLevel) {
      setPlanPriceAccessLevel(currentPlanPriceAccessLevel)
    }
  }, [currentPlanPriceAccessLevel, planPriceAccessLevel])

  if (
    (subscriptionLoading && !subscriptionPriceId) ||
    (plansLoading && !planPriceAccessLevel)
  )
    return <CircularProgress />

  if (planPriceAccessLevel >= requiredAccessLevel) return children

  return <UpgradeOptions requiredAccessLevel={requiredAccessLevel} />
}

AccessLevelRequired.propTypes = {
  children: PropTypes.node.isRequired,
  requiredAccessLevel: PropTypes.number.isRequired,
}

export default AccessLevelRequired

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import { FormattedMessage } from 'react-intl'

import { selectPromotionPromotion } from 'shared/modules/Promotion/selectors'
import { removePromotion } from 'shared/modules/Promotion/actions'
import { addSubscriptionPromotionRequest } from 'shared/modules/Subscription/actions'

import { Card, Alert, Grid } from 'web/components'
import { PromotionCodeForm } from 'web/modules/Promotion/components'

import messages from './messages'

const AddPromotion = ({ canceling, downgrading }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState()
  const [displayError, setDisplayError] = useState()
  const promotion = useSelector(state => selectPromotionPromotion(state))

  const handleApplyError = error => {
    setLoading(false)
    setDisplayError(error)
    handleCancel()
  }

  const handleApply = () => {
    setDisplayError(null)
    setLoading(true)
    dispatch(addSubscriptionPromotionRequest(promotion.id, handleApplyError))
  }

  const handleCancel = () => {
    dispatch(removePromotion())
  }

  if (canceling) {
    return (
      <Card>
        <CardHeader title="Enter Promotion Code" />
        <CardContent>
          <Alert severity="info">
            <FormattedMessage {...messages.canceling} />
          </Alert>
        </CardContent>
      </Card>
    )
  }

  if (downgrading) {
    return (
      <Card>
        <CardHeader title="Enter Promotion Code" />
        <CardContent>
          <Alert severity="info">
            <FormattedMessage {...messages.downgrading} />
          </Alert>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader title="Enter Promotion Code" />
      <CardContent>
        <Grid container>
          {displayError && (
            <Grid item xs={12}>
              <Alert severity="error">
                Sorry, we could not add that promotion to your subscription. You
                can try again or contact us if you continue to experience
                problems.
              </Alert>
            </Grid>
          )}
          {promotion ? (
            <Grid item xs={12}>
              <Alert
                severity="success"
                action={
                  <>
                    <Button onClick={handleApply} disabled={loading}>
                      Apply Promotion
                    </Button>
                    <IconButton aria-label="delete" onClick={handleCancel}>
                      <CancelIcon />
                    </IconButton>
                  </>
                }
              >
                <strong>{promotion.code}</strong>{' '}
                {promotion.description && `- ${promotion.description}`}{' '}
                <strong>
                  Remember to apply this promotion to your subscription!
                </strong>
              </Alert>
            </Grid>
          ) : (
            <Grid item xs={12} sm={10} md={8}>
              <PromotionCodeForm />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

AddPromotion.propTypes = {
  canceling: PropTypes.bool,
  downgrading: PropTypes.bool,
}

export default AddPromotion

import * as actionTypes from './actionTypes'

export const getMuscleGroupsRequest = () => ({
  type: actionTypes.GET_MUSCLE_GROUPS_REQUEST,
})

export const getMuscleGroupsSuccess = muscleGroupsById => ({
  type: actionTypes.GET_MUSCLE_GROUPS_SUCCESS,
  muscleGroupsById,
})

export const getMuscleGroupsError = error => ({
  type: actionTypes.GET_MUSCLE_GROUPS_ERROR,
  error,
})

import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'

import { validateRequired, composeValidators } from 'shared/utils/FormUtils'
import { InputGroup } from 'shared/components'
import { getPromotionRequest } from 'shared/modules/Promotion/actions'
import { selectPromotionPromotion } from 'shared/modules/Promotion/selectors'

import {
  Grid,
  FormInputText,
  ButtonPrimary,
  FormErrorDisplay,
} from 'web/components'

import messages, { errorMessages } from './messages'

const PromotionCodeForm = () => {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState()
  const [displayError, setDisplayError] = useState()
  const dispatch = useDispatch()
  const promotion = useSelector(state => selectPromotionPromotion(state))

  const onSuccess = () => {
    // setLoading(false)
  }

  const onError = error => {
    setLoading(false)
    setDisplayError(error)
  }

  return (
    <Form
      onSubmit={({ promotionCode }) => {
        setLoading(true)
        setDisplayError(null)
        dispatch(getPromotionRequest(promotionCode, onSuccess, onError))
      }}
    >
      {({ handleSubmit }) => (
        <Grid container component="form" onSubmit={handleSubmit} noValidate>
          {displayError && (
            <Grid item xs={12}>
              <FormErrorDisplay
                error={{
                  message: formatMessage(
                    errorMessages[displayError.message] ||
                      errorMessages.defaultError,
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <InputGroup
              name="promotionCode"
              Component={FormInputText}
              validate={composeValidators(validateRequired)}
              label={<FormattedMessage {...messages.codeLabel} />}
              required
            />
          </Grid>
          <Grid item xs={12} style={styles.buttonGrid}>
            <ButtonPrimary
              type="submit"
              onClick={handleSubmit}
              loading={loading}
              size="small"
              disabled={!!promotion}
            >
              <FormattedMessage {...messages.submitButton} />
            </ButtonPrimary>
          </Grid>
        </Grid>
      )}
    </Form>
  )
}

const styles = {
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
}

export default PromotionCodeForm

import { defineMessages } from 'react-intl'

export default defineMessages({
  subscriptionSummary: {
    id: 'web.Subscription.SubscriptionDetails.subscriptionSummary',
    defaultMessage:
      '<strong>Subscription</strong>: {ACCESS} / {STATUS} / {PRICE} / {FREQUENCY}',
  },
  subscriptionSummaryLoading: {
    id: 'web.Subscription.SubscriptionDetails.subscriptionSummaryLoading',
    defaultMessage: '<strong>Subscription</strong>: Loading...',
  },
  upgradeSubscription: {
    id: 'web.Subscription.SubscriptionDetails.upgradeSubscription',
    defaultMessage: 'Upgrade To Evolve Premium',
  },
  subscriptionPending: {
    id: 'web.Subscription.SubscriptionDetails.subscriptionPending',
    defaultMessage:
      'Looks like your Subscription is still being setup - please try back here in a few minutes.',
  },
  subscriptionError: {
    id: 'web.Subscription.SubscriptionDetails.subscriptionError',
    defaultMessage:
      'Something went wrong trying to fetch your subscription details. Please try refreshing the page. If this problem persists, please contact us.',
  },
  cardDetailsUpdated: {
    id: 'web.Subscription.SubscriptionDetails.cardDetailsUpdated',
    defaultMessage: 'Your Payment Card Details have been updated successfully.',
  },
})

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.DashboardPage.title',
    defaultMessage: 'Live Classes Schedule',
  },
  description: {
    id: 'web.Pages.DashboardPage.description',
    defaultMessage: "See what's coming up and when",
  },
})

import moment from 'moment'

import * as actionTypes from './actionTypes'

const initialState = {
  signedIn: false,
  uid: null,
  stripeCustomerId: undefined,
  lastActive: undefined,
  lastLoaded: undefined,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGN_IN_REQUEST:
    case actionTypes.SIGN_UP_REQUEST:
    case actionTypes.RESET_PASSWORD_REQUEST:
    case actionTypes.UPDATE_EMAIL_REQUEST:
    case actionTypes.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.SIGN_UP_ERROR:
    case actionTypes.SIGN_IN_ERROR:
    case actionTypes.RESET_PASSWORD_ERROR:
    case actionTypes.UPDATE_EMAIL_ERROR:
    case actionTypes.UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case actionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SIGN_IN_SUCCESS: {
      const timestamp = moment().valueOf()
      return {
        ...state,
        signedIn: true,
        lastActive: timestamp,
        lastLoaded: timestamp,
        loading: false,
        error: null,
        ...action.authData,
      }
    }
    case actionTypes.RESET_PASSWORD_SUCCESS:
    case actionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        email: action.email,
        loading: false,
      }
    default:
      return state
  }
}

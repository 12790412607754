import Error from '@material-ui/icons/Error'
import Lock from '@material-ui/icons/Lock'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Close from '@material-ui/icons/Close'
import HelpOutline from '@material-ui/icons/HelpOutline'
import Menu from '@material-ui/icons/Menu'
import Person from '@material-ui/icons/Person'
import Payment from '@material-ui/icons/Payment'
import LiveTv from '@material-ui/icons/LiveTvSharp'
import AccountBox from '@material-ui/icons/AccountBoxSharp'
import Home from '@material-ui/icons/HomeSharp'
import Today from '@material-ui/icons/TodaySharp'
import AccessibilityNew from '@material-ui/icons/AccessibilityNewSharp'
import Star from '@material-ui/icons/StarSharp'
import ExpandMore from '@material-ui/icons/ExpandMoreSharp'
import ExpandLess from '@material-ui/icons/ExpandLessSharp'

const iconPath = {
  Error,
  Lock,
  Map,
  Visibility,
  VisibilityOff,
  Close,
  HelpOutline,
  Menu,
  Person,
  Payment,
  LiveTv,
  AccountBox,
  Home,
  Today,
  AccessibilityNew,
  Star,
  ExpandMore,
  ExpandLess,
}

export default iconPath

import * as SentryReact from '@sentry/react'

export const Sentry = SentryReact

export const throwSentryError = error => {
  Sentry.captureException(error)
}

export const captureSentryMessage = (message, level) => {
  Sentry.captureMessage(message, level)
}

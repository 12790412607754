import { defineMessages } from 'react-intl'

export const plansMessages = defineMessages({
  basic: {
    id: 'shared.Plans.plansMessages.basic',
    defaultMessage: 'Evolve On Demand',
  },
  premium: {
    id: 'shared.Plans.plansMessages.premium',
    defaultMessage: 'Evolve Premium',
  },
})

export const frequencyMessages = defineMessages({
  monthly: {
    id: 'shared.Plans.frequencyMessages.monthly',
    defaultMessage: 'Monthly',
  },
  yearly: {
    id: 'shared.Plans.frequencyMessages.yearly',
    defaultMessage: 'Annual',
  },
})

export const planBenefitsMessages = defineMessages({
  basic: {
    id: 'shared.Plans.planBenefitsMessages.basic',
    defaultMessage:
      '<li>250+ On Demand Pilates, Yoga, Meditation classes</li><li>Mental Training Courses & Hypnotherapy Audios</li><li>1 New class published weekly</li><li>Guided Weekly Workout Planner</li><li>Cancel Anytime</li>',
  },
  premium: {
    id: 'shared.Plans.planBenefitsMessages.premium',
    defaultMessage:
      '<li>Livestream classes + Live class catch up recordings</li><li>250+ On Demand Pilates, Yoga, Meditation classes</li><li>Mental Training Courses & Hypnotherapy Audios</li><li>1 New class published weekly</li><li>Guided Weekly Workout Planner</li><li>Cancel Anytime</li>',
  },
})

import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import FormControl from '@material-ui/core/FormControl'

import { componentPropType, intlMessageShape } from 'web/utils/PropTypeUtils'

import StyledOutlinedInput from 'web/components/StyledOutlinedInput'
import StyledInputLabel from 'web/components/StyledInputLabel'
import Grid from 'web/components/Grid'
import FormInputErrorMessage from 'web/components/FormInputErrorMessage'
import StyledHelperText from 'web/components/StyledHelperText'

const FormInputText = ({
  label,
  helperText,
  endAdornment,
  startAdornment,
  type,
  multiline,
  inputGridOptions = { xs: 12 },
  placeholder,
  inputProps,
  required,
  hiddenLabel,
  disabled,
  inactive,
  input: { value, onChange, name },
  meta: { touched, error, submitError, dirtySinceLastSubmit },
  onClick,
}) => {
  const intl = useIntl()
  const showError =
    (touched && !!error) || (!!submitError && !dirtySinceLastSubmit)

  return (
    <FormControl
      fullWidth
      error={showError}
      disabled={disabled || inactive}
      aria-describedby={`${name}-text`}
    >
      <Grid item {...inputGridOptions} style={styles.slice}>
        <StyledInputLabel
          variant="outlined"
          htmlFor={name}
          required={required}
          hidden={hiddenLabel}
          inactive={inactive}
          {...(placeholder ? { shrink: true } : null)}
        >
          {label}
        </StyledInputLabel>
        <StyledOutlinedInput
          id={name}
          type={type}
          value={value}
          error={showError}
          onChange={onChange}
          required={required}
          endAdornment={endAdornment && endAdornment}
          startAdornment={startAdornment && startAdornment}
          rows={multiline || null}
          multiline={!!multiline}
          inputProps={{ 'data-testid': 'text-input', ...(inputProps || null) }}
          placeholder={
            typeof placeholder === 'object'
              ? intl.formatMessage(placeholder)
              : placeholder
          }
          inactive={inactive}
          readOnly={!!onClick}
          {...(onClick ? { onClick: () => onClick() } : null)}
        />
      </Grid>
      {!disabled && (
        <StyledHelperText
          id={error ? `${name}-text` : null}
          show={!!helperText || showError}
          style={styles.slice}
          data-testid={error ? 'input-error-text' : 'input-helper-text'}
        >
          {showError ? (
            <FormInputErrorMessage validationCode={error || submitError} />
          ) : (
            helperText
          )}
        </StyledHelperText>
      )}
    </FormControl>
  )
}

const styles = {
  slice: {
    flexBasis: 'auto',
  },
}

FormInputText.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    componentPropType(FormattedMessage),
  ]).isRequired,
  type: PropTypes.string,
  multiline: PropTypes.number,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  helperText: PropTypes.oneOfType([
    PropTypes.string,
    componentPropType(FormattedMessage),
  ]),
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  inputGridOptions: PropTypes.object,
  placeholder: PropTypes.oneOfType([PropTypes.string, intlMessageShape]),
  inputProps: PropTypes.object,
  required: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  inactive: PropTypes.bool,
  onClick: PropTypes.func,
}

export default FormInputText

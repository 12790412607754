import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import moment from 'moment'

export const validationCodes = {
  required: 'required',
  UKNationalInsurance: 'UKNationalInsurance',
  UKHomeTelephoneNumber: 'UKHomeTelephoneNumber',
  UKMobileTelephoneNumber: 'UKMobileTelephoneNumber',
  emailAddress: 'emailAddress',
  UKDate: 'UKDate',
  passwordStrength: 'passwordStrength',
  passwordsMatch: 'passwordsMatch',
  maxLength16: 'maxLength16',
  alphaNumeric: 'alphaNumeric',
  date: 'date',
  datePeriodMax: 'datePeriodMax',
  dateAfterActive: 'dateAfterActive',
  dateAfterYesterday: 'dateAfterYesterday',
  vehicleRequired: 'vehicleRequired',
  emailAddressesMatch: 'emailAddressesMatch',
  incorrectPassword: 'incorrectPassword',
  dayNumber: 'dayNumber',
  dateOfBirth: 'dateOfBirth',
  minimumDateOfBirth: 'minimumDateOfBirth',
  maximumDateOfBirth: 'maximumDateOfBirth',
  maxLength47: 'maxLength47',
  maxLengthPerLine47: 'maxLengthPerLine47',
  maxLines3: 'maxLines3',
  maxLength: 'maxLength',
  maxLength500: 'maxLength500',
}

export const MIN_RENTAL_LENGTH = 30
export const MAX_RENTAL_LENGTH = 85

export const validateRequired = value =>
  value && value.trim() ? undefined : validationCodes.required

export const validateUKNationalInsurance = value => {
  const regEx = /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]?\s*$/
  return value.match(regEx) ? undefined : validationCodes.UKNationalInsurance
}

export const validateUKMobileTelephoneNumber = value => {
  const regEx = /^7\d{9}$/
  return value.match(regEx)
    ? undefined
    : validationCodes.UKMobileTelephoneNumber
}

export const validateUKHomeTelephoneNumber = value => {
  const regEx = /^[1-9]\d{8,9}$/
  return !value || value.match(regEx)
    ? undefined
    : validationCodes.UKHomeTelephoneNumber
}

export const validateEmailAddress = value => {
  // eslint-disable-next-line
  const regEx = /^(?:[a-zA-Z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
  return value.match(regEx) ? undefined : validationCodes.emailAddress
}

export const validateUKDate = value => {
  const regEx = /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/
  return value.match(regEx) ? undefined : validationCodes.UKDate
}

export const validatePasswordStrength = value => {
  const regEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,20}$/
  return value && value.match(regEx)
    ? undefined
    : validationCodes.passwordStrength
}

export const validateFieldsMatch = (fieldNameToMatch, validationCode) => (
  value,
  allValues,
) => (value === allValues[fieldNameToMatch] ? undefined : validationCode)

export const validatePasswordsMatch = fieldNameToMatch =>
  validateFieldsMatch(fieldNameToMatch, validationCodes.passwordsMatch)

export const validateEmailAddressesMatch = fieldNameToMatch =>
  validateFieldsMatch(fieldNameToMatch, validationCodes.emailAddressesMatch)

export const validateMaxLength16 = value =>
  value.length <= 16 ? undefined : validationCodes.maxLength16

export const validateAlphaNumeric = value => {
  const regEx = /^[a-zA-Z0-9]*$/
  return value.match(regEx) ? undefined : validationCodes.alphaNumeric
}

export const validateDate = value => {
  const regEx = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
  return value.match(regEx) ? undefined : validationCodes.date
}

export const validateRentalStartAfterActiveRental = (value, allValues) => {
  const activeRentalEndDate =
    allValues[formFields.rentalDetails.activeRentalRequestEndDate]
  if (!activeRentalEndDate) return undefined
  return moment(value).isAfter(moment(activeRentalEndDate))
    ? undefined
    : validationCodes.dateAfterActive
}

export const validateStartDateAfterYesterday = value => {
  const yesterday = moment().subtract(1, 'd')
  return moment(value).isAfter(yesterday)
    ? undefined
    : validationCodes.dateAfterYesterday
}

export const validateVehicleSelect = value =>
  value ? undefined : validationCodes.vehicleRequired

export const validateRentalPeriod = (value, allValues) => {
  const rentalMinimumPeriod = moment(
    allValues[formFields.rentalDetails.rentalRequestStartDate],
  ).add(MIN_RENTAL_LENGTH - 1, 'd')
  const rentalMaximumPeriod = moment(
    allValues[formFields.rentalDetails.rentalRequestStartDate],
  ).add(MAX_RENTAL_LENGTH - 1, 'd')
  return moment(value).isSameOrAfter(rentalMinimumPeriod) &&
    moment(value).isSameOrBefore(rentalMaximumPeriod)
    ? undefined
    : validationCodes.datePeriodMax
}

export const validateDayNumber = value => {
  if (typeof value === 'number' && value >= 0 && value <= 6) return undefined
  return validationCodes.dayNumber
}

export const validateDateOfBirth = value => {
  const regEx = /^(19[0-9][0-9]|20[0-4][0-9]|2050)[-](0[1-9]|1[0-2])[-](0[1-9]|[12][0-9]|3[01])$/
  return value.match(regEx) ? undefined : validationCodes.dateOfBirth
}

export const parseEmailAddress = value =>
  value ? value.toLowerCase().trim() : value

export const validateDisabled = (list, item) => {
  if (list && (isArray(list) || isEmpty(list))) return list.includes(item)
  return false
}

export const validateStartDateInput = startDateValue => {
  if (!startDateValue) return true
  if (validateDate(startDateValue)) return true
  if (validateStartDateAfterYesterday(startDateValue)) return true
  return false
}

export const validateRentalPeriodSelected = (
  startDate,
  endDate,
  periodLength,
) => {
  const rentalPeriodInclusive = moment(endDate).diff(moment(startDate), 'd') + 1
  if (rentalPeriodInclusive === periodLength) return true
  return false
}

export const validateMaxLength47 = value =>
  value.length <= 47 ? undefined : validationCodes.maxLength47

export const validateMaxLength500 = value =>
  value.length <= 500 ? undefined : validationCodes.maxLength500

export const validateAddressLines = value => {
  const lines = value.split('\n')
  if (lines.length > 3) return validationCodes.maxLines3
  const reduce = lines.reduce(
    (acc, key) =>
      validateMaxLength47(key) ? validationCodes.maxLengthPerLine47 : acc,
    [],
  )
  if (reduce.indexOf(validationCodes.maxLengthPerLine47) !== -1)
    return validationCodes.maxLengthPerLine47
  return undefined
}

// @TODO ideally we can respond directly to the max length value, but that will mean adapting the error component
export const validateMaxLength = length => value =>
  value.length <= length ? undefined : validationCodes.maxLength

export const valuesHaveNotChanged = (original, latest) =>
  JSON.stringify(original) === JSON.stringify(latest)

export const validateLosses = (value, allValues) => {
  if (allValues.lossesAccidentsClaims === 'yes') return validateRequired(value)
  return undefined
}

export const validateMotoring = (value, allValues) => {
  if (allValues.motoringConvictionsOrPenalties === 'yes')
    return validateRequired(value)
  return undefined
}

export const validateMedical = (value, allValues) => {
  if (allValues.medicalDisabilities === 'yes') return validateRequired(value)
  return undefined
}

export const validateCriminal = (value, allValues) => {
  if (allValues.criminalConvictions === 'yes') return validateRequired(value)
  return undefined
}

export const composeValidators = (...validators) => (value, allValues) =>
  validators.reduce(
    (validationError, validator) =>
      validationError || validator(value, allValues),
    undefined,
  )

export const formFields = {
  rentalDetails: {
    preferredDepot: 'preferredDepot',
    vehicleId: 'vehicleId',
    rentalRequestStartDate: 'rentalRequestStartDate',
    rentalRequestEndDate: 'rentalRequestEndDate',
    rentalRequestBillingDay: 'rentalRequestBillingDay',
    rentalRequestBillingDate: 'rentalRequestBillingDate',
    activeRentalRequestEndDate: 'activeRentalRequestEndDate',
    parentExtension: 'parentExtension',
    referrer: 'referrer',
  },
  accountDetails: {
    firstName: 'firstName',
    lastName: 'lastName',
    dateOfBirth: 'dateOfBirth',
    emailAddress: 'emailAddress',
    confirmEmailAddress: 'confirmEmailAddress',
    password: 'password',
  },
  personalDetails: {
    nationalInsuranceNumber: 'nationalInsuranceNumber',
    mobilePhoneNumber: 'mobilePhoneNumber',
    homePhoneNumber: 'homePhoneNumber',
    address: 'address',
    city: 'city',
    postCode: 'postCode',
  },
  drivingDetails: {
    drivingLicenceNumber: 'drivingLicenceNumber',
    drivingLicenceDuration: 'drivingLicenceDuration',
    PCOLicenceDuration: 'PCOLicenceDuration',
    lossesAccidentsClaims: 'lossesAccidentsClaims',
    lossesAccidentsClaimsDetails: 'lossesAccidentsClaimsDetails',
    motoringConvictionsOrPenalties: 'motoringConvictionsOrPenalties',
    fixedPenaltyEndorsements: 'fixedPenaltyEndorsements',
    motoringConvictionsOrPenaltiesDetails:
      'motoringConvictionsOrPenaltiesDetails',
    medicalDisabilities: 'medicalDisabilities',
    medicalDisabilitiesDetails: 'medicalDisabilitiesDetails',
    criminalConvictions: 'criminalConvictions',
    criminalConvictionsDetails: 'criminalConvictionsDetails',
  },
  reportAccident: {
    dateTime: 'dateTime',
    location: 'location',
    description: 'description',
    registration: 'registration',
    firstName: 'firstName',
    lastName: 'lastName',
    telephoneNumber: 'telephoneNumber',
    insuranceProvider: 'insuranceProvider',
    insuranceNumber: 'insuranceNumber',
    images: 'images',
    sceneImages: 'sceneImages',
    thirdPartyImages: 'thirdPartyImages',
  },
}

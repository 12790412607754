import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import { registerUserForLiveEventRequest } from 'shared/modules/LiveSchedule/actions'
import { selectUserLiveScheduleItemByLiveScheduleIdJoinUrl } from 'shared/modules/User/selectors'

import { ButtonPrimary } from 'web/components'

const ButtonRegisterOrJoin = ({
  meetingNumber,
  liveScheduleId,
  onSuccess,
  onError,
}) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const joinUrl = useSelector(state =>
    selectUserLiveScheduleItemByLiveScheduleIdJoinUrl(state, liveScheduleId),
  )

  const handleRegisterSuccess = () => {
    setLoading(false)
    if (onSuccess) onSuccess()
  }

  const handleRegisterError = () => {
    setLoading(false)
    if (onError) onError()
    throwSentryError(
      Error(
        `Failed to register for Live Schedule - ${meetingNumber} - ${liveScheduleId}`,
      ),
    )
  }

  const handleRegister = () => {
    setLoading(true)
    dispatch(
      registerUserForLiveEventRequest(
        meetingNumber,
        liveScheduleId,
        handleRegisterSuccess,
        handleRegisterError,
      ),
    )
  }

  const handleJoin = () => {
    window.open(joinUrl, '_blank')
  }

  return (
    <ButtonPrimary
      onClick={joinUrl ? handleJoin : handleRegister}
      loading={loading}
      highlight={!!joinUrl}
    >
      {joinUrl ? 'Join Class' : 'Register Now'}
    </ButtonPrimary>
  )
}

ButtonRegisterOrJoin.propTypes = {
  meetingNumber: PropTypes.string.isRequired,
  liveScheduleId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
}

export default ButtonRegisterOrJoin

import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { buildCloudinaryImageSrc } from 'shared/utils/ImageUtils'

const Image = ({ alt, publicId, format }) => (
  <img
    style={styles}
    alt={alt}
    src={buildCloudinaryImageSrc(publicId, format, 'c_fill,w_800,h_450')}
    // @TODO add srcset to help with optimisation and load time
    // srcset="image-smallest.jpg 719w,
    //         image-medium.jpg 1019w,
    //         image.jpg 1400w"
    // sizes="(min-width: 1600px) 1224px, (min-width: 1020px) calc(100vw - 140px), (min-width: 720px) calc(100vw - 90px), calc(100vw - 60px)"
  />
)

const styles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  publicId: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
}

export default memo(Image)

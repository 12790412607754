import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { selectUserStripeCustomerId } from 'shared/modules/User/selectors'
import {
  selectSubscriptionStatus,
  selectSubscriptionPriceId,
  selectSubscriptionLastLoaded,
  selectSubscriptionLoading,
  selectSubscriptionError,
} from 'shared/modules/Subscription/selectors'
import { getSubscriptionRequest } from 'shared/modules/Subscription/actions'

const SubscriptionContainer = ({ children, render }) => {
  const dispatch = useDispatch()

  const stripeCustomerId = useSelector(state =>
    selectUserStripeCustomerId(state),
  )
  const status = useSelector(state => selectSubscriptionStatus(state))
  const priceId = useSelector(state => selectSubscriptionPriceId(state))
  const lastLoaded = useSelector(state => selectSubscriptionLastLoaded(state))
  const loading = useSelector(state => selectSubscriptionLoading(state))
  const error = useSelector(state => selectSubscriptionError(state))

  const shouldBeLoading = lastLoaded
    ? moment(lastLoaded).isBefore(moment().subtract(5, 'minutes'))
    : true

  useEffect(() => {
    if (shouldBeLoading) dispatch(getSubscriptionRequest())
  }, [dispatch, shouldBeLoading, stripeCustomerId])

  const renderProps = children || render

  return renderProps({
    status,
    priceId,
    lastLoaded,
    loading: loading || shouldBeLoading,
    error,
  })
}

SubscriptionContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
}

export default SubscriptionContainer

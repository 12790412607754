import moment from 'moment'

import {
  ADD_SUBSCRIPTION_PROMOTION_SUCCESS,
  SCHEDULE_SUBSCRIPTION_DOWNGRADE_SUCCESS,
  CANCEL_SUBSCRIPTION_DOWNGRADE_SUCCESS,
  UPDATE_SUBSCRIPTION_PRICE_SUCCESS,
} from 'shared/modules/Subscription/actionTypes'

import * as actionTypes from './actionTypes'

const initialState = {
  upcoming: {
    lastLoaded: undefined,
    loading: false,
    error: false,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_INVOICE_UPCOMING_REQUEST:
      return {
        ...state,
        upcoming: {
          ...state.upcoming,
          loading: true,
          error: null,
        },
      }
    case actionTypes.GET_INVOICE_UPCOMING_SUCCESS:
      return {
        ...state,
        upcoming: {
          ...state.upcoming,
          ...action.payload,
          lastLoaded: moment().valueOf(),
          loading: false,
          error: null,
        },
      }
    case actionTypes.GET_INVOICE_UPCOMING_ERROR:
      return {
        ...state,
        upcoming: {
          ...state.upcoming,
          loading: false,
          error: action.error,
        },
      }
    // @NOTE Forces a refresh on the data for the next time it will be displayed
    case actionTypes.INVOICE_SHOULD_UPDATE:
    case ADD_SUBSCRIPTION_PROMOTION_SUCCESS:
    case SCHEDULE_SUBSCRIPTION_DOWNGRADE_SUCCESS:
    case CANCEL_SUBSCRIPTION_DOWNGRADE_SUCCESS:
    case UPDATE_SUBSCRIPTION_PRICE_SUCCESS:
      return {
        ...state,
        upcoming: {
          ...state.upcoming,
          lastLoaded: undefined,
        },
      }
    default:
      return state
  }
}

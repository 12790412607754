export const hexToRgba = (hex, opacity) => {
  if (!hex) return `rgba(0,0,0, ${opacity || 1})`
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const newHex = hex.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b,
  )
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex)
  const color = x => parseInt(result[x], 16)
  const string = `rgba(${color(1)},${color(2)},${color(3)},${opacity || 1})`
  return result ? string : null
}

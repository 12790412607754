import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from './selectors'
import * as actions from './actions'

export const useAuth = () => {
  const uid = useSelector(state => selectors.selectAuthUid(state))
  const signedIn = useSelector(state => selectors.selectAuthSignedIn(state))
  const loading = useSelector(state => selectors.selectAuthLoading(state))
  const error = useSelector(state => selectors.selectAuthError(state))

  const dispatch = useDispatch()

  const signIn = useCallback(
    (email, password, onSuccess, onError) =>
      dispatch(actions.signInRequest(email, password, onSuccess, onError)),
    [dispatch],
  )

  const clearError = useCallback(() => dispatch(actions.clearError()), [
    dispatch,
  ])

  return {
    uid,
    signedIn,
    signIn,
    loading,
    error,
    clearError,
  }
}

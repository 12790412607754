import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getLiveScheduleItemRequest } from 'shared/modules/LiveSchedule/actions'
import {
  selectLiveScheduleItemByIdCatchupUrl,
  selectLiveScheduleItemByIdDescription,
  selectLiveScheduleItemByIdEndDateTime,
  selectLiveScheduleItemByIdMeetingNumber,
  selectLiveScheduleItemByIdMeetingPassword,
  selectLiveScheduleItemByIdName,
  selectLiveScheduleItemByIdStartDateTime,
  selectLiveScheduleItemByIdSummary,
} from 'shared/modules/LiveSchedule/selectors'

const LiveScheduleItemContainer = ({ children, render, id }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  const onError = () => setError(true)
  const onSuccess = () => setLoading(false)

  const catchupUrl = useSelector(state =>
    selectLiveScheduleItemByIdCatchupUrl(state, id),
  )
  const description = useSelector(state =>
    selectLiveScheduleItemByIdDescription(state, id),
  )
  const endDateTime = useSelector(state =>
    selectLiveScheduleItemByIdEndDateTime(state, id),
  )
  const meetingNumber = useSelector(state =>
    selectLiveScheduleItemByIdMeetingNumber(state, id),
  )
  const meetingPassword = useSelector(state =>
    selectLiveScheduleItemByIdMeetingPassword(state, id),
  )
  const name = useSelector(state => selectLiveScheduleItemByIdName(state, id))
  const startDateTime = useSelector(state =>
    selectLiveScheduleItemByIdStartDateTime(state, id),
  )
  const summary = useSelector(state =>
    selectLiveScheduleItemByIdSummary(state, id),
  )

  useEffect(() => {
    dispatch(getLiveScheduleItemRequest(id, onSuccess, onError))
  }, [dispatch, id])

  const renderProps = children || render

  return renderProps({
    catchupUrl,
    description,
    endDateTime,
    meetingNumber,
    meetingPassword,
    name,
    startDateTime,
    summary,
    loading,
    error,
  })
}

export default LiveScheduleItemContainer

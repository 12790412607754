import React from 'react'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import { FormattedMessage } from 'react-intl'

import routes from 'web/routing/routes'
import {
  HelmetLocalised,
  FullPageBgFade,
  Image,
  Grid,
  Link,
  Card,
  Alert,
} from 'web/components'
import { UserDetailsForm } from 'web/modules/Auth/components'
import logo from 'web/images/evolve-logo.png'

import messages from './messages'

const UserDetailsPage = () => (
  <>
    <HelmetLocalised
      title={messages.title}
      description={messages.description}
    />
    <FullPageBgFade type="second">
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={0}
        style={styles.wrapper}
      >
        <Grid item xs={11} md={6} lg={4}>
          <Grid container justify="center">
            <Grid item xs={5} sm={3} md={3}>
              <Image src={logo} alt="Evolve" />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item xs={12} sm={6} md={8} lg={10}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        variant="h3"
                        component="h1"
                        align="center"
                      >
                        <FormattedMessage {...messages.bodyTitle} />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Alert>
                        Register today to start your{' '}
                        <strong>7 Day free trial</strong>!
                      </Alert>
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <UserDetailsForm freeTrial />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        align="center"
                        style={styles.footer}
                      >
                        <FormattedMessage
                          {...messages.signInCallToAction}
                          values={{
                            SIGN_IN_LINK: (
                              <Link to={routes.SignInPage.routePath}>
                                <FormattedMessage
                                  {...messages.signInCallToActionLink}
                                />
                              </Link>
                            ),
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullPageBgFade>
  </>
)

const styles = {
  wrapper: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  image: {
    mixBlendMode: 'luminosity',
    width: '100%',
  },
  footer: {
    marginBottom: '40px',
  },
}

export default UserDetailsPage

import { SIGN_IN_SUCCESS } from 'shared/modules/Auth/actionTypes'
import { CREATE_SUBSCRIPTION_SUCCESS } from 'shared/modules/Subscription/actionTypes'
import * as actionTypes from './actionTypes'

const initialState = {
  promotion: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_PROMOTION: {
      const promotion =
        action.promotion && action.promotion.active ? action.promotion : null
      return {
        ...state,
        ...(promotion
          ? {
              promotion,
            }
          : null),
      }
    }
    case actionTypes.REMOVE_PROMOTION:
    case SIGN_IN_SUCCESS:
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        promotion: null,
      }
    default:
      return state
  }
}

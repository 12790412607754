import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import InputAdornment from '@material-ui/core/InputAdornment'

import {
  validateRequired,
  validateEmailAddress,
  composeValidators,
  parseEmailAddress,
} from 'shared/utils/FormUtils'
import { InputGroup } from 'shared/components'
import { useAuth } from 'shared/modules/Auth/hooks'
import { resetPasswordRequest } from 'shared/modules/Auth/actions'

import {
  Grid,
  MaterialIcon,
  FormInputText,
  ButtonPrimary,
  FormErrorDisplay,
  Alert,
} from 'web/components'
import { colors } from 'web/theme'

import messages from './messages'

const ResetPasswordForm = () => {
  const [displayError, setDisplayError] = useState()
  const [displaySuccess, setDisplaySuccess] = useState()
  const dispatch = useDispatch()
  const { loading } = useAuth()

  const onSuccess = () => setDisplaySuccess(true)

  const onError = error => setDisplayError(error)

  return (
    <Form
      onSubmit={({ email }) => {
        setDisplayError(undefined)
        setDisplaySuccess(false)
        dispatch(resetPasswordRequest(email, onSuccess, onError))
      }}
    >
      {({ handleSubmit }) => (
        <>
          <Grid container component="form" onSubmit={handleSubmit} noValidate>
            {displayError && (
              <Grid item xs={12}>
                <FormErrorDisplay error={displayError} />
              </Grid>
            )}
            {displaySuccess && (
              <Grid item xs={12}>
                <Alert severity="success">
                  <FormattedMessage {...messages.success} />
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <InputGroup
                name="email"
                Component={FormInputText}
                validate={composeValidators(
                  validateRequired,
                  validateEmailAddress,
                )}
                parse={parseEmailAddress}
                placeholder={messages.emailLabel}
                label={<FormattedMessage {...messages.emailLabel} />}
                required
                hiddenLabel
                startAdornment={
                  <InputAdornment position="start">
                    <MaterialIcon
                      color={colors.signInInputIcon}
                      icon="Person"
                      size={24}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} style={styles.buttonGrid}>
              <ButtonPrimary
                type="submit"
                onClick={handleSubmit}
                loading={loading}
              >
                <FormattedMessage {...messages.submitButton} />
              </ButtonPrimary>
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  )
}

const styles = {
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
}

export default ResetPasswordForm

// @NOTE toFixed has issues rounding numbers, especially those ending in 5
// check the affect of this rounding when using the `points` arg

import moment from 'moment'

export const formatMoney = (amount, points = 2) => {
  if (!amount) return 'NaN'
  return Number(amount)
    .toFixed(points)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const generateUniqueCode = (uid, length = 8) => {
  if (!uid) return undefined
  let result = ''
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  for (let i = 0; i < length / 2; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  for (let i = 0; i < length / 2; i++) {
    result += uid.charAt(Math.floor(Math.random() * uid.length))
  }
  return result.toUpperCase()
}

export const removeUKPrefixFromPhoneNumber = phoneNumber =>
  phoneNumber ? phoneNumber.replace(/^44/, '') : ''

export const addUKPrefixToPhoneNumber = phoneNumber => {
  if (!phoneNumber) return ''
  return phoneNumber.startsWith('44') ? phoneNumber : `44${phoneNumber}`
}

export const getEndOfPathname = pathname =>
  pathname
    .replace(/\/$/, '') // remove any trialing slash to avoid empty string
    .split('/')
    .pop()

export const kebabCase = string =>
  string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()

export const isExternalUrl = url => /^(http|https):\/\//.test(url)

export const formatPaymentCardExpiry = (expMonth, expYear) => {
  if (!expMonth || !expYear) return undefined
  return `${moment()
    .month(expMonth - 1)
    .format('MM')}/${moment()
    .year(expYear)
    .format('YY')}`
}

export const emailCensor = email => {
  const splitted = email.split('@')
  let part1 = splitted[0]
  const avg = part1.length / 2
  part1 = part1.substring(0, part1.length - avg)
  const part2 = splitted[1]
  return `${part1}...@${part2}`
}

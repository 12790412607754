import { call, put, takeLatest, all, spawn, select } from 'redux-saga/effects'
import moment from 'moment'

import { throwSentryError } from 'shared/utils/ErrorUtils'
import { authenticatedRequest } from 'shared/utils/RequestUtils'
import { envConfig } from 'shared/config'
import * as database from 'shared/firebase/database'
import { getWorkoutCategories } from 'shared/modules/WorkoutCategories/sagas'
import { getEquipment } from 'shared/modules/Equipment/sagas'
import { getMuscleGroups } from 'shared/modules/MuscleGroups/sagas'
import { selectAuthUid } from 'shared/modules/Auth/selectors'
import { selectUserFirstName } from 'shared/modules/User/selectors'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import {
  transformWorkouts,
  transformWorkout,
  transformWorkoutBySlug,
  transformWorkoutFeedback,
} from './utils'
import { POST_WORKOUT_FEEDBACK_URL } from './constants'

function* getWorkouts() {
  try {
    const responses = yield all([
      call(getWorkoutCategories),
      call(getEquipment),
      call(getMuscleGroups),
      call(database.getWorkouts),
    ])
    yield put(actions.getWorkoutsSuccess(transformWorkouts(responses[3])))
  } catch (error) {
    yield put(actions.getWorkoutsError(error))
    yield spawn(throwSentryError, error)
  }
}

function* getWorkout({ id }) {
  try {
    const response = yield call(database.getWorkout, id)
    yield put(actions.getWorkoutSuccess(transformWorkout(response, id)))
  } catch (error) {
    yield put(actions.getWorkoutError(id, error))
    yield spawn(throwSentryError, error)
  }
}

function* getWorkoutBySlug({ slug, onSuccess, onError }) {
  try {
    const response = yield call(database.getWorkoutBySlug, slug)
    yield put(actions.getWorkoutBySlugSuccess(transformWorkoutBySlug(response)))
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.getWorkoutBySlugError(error))
    if (onError) onError()
    yield spawn(throwSentryError, error)
  }
}

function* getWorkoutFeedback({ id }) {
  try {
    const response = yield call(database.getWorkoutFeedback, id)
    yield put(
      actions.getWorkoutFeedbackSuccess(transformWorkoutFeedback(response, id)),
    )
  } catch (error) {
    yield put(actions.getWorkoutFeedbackError(id, error))
    yield spawn(throwSentryError, error)
  }
}

function* postWorkoutFeedback({ payload, onSuccess, onError }) {
  try {
    const timestamp = moment().valueOf()
    const uid = yield select(selectAuthUid)
    const name = yield select(selectUserFirstName)
    const options = {
      method: 'POST',
      body: JSON.stringify({ ...payload, timestamp, uid, name, status: 'new' }),
    }
    yield call(
      authenticatedRequest,
      `${envConfig.FIREBASE_API}${POST_WORKOUT_FEEDBACK_URL}${payload.workoutId}`,
      options,
    )
    yield put(actions.postWorkoutFeedbackSuccess())
    if (onSuccess) onSuccess()
  } catch (error) {
    yield put(actions.postWorkoutFeedbackError(payload.workoutId, error))
    if (onError) onError()
    yield spawn(throwSentryError, error)
  }
}

export default [
  takeLatest(actionTypes.GET_WORKOUTS_REQUEST, getWorkouts),
  takeLatest(actionTypes.GET_WORKOUT_REQUEST, getWorkout),
  takeLatest(actionTypes.GET_WORKOUT_BY_SLUG_REQUEST, getWorkoutBySlug),
  takeLatest(actionTypes.GET_WORKOUT_FEEDBACK_REQUEST, getWorkoutFeedback),
  takeLatest(actionTypes.POST_WORKOUT_FEEDBACK_REQUEST, postWorkoutFeedback),
]

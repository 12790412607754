import React from 'react'
import { useLocation } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'

import {
  selectWorkoutCategoriesBySlug,
  selectWorkoutCategoriesById,
} from 'shared/modules/WorkoutCategories/selectors'

import { Link } from 'web/components'

const WorkoutCategoryBreadcrumbs = () => {
  const { pathname } = useLocation()

  const workoutCategoriesBySlug = useSelector(state =>
    selectWorkoutCategoriesBySlug(state),
  )

  const workoutCategoriesById = useSelector(state =>
    selectWorkoutCategoriesById(state),
  )

  const breadcrumbs = pathname
    .split('/')
    .filter(part => part !== 'workout-category' && part !== '')

  if (breadcrumbs.length <= 1) {
    return null
  }

  const getBreadcrumbSlug = position =>
    breadcrumbs.slice(0, position + 1).join('/')

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, i) =>
        i === breadcrumbs.length - 1 ? (
          <Typography key={breadcrumb} color="textPrimary">
            {
              workoutCategoriesById[
                workoutCategoriesBySlug[getBreadcrumbSlug(i)]
              ].name
            }
          </Typography>
        ) : (
          <Link
            to={`/workout-category/${getBreadcrumbSlug(i)}`}
            key={breadcrumb}
          >
            {
              workoutCategoriesById[
                workoutCategoriesBySlug[getBreadcrumbSlug(i)]
              ].name
            }
          </Link>
        ),
      )}
    </Breadcrumbs>
  )
}

export default WorkoutCategoryBreadcrumbs

import moment from 'moment'
import { REGISTER_USER_FOR_LIVE_EVENT_SUCCESS } from 'shared/modules/LiveSchedule/actionTypes'
import { CREATE_SUBSCRIPTION_SUCCESS } from 'shared/modules/Subscription/actionTypes'
import { SUBSCRIPTION_STATUS } from 'shared/modules/Subscription/constants'
import * as actionTypes from './actionTypes'

const initialState = {
  firstName: undefined,
  lastName: undefined,
  liveScheduleById: null,
  workoutFavouritesById: null,
  freeTrialUsed: undefined,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_REQUEST:
    case actionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GET_USER_SUCCESS:
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        ...action.user,
        loading: false,
      }
    case actionTypes.GET_USER_ERROR:
    case actionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case REGISTER_USER_FOR_LIVE_EVENT_SUCCESS:
      return {
        ...state,
        liveScheduleById: {
          ...state.liveScheduleById,
          [action.liveScheduleId]: {
            meetingNumber: action.meetingNumber,
            joinUrl: action.joinUrl,
            id: action.liveScheduleId,
          },
        },
      }
    case actionTypes.ADD_WORKOUT_TO_FAVOURITES_SUCCESS: {
      const timestamp = moment().valueOf()
      return {
        ...state,
        workoutFavouritesById: {
          ...state.workoutFavouritesById,
          [action.workoutId]: {
            timestamp,
          },
        },
      }
    }
    case actionTypes.REMOVE_WORKOUT_FROM_FAVOURITES_SUCCESS: {
      const {
        [action.workoutId]: removedWorkout,
        ...workoutFavouritesById
      } = state.workoutFavouritesById
      return {
        ...state,
        workoutFavouritesById,
      }
    }
    case CREATE_SUBSCRIPTION_SUCCESS: {
      if (action.status === SUBSCRIPTION_STATUS.trialing) {
        return {
          ...state,
          freeTrialUsed: true,
        }
      }
      return state
    }
    default:
      return state
  }
}

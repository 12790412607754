import { createSelector } from 'reselect'
import get from 'lodash/get'
import { NAME } from './constants'

export const selectPromotionState = state => state[NAME]

export const selectPromotion = createSelector(
  selectPromotionState,
  promotionState => promotionState,
)

export const selectPromotionPromotion = createSelector(
  selectPromotion,
  promotion => promotion.promotion,
)

export const selectPromotionId = createSelector(
  selectPromotionPromotion,
  promotion => get(promotion, 'id'),
)

import React from 'react'
import styled from 'styled-components'

import { colors } from 'web/theme'

const StyledInputWrapper = styled(({ error, ...props }) => <div {...props} />)`
  & .StripeElement {
    padding: 5px 0px;
    border: 1px solid
      ${({ error }) => (error ? colors.inputBorderError : colors.inputBorder)};
    border-radius: 0;
    padding: 16px 12px;
    margin-top: 3px;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &.StripeElement--focus {
      border-color: ${colors.inputBorderFocused};
    }
    &:hover {
      border-color: ${colors.inputBorderHover};
      cursor: text;
    }
  }
`

export default StyledInputWrapper

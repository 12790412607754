import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import MuiListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import { isExternalUrl } from 'shared/utils/StringUtils'

import { colors } from 'web/theme'
import { ButtonMaterialIcon, MaterialIcon } from 'web/components'

const LinkListItem = ({
  to,
  activeOnlyWhenExact,
  icon,
  primary,
  secondary,
  onClick,
  iconRight,
  iconRightOnClick,
  nested,
  onActivate,
}) => {
  const classes = useStyles()
  const selected = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  })
  const { push } = useHistory()

  const buildListItemProps = () => {
    if (onClick)
      return {
        onClick: () => {
          onClick()
          onActivate()
        },
      }
    if (isExternalUrl(to))
      return {
        component: 'a',
        href: to,
        onClick: onActivate,
      }
    return {
      onClick: () => {
        push(to)
        onActivate()
      },
    }
  }

  const getPrimary = () => {
    if (primary && typeof primary === 'string') return primary
    return <FormattedMessage {...primary} />
  }

  const getSecondary = () => {
    if (secondary && typeof secondary === 'string') return secondary
    return <FormattedMessage {...secondary} />
  }

  return (
    <MuiListItem
      button
      selected={!!selected}
      {...buildListItemProps()}
      classes={{
        root: nested ? classes.muiListItemNested : classes.muiListItem,
        selected: classes.muiListItemSelected,
      }}
    >
      {icon && (
        <ListItemIcon>
          <MaterialIcon
            icon={icon}
            color={
              selected ? colors.drawerItemIconSelected : colors.drawerItemIcon
            }
          />
        </ListItemIcon>
      )}
      <ListItemText
        primary={primary && getPrimary()}
        secondary={secondary && getSecondary()}
        classes={{
          root: selected ? classes.listItemTextSelected : classes.listItemText,
          secondary: selected
            ? classes.listItemTextSelected
            : classes.listItemTextSecondary,
        }}
      />
      {iconRight && (
        <ListItemSecondaryAction>
          <ButtonMaterialIcon
            icon={iconRight}
            color={
              selected ? colors.drawerItemIconSelected : colors.drawerItemIcon
            }
            onClick={iconRightOnClick}
            size={24}
          />
        </ListItemSecondaryAction>
      )}
    </MuiListItem>
  )
}

const useStyles = makeStyles(theme => ({
  muiListItem: {
    '&.Mui-selected': {
      backgroundColor:
        colors.drawerItemSelected || theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.grey['50'],
      },
    },
  },
  muiListItemNested: {
    paddingLeft: theme.spacing(4),
    '&.Mui-selected': {
      backgroundColor:
        colors.drawerItemSelected || theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.grey['50'],
      },
    },
  },
  muiListItemSelected: {
    backgroundColor:
      colors.drawerItemSelected || theme.palette.primary.contrastText,
  },
  listItemText: {
    color: colors.drawerItemText || theme.palette.primary.contrastText,
  },
  listItemTextSecondary: {
    color: colors.drawerItemTextSecondary || theme.palette.primary.contrastText,
  },
  listItemTextSelected: {
    color: colors.drawerItemTextSelected || theme.palette.primary.main,
  },
}))

LinkListItem.propTypes = {
  to: PropTypes.string,
  activeOnlyWhenExact: PropTypes.bool,
  icon: PropTypes.string,
  primary: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  secondary: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
  iconRight: PropTypes.string,
  iconRightOnClick: PropTypes.func,
  nested: PropTypes.bool,
  onActivate: PropTypes.func,
}

export default LinkListItem

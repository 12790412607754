import { NAME } from './constants'

export const GET_WORKOUTS_REQUEST = `${NAME}/GET_WORKOUTS_REQUEST`
export const GET_WORKOUTS_SUCCESS = `${NAME}/GET_WORKOUTS_SUCCESS`
export const GET_WORKOUTS_ERROR = `${NAME}/GET_WORKOUTS_ERROR`

export const GET_WORKOUT_REQUEST = `${NAME}/GET_WORKOUT_REQUEST`
export const GET_WORKOUT_SUCCESS = `${NAME}/GET_WORKOUT_SUCCESS`
export const GET_WORKOUT_ERROR = `${NAME}/GET_WORKOUT_ERROR`

export const GET_WORKOUT_BY_SLUG_REQUEST = `${NAME}/GET_WORKOUT_BY_SLUG_REQUEST`
export const GET_WORKOUT_BY_SLUG_SUCCESS = `${NAME}/GET_WORKOUT_BY_SLUG_SUCCESS`
export const GET_WORKOUT_BY_SLUG_ERROR = `${NAME}/GET_WORKOUT_BY_SLUG_ERROR`

export const GET_WORKOUT_FEEDBACK_REQUEST = `${NAME}/GET_WORKOUT_FEEDBACK_REQUEST`
export const GET_WORKOUT_FEEDBACK_SUCCESS = `${NAME}/GET_WORKOUT_FEEDBACK_SUCCESS`
export const GET_WORKOUT_FEEDBACK_ERROR = `${NAME}/GET_WORKOUT_FEEDBACK_ERROR`

export const POST_WORKOUT_FEEDBACK_REQUEST = `${NAME}/POST_WORKOUT_FEEDBACK_REQUEST`
export const POST_WORKOUT_FEEDBACK_SUCCESS = `${NAME}/POST_WORKOUT_FEEDBACK_SUCCESS`
export const POST_WORKOUT_FEEDBACK_ERROR = `${NAME}/POST_WORKOUT_FEEDBACK_ERROR`

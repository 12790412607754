import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { getPlansRequest } from 'shared/modules/Plans/actions'
import {
  selectPlansLoading,
  selectPlansError,
  selectPlansByPriceId,
  selectPlansByPriceIdSortedByAccess,
} from 'shared/modules/Plans/selectors'

const PlansContainer = ({ children, render }) => {
  const dispatch = useDispatch()
  const plansByPriceId = useSelector(state => selectPlansByPriceId(state))
  const plans = useSelector(state => selectPlansByPriceIdSortedByAccess(state))
  const loading = useSelector(state => selectPlansLoading(state))
  const error = useSelector(state => selectPlansError(state))

  const renderProps = children || render

  useEffect(() => {
    dispatch(getPlansRequest())
  }, []) // eslint-disable-line

  return renderProps({
    plansByPriceId,
    plans,
    loading,
    error,
  })
}

PlansContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
}

export default PlansContainer

import * as actionTypes from './actionTypes'

export const getEquipmentRequest = () => ({
  type: actionTypes.GET_EQUIPMENT_REQUEST,
})

export const getEquipmentSuccess = equipmentById => ({
  type: actionTypes.GET_EQUIPMENT_SUCCESS,
  equipmentById,
})

export const getEquipmentError = error => ({
  type: actionTypes.GET_EQUIPMENT_ERROR,
  error,
})

import React from 'react'
import { useParams } from 'react-router-dom'

import { WorkoutCategoryContainer } from 'shared/modules/WorkoutCategories/components'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { HelmetLocalised } from 'web/components'
import { WorkoutCategory } from 'web/modules/Workouts/components'

import messages from './messages'

const WorkoutCategoryPage = () => {
  const { slug } = useParams()

  return (
    <>
      <HelmetLocalised
        title={messages.title}
        description={messages.description}
      />
      <PageWrapper>
        <WorkoutCategoryContainer slug={slug}>
          {workoutCategory => <WorkoutCategory {...workoutCategory} />}
        </WorkoutCategoryContainer>
      </PageWrapper>
    </>
  )
}

export default WorkoutCategoryPage

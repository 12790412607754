import { defineMessages } from 'react-intl'

export default defineMessages({
  firstNameLabel: {
    id: 'web.Auth.SignUpForm.firstNameLabel',
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    id: 'web.Auth.SignUpForm.lastNameLabel',
    defaultMessage: 'Last name',
  },
  emailLabel: {
    id: 'web.Auth.SignUpForm.emailLabel',
    defaultMessage: 'Email address',
  },
  passwordLabel: {
    id: 'web.Auth.SignUpForm.passwordLabel',
    defaultMessage: 'Password',
  },
  nextButton: {
    id: 'web.Auth.SignUpForm.nextButton',
    defaultMessage: 'Register Now',
  },
})

import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'

const HelperText = ({ show, ...otherProps }) => {
  const opacity = show ? 1 : 0
  const offset = show ? 0 : -15
  const pointerEvents = show ? 'auto' : 'none'
  const transition = {
    opacity,
    transform: `translate(0, ${offset}px)`,
    transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1)',
    pointerEvents,
  }

  return <FormHelperText style={transition} {...otherProps} />
}

HelperText.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default HelperText

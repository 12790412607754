import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Alert } from 'web/components'

const FormErrorDisplay = ({ error }) => {
  if (!error) return null
  return (
    <Alert severity="error">
      {error?.message || 'Oops, something went wrong..'}
    </Alert>
  )
}

FormErrorDisplay.propTypes = {
  error: PropTypes.object,
}

export default memo(FormErrorDisplay)

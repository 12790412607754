import routes from 'web/routing/routes'

export const NAME = 'PageLayout'

export const NAV_HIDDEN_ROUTES = [
  routes.SignInPage.routePath,
  routes.RegistrationUserDetailsPage.routePath,
  routes.RegistrationPricePlanPage.routePath,
  routes.RegistrationBillingDetailsPage.routePath,
  routes.ResetPasswordPage.routePath,
  routes.RegistrationFreeTrialUserDetailsPage.routePath,
  routes.RegistrationFreeTrialPricePlanPage.routePath,
  routes.RegistrationFreeTrialBillingDetailsPage.routePath,
]

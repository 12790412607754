import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { intlMessageShape } from 'web/utils/PropTypeUtils'
import { appConfig } from 'shared/config'

export const titleTemplate = ` | ${appConfig.appName}`

export const HelmetLocalised = ({ intl, title, description }) => (
  <Helmet
    titleTemplate={`%s${titleTemplate}`}
    title={intl.formatMessage(title)}
    meta={[
      {
        name: 'description',
        content: description ? intl.formatMessage(description) : '',
      },
    ]}
  />
)

HelmetLocalised.defaultProps = {
  description: undefined,
}

HelmetLocalised.propTypes = {
  intl: PropTypes.any.isRequired,
  title: PropTypes.oneOfType([intlMessageShape, PropTypes.string]).isRequired,
  description: PropTypes.oneOfType([intlMessageShape, PropTypes.string]),
}

export default injectIntl(HelmetLocalised)

import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import * as selectors from 'shared/modules/Subscription/selectors'
import { getSubscriptionRequest } from 'shared/modules/Subscription/actions'
import {
  selectPaymentMethodExpYear,
  selectPaymentMethodExpMonth,
  selectPaymentMethodLast4,
  selectPaymentMethodError,
} from 'shared/modules/PaymentMethod/selectors'
import { getPaymentMethodRequest } from 'shared/modules/PaymentMethod/actions'
import { getPlansRequest } from 'shared/modules/Plans/actions'
import { selectPlansError } from 'shared/modules/Plans/selectors'

const SubscriptionContainer = ({ children, render }) => {
  const dispatch = useDispatch()

  const status = useSelector(state => selectors.selectSubscriptionStatus(state))
  const cancelAtPeriodEnd = useSelector(state =>
    selectors.selectSubscriptionCancelAtPeriodEnd(state),
  )
  const currentPeriodStart = useSelector(state =>
    selectors.selectSubscriptionCurrentPeriodStart(state),
  )
  const currentPeriodEnd = useSelector(state =>
    selectors.selectSubscriptionCurrentPeriodEnd(state),
  )
  const price = useSelector(state => selectors.selectSubscriptionPrice(state))
  const access = useSelector(state => selectors.selectSubscriptionAccess(state))
  const frequency = useSelector(state =>
    selectors.selectSubscriptionFrequency(state),
  )
  const lastLoaded = useSelector(state =>
    selectors.selectSubscriptionLastLoaded(state),
  )
  const scheduleId = useSelector(state =>
    selectors.selectSubscriptionScheduleId(state),
  )
  const loading = useSelector(state =>
    selectors.selectSubscriptionLoading(state),
  )
  const error = useSelector(state => selectors.selectSubscriptionError(state))

  // PaymentMethod
  const expMonth = useSelector(state => selectPaymentMethodExpMonth(state))
  const expYear = useSelector(state => selectPaymentMethodExpYear(state))
  const last4 = useSelector(state => selectPaymentMethodLast4(state))
  const paymentMethodError = useSelector(state =>
    selectPaymentMethodError(state),
  )

  // Plans
  const plansError = useSelector(state => selectPlansError(state))

  // Promotion
  const promotionCode = useSelector(state =>
    selectors.selectSubscriptionPromotionCode(state),
  )
  const promotionDescription = useSelector(state =>
    selectors.selectSubscriptionPromotionDescription(state),
  )
  const promotionName = useSelector(state =>
    selectors.selectSubscriptionPromotionName(state),
  )

  const getSubscription = useCallback(() => {
    dispatch(getSubscriptionRequest())
  }, [dispatch])

  useEffect(() => {
    dispatch(getPlansRequest())
    dispatch(getPaymentMethodRequest())
  }, []) // eslint-disable-line

  const renderProps = children || render

  return renderProps({
    status,
    cancelAtPeriodEnd,
    currentPeriodStart,
    currentPeriodEnd,
    getSubscription,
    expMonth,
    expYear,
    last4,
    lastLoaded,
    price,
    frequency,
    access,
    promotionCode,
    promotionDescription,
    promotionName,
    scheduleId,
    loading,
    error: error || paymentMethodError || plansError,
  })
}

SubscriptionContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
}

export default SubscriptionContainer

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'web.Pages.RegistrationPages.UserDetailsPage.title',
    defaultMessage: 'Add your details',
  },
  description: {
    id: 'web.Pages.RegistrationPages.UserDetailsPage.description',
    defaultMessage: 'Start your Free Trial today by entering your user details',
  },
  bodyTitle: {
    id: 'web.Pages.RegistrationPages.UserDetailsPage.description',
    defaultMessage: 'Start your Free Trial Today',
  },
  signInCallToAction: {
    id: 'web.Pages.RegistrationPages.UserDetailsPage.signInCallToAction',
    defaultMessage: 'Already have an account? {SIGN_IN_LINK}',
  },
  signInCallToActionLink: {
    id: 'web.Pages.RegistrationPages.UserDetailsPage.signInCallToActionLink',
    defaultMessage: 'Sign in',
  },
})

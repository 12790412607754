import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import { FormattedMessage } from 'react-intl'

import {
  HelmetLocalised,
  FullPageBgFade,
  Image,
  Grid,
  Card,
} from 'web/components'
import {
  BillingDetailsForm,
  RegisteringAs,
} from 'web/modules/Subscription/components'
import signInImage from 'web/images/evolve-4.png'
import logo from 'web/images/evolve-logo-dark-alt.png'

import messages from './messages'

const UserDetailsPage = () => (
  <>
    <HelmetLocalised
      title={messages.title}
      description={messages.description}
    />
    <FullPageBgFade type="second">
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={0}
        style={styles.wrapper}
      >
        <Grid item xs={11} md={6} lg={4}>
          <Grid container justify="center">
            <Grid item xs={5} sm={3} md={3}>
              <Image src={logo} alt="Evolve" />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item xs={12} sm={6} md={8} lg={10}>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="h1"
                    align="center"
                  >
                    <FormattedMessage {...messages.bodyTitle} />
                  </Typography>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <BillingDetailsForm />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <RegisteringAs />
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item md={4} lg={4}>
            <Image src={signInImage} style={styles.image} alt="Evolve" />
          </Grid>
        </Hidden>
      </Grid>
    </FullPageBgFade>
  </>
)

const styles = {
  wrapper: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  image: {
    mixBlendMode: 'luminosity',
    width: '100%',
  },
  subText: {
    marginTop: '15px',
  },
  footer: {
    marginBottom: '40px',
  },
}

export default UserDetailsPage

import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'

import { selectWeeklyPlanTodaysWorkout } from 'shared/modules/WeeklyPlan/selectors'

import routes from 'web/routing/routes'
import { Grid, ButtonPrimary } from 'web/components'
import { CardWorkoutSummary } from 'web/modules/Workouts/components'

const TodaysWorkout = () => {
  const history = useHistory()
  const todaysWorkout = useSelector(state =>
    selectWeeklyPlanTodaysWorkout(state),
  )

  if (!todaysWorkout) return null

  return (
    <Grid container style={styles.wrapper}>
      <Grid item xs={12}>
        <Typography variant="h3">Today&apos;s Workout</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Keep up with the Weekly Plan by completing Todays Workout
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CardWorkoutSummary
          id={todaysWorkout.id}
          name={todaysWorkout.name}
          image={todaysWorkout.image}
          slug={todaysWorkout.slug}
          summary={todaysWorkout.summary}
          duration={todaysWorkout.duration}
          publishAt={todaysWorkout.publishAt}
        />
      </Grid>
      <Grid item xs={12}>
        <ButtonPrimary
          onClick={() => history.push(routes.WeeklyPlanPage.routePath)}
        >
          Weekly Plan
        </ButtonPrimary>
      </Grid>
    </Grid>
  )
}

const styles = {
  wrapper: {
    marginBottom: 35,
  },
}

export default TodaysWorkout

import PropTypes from 'prop-types'

/**
 * Generates the correct PropType when trying to ensure a specific Component
 * @param {Class} component the React component you expect the children to be
 * @returns {PropType}
 */
export const componentPropType = component => {
  const componentShape = PropTypes.shape({
    type: PropTypes.oneOf([component]),
  })

  // Need to accept the class or false (conditional rendering)
  return PropTypes.oneOfType([componentShape, PropTypes.oneOf([false])])
}

export const intlMessageShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
})

import { call, put, takeLatest, spawn } from 'redux-saga/effects'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import * as database from 'shared/firebase/database'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import { transformWorkoutCategories, transformCategoriesToTree } from './utils'

export function* getWorkoutCategories() {
  try {
    const response = yield call(database.getWorkoutCategories)
    const categories = transformWorkoutCategories(response)
    yield put(
      actions.getWorkoutCategoriesSuccess(
        categories,
        transformCategoriesToTree(categories.byId),
      ),
    )
  } catch (error) {
    yield put(actions.getWorkoutCategoriesError(error))
    yield spawn(throwSentryError, error)
  }
}

export default [
  takeLatest(actionTypes.GET_WORKOUT_CATEGORIES_REQUEST, getWorkoutCategories),
]

import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CardContent from '@material-ui/core/CardContent'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { formatLongDateTime } from 'shared/utils/DateTimeUtils'
import {
  isLiveScheduleItemLive,
  isLiveScheduleItemFuture,
  isLiveScheduleItemPast,
} from 'shared/modules/LiveSchedule/utils'
import { selectUserLiveScheduleItemByLiveScheduleIdJoinUrl } from 'shared/modules/User/selectors'

import {
  CircularProgress,
  Grid,
  ButtonPrimary,
  Card,
  Alert,
} from 'web/components'
import ButtonRegisterOrJoin from 'web/modules/LiveSchedule/components/ButtonRegisterOrJoin'

const LiveScheduleItem = ({
  id,
  name,
  startDateTime,
  endDateTime,
  description,
  meetingNumber,
  catchupUrl,
  loading,
  error,
}) => {
  const joinUrl = useSelector(state =>
    selectUserLiveScheduleItemByLiveScheduleIdJoinUrl(state, id),
  )
  const hasRegistered = !!joinUrl

  // @NOTE if we don't have a name and we are loading, we assume
  // fresh content, otherwise the fetch can happen in the background
  if (loading && !name) return <CircularProgress />

  const isFutureClass = isLiveScheduleItemFuture(startDateTime, endDateTime)
  const isPastClass = isLiveScheduleItemPast(startDateTime, endDateTime)
  const isLiveClass = isLiveScheduleItemLive(startDateTime, endDateTime)

  // @NOTE assume that if there is no name, and it's not loading, something has gone wrong.
  if (error || !name)
    return (
      <Alert severity={error}>
        Oops, something went wrong with this Live Class... try refreshing the
        page
      </Alert>
    )

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Typography variant="h1">
          {name} {isLiveClass && <Chip size="small" label="Live now" />}
        </Typography>
        <Typography gutterBottom variant="subtitle1" color="textSecondary">
          {isLiveClass &&
            `Started ${moment(startDateTime).fromNow()} - ${formatLongDateTime(
              startDateTime,
            )}`}
          {isFutureClass &&
            `Starts ${moment(startDateTime).fromNow()} - ${formatLongDateTime(
              startDateTime,
            )}`}
          {isPastClass &&
            `Ended ${moment(endDateTime).fromNow()} - ${formatLongDateTime(
              endDateTime,
            )}`}
        </Typography>
        <Typography>{description}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent>
            {hasRegistered && isFutureClass && (
              <>
                <Typography gutterBottom variant="h6">
                  You are Registered!
                </Typography>
                <Typography paragraph>
                  This class is scheduled to start{' '}
                  {moment(startDateTime).fromNow()}. You will be able to join
                  the class within 5 minutes of the start time.
                </Typography>
                <Typography paragraph variant="body2" color="textSecondary">
                  Simply click the link below or check your email for the invite
                  to the class.
                </Typography>
              </>
            )}

            {!hasRegistered && isFutureClass && (
              <>
                <Typography gutterBottom variant="h6">
                  Register now to join the Live Class
                </Typography>
                <Typography paragraph>
                  This class is scheduled to start{' '}
                  {moment(startDateTime).fromNow()}. You will be able to join
                  the class within 5 minutes of the start time.
                </Typography>
                <Typography paragraph variant="body2" color="textSecondary">
                  Simply click the link below to register automatically. You
                  will receive a confirmation email with all of the meeting
                  details, as well as being able to join directly from here.
                </Typography>
              </>
            )}

            {isPastClass && (
              <>
                <Typography gutterBottom variant="h6">
                  This class has ended
                </Typography>
                {catchupUrl ? (
                  <Typography paragraph variant="body2" color="textSecondary">
                    You can catch up with this class by watching back the
                    recording.
                  </Typography>
                ) : (
                  <Typography paragraph variant="body2" color="textSecondary">
                    The recording from this class is not yet available. Please
                    check back soon.
                  </Typography>
                )}
                <Grid container alignItems="center" justify="center">
                  <Grid item>
                    <ButtonPrimary
                      disabled={!catchupUrl}
                      onClick={() => window.open(catchupUrl, '_blank')}
                    >
                      Watch the Recording
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </>
            )}

            {isLiveClass && (
              <>
                <Typography gutterBottom variant="h6">
                  This Class has started!
                </Typography>
                <Typography paragraph>
                  This class started {moment(startDateTime).fromNow()}. You may
                  still be allowed to join by registering.
                </Typography>
                <Typography paragraph variant="body2" color="textSecondary">
                  Simply click the link below to register automatically. You
                  will receive a confirmation email with all of the meeting
                  details, as well as being able to join directly from here.
                </Typography>
              </>
            )}

            {(isFutureClass || isLiveClass) && (
              <Grid container alignItems="center" justify="center">
                <Grid item>
                  <ButtonRegisterOrJoin
                    meetingNumber={meetingNumber}
                    liveScheduleId={id}
                  />
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

LiveScheduleItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  startDateTime: PropTypes.number,
  endDateTime: PropTypes.number,
  description: PropTypes.string,
  meetingNumber: PropTypes.string,
  catchupUrl: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
}

export default LiveScheduleItem

import React from 'react'
import Typography from '@material-ui/core/Typography'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { HelmetLocalised, Grid } from 'web/components'
import { SubscriptionDetails } from 'web/modules/Subscription/components'
import { UserDetails } from 'web/modules/User/components'

import messages from './messages'

const AccountDetailsPage = () => (
  <>
    <HelmetLocalised
      title={messages.title}
      description={messages.description}
    />
    <PageWrapper>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">My Account</Typography>
        </Grid>
        <Grid item xs={12}>
          <SubscriptionDetails />
        </Grid>
        <UserDetails />
      </Grid>
    </PageWrapper>
  </>
)

export default AccountDetailsPage

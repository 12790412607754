import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from 'web/components'
import CardWorkoutSummary from 'web/modules/Workouts/components/CardWorkoutSummary'

const WorkoutsList = ({ workouts }) => (
  <Grid container>
    {workouts.map(({ id, name, image, slug, summary, duration, publishAt }) => (
      <Grid key={id} item xs={12}>
        <CardWorkoutSummary
          id={id}
          name={name}
          image={image}
          slug={slug}
          summary={summary}
          duration={duration}
          publishAt={publishAt}
        />
      </Grid>
    ))}
  </Grid>
)

WorkoutsList.propTypes = {
  workouts: PropTypes.array.isRequired,
}

export default WorkoutsList

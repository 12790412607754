import React from 'react'
import PropTypes from 'prop-types'

import { Alert } from 'web/components'

const RedirectionMessage = ({ message }) => {
  switch (message) {
    case 'freeTrialUsed':
      return (
        <Alert severity="warning">
          Your Free Trial has ended! To continue with your Subscription, please
          select a Plan and continue to payment.
        </Alert>
      )
    case 'expired':
      return (
        <Alert severity="warning">
          Your Subscription was canceled or has ended without being renewed. To
          continue, please select your new plan and continue to payment.
        </Alert>
      )
    case 'retry':
      return (
        <Alert severity="warning">
          It looks like your Subscription is incomplete. Please select a plan
          and continue to payment.
        </Alert>
      )
    default:
      return null
  }
}

RedirectionMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default RedirectionMessage

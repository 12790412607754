import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import { useHistory } from 'react-router-dom'

import routes from 'web/routing/routes'
import { CircularProgress, Video, Grid, Alert } from 'web/components'
import ButtonToggleFavourite from 'web/modules/Workouts/components/ButtonToggleFavourite'
import WorkoutFeedbackForm from 'web/modules/Workouts/components/WorkoutFeedbackForm'
import WorkoutFeedbackList from 'web/modules/Workouts/components/WorkoutFeedbackList'

const Workout = ({
  id,
  categories,
  description,
  duration,
  equipment,
  muscleGroups,
  name,
  publishAt,
  vimeoId,
  // watchCount,
  feedback,
  loading,
  error,
}) => {
  const history = useHistory()

  // @NOTE if we don't have a name and we are loading, we assume
  // fresh content, otherwise the fetch can happen in the background
  if (loading && !name) return <CircularProgress />

  // @NOTE assume that if there is no name, and it's not loading, something has gone wrong.
  if (error || !name)
    return (
      <Alert severity="error">
        Oops, something went wrong with this Workout... try refreshing the page
      </Alert>
    )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Video id={vimeoId} workoutId={id} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1">{name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {duration} minutes | Added {moment(publishAt).fromNow()}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7}>
        <Typography
          paragraph
          variant="body1"
          style={{ whiteSpace: 'pre-line' }}
        >
          {description}
        </Typography>
        {feedback?.length ? (
          <>
            <Divider style={styles.divider} />
            <WorkoutFeedbackList feedback={feedback} />
          </>
        ) : null}
        {/* @NOTE if we want to enable Feedback only when the user has watched the Workout. */}
        {/* {watchCount && id ? (
          <>
            <Divider style={styles.divider} />
            <WorkoutFeedbackForm workoutId={id} />
          </>
        ) : null} */}
        <Divider style={styles.divider} />
        <WorkoutFeedbackForm workoutId={id} />
      </Grid>
      <Grid item xs={12} sm={5}>
        <ButtonToggleFavourite workoutId={id} />
        {categories && categories.length && (
          <>
            <Divider style={styles.divider} />
            <Typography gutterBottom variant="h6" color="textSecondary">
              Categories
            </Typography>
            {categories &&
              categories.length &&
              categories.map(category => (
                <Chip
                  size="small"
                  color="primary"
                  key={category.id}
                  label={category.name}
                  style={styles.chip}
                  onClick={() =>
                    history.push(
                      `${routes.WorkoutCategoryPage.routeName}${category.slug}`,
                    )
                  }
                />
              ))}
          </>
        )}
        <Divider style={styles.divider} />
        <Typography gutterBottom variant="h6" color="textSecondary">
          Equipment
        </Typography>
        {equipment.map(equipmentItem => (
          <Chip
            size="small"
            key={equipmentItem.id}
            label={equipmentItem.name}
            style={styles.chip}
          />
        ))}
        <Divider style={styles.divider} />
        <Typography gutterBottom variant="h6" color="textSecondary">
          Muscle Groups
        </Typography>
        {muscleGroups.map(muscleGroup => (
          <Chip
            size="small"
            key={muscleGroup.id}
            label={muscleGroup.name}
            style={styles.chip}
          />
        ))}
      </Grid>
    </Grid>
  )
}

const styles = {
  divider: {
    marginTop: 12,
    marginBottom: 16,
  },
  chip: {
    marginRight: 4,
    marginBottom: 4,
  },
}

Workout.propTypes = {
  id: PropTypes.string,
  categories: PropTypes.array,
  description: PropTypes.string,
  duration: PropTypes.string,
  equipment: PropTypes.array,
  muscleGroups: PropTypes.array,
  name: PropTypes.string,
  publishAt: PropTypes.number,
  vimeoId: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
}

export default Workout

import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

import { Grid } from 'web/components'
import { colors } from 'web/theme'

const WeeklyMantra = ({ mantra, variant }) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="h3">Monday mantra</Typography>
    </Grid>
    <Grid item xs={12}>
      <div style={{ ...styles.wrapperTypes[variant], ...styles.wrapper }}>
        <Typography variant="subtitle1">{mantra}</Typography>
      </div>
    </Grid>
  </Grid>
)

const styles = {
  wrapper: {
    padding: '30px',
    maxWidth: '910px',
  },
  wrapperTypes: {
    yellow: {
      background: colors.buttonPrimaryBg,
      color: '#ffffff',
    },
    green: {
      background: colors.buttonPrimaryHighlightBg,
      color: '#ffffff',
    },
    blue: {
      background: colors.buttonPrimaryBg,
      color: '#ffffff',
    },
  },
}

WeeklyMantra.propTypes = {
  mantra: PropTypes.string,
  variant: PropTypes.string,
}

export default WeeklyMantra

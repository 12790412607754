import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { intlMessageShape } from 'web/utils/PropTypeUtils'

import messages from './messages'

const FormInputErrorMessage = ({ validationCode, message }) => {
  const errorMessage = message || messages[validationCode] || messages.default
  return <FormattedMessage {...errorMessage} />
}

FormInputErrorMessage.defaultProps = {
  validationCode: null,
  message: null,
}

FormInputErrorMessage.propTypes = {
  validationCode: PropTypes.string,
  message: intlMessageShape,
}

export default FormInputErrorMessage

import { defineMessages } from 'react-intl'

export default defineMessages({
  emailLabel: {
    id: 'web.Auth.SignInForm.emailLabel',
    defaultMessage: 'Email address',
  },
  passwordLabel: {
    id: 'web.Auth.SignInForm.password',
    defaultMessage: 'Password',
  },
  signInButton: {
    id: 'web.Auth.SignInForm.signInButton',
    defaultMessage: 'Sign in to Evolve',
  },
  remainSignedIn: {
    id: 'web.Auth.SignInForm.remainSignedIn',
    defaultMessage: 'Keep me signed in',
  },
})

import moment from 'moment'
import * as actionTypes from './actionTypes'

const initialState = {
  id: undefined,
  status: undefined,
  latestInvoicePaymentIntentStatus: undefined,
  latestInvoiceId: undefined,
  priceId: undefined,
  cancelAtPeriodEnd: undefined,
  currentPeriodStart: undefined,
  currentPeriodEnd: undefined,
  lastLoaded: undefined,
  scheduleId: undefined,
  promotion: null,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUBSCRIPTION_REQUEST:
    case actionTypes.CREATE_SUBSCRIPTION_REQUEST:
    case actionTypes.CANCEL_SUBSCRIPTION_AT_PERIOD_END_REQUEST:
    case actionTypes.RETRY_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GET_SUBSCRIPTION_SUCCESS:
    case actionTypes.CREATE_SUBSCRIPTION_SUCCESS:
    case actionTypes.RETRY_INVOICE_SUCCESS:
      return {
        ...state,
        id: action.id,
        latestInvoiceId: action.latestInvoiceId,
        latestInvoicePaymentIntentStatus:
          action.latestInvoicePaymentIntentStatus,
        status: action.status,
        priceId: action.priceId,
        cancelAtPeriodEnd: action.cancelAtPeriodEnd,
        currentPeriodStart: action.currentPeriodStart,
        currentPeriodEnd: action.currentPeriodEnd,
        lastLoaded: moment().valueOf(),
        promotion: action.promotion,
        scheduleId: action.scheduleId,
        loading: false,
      }
    case actionTypes.GET_SUBSCRIPTION_ERROR:
    case actionTypes.CREATE_SUBSCRIPTION_ERROR:
    case actionTypes.RETRY_INVOICE_ERROR:
      return {
        ...state,
        id: action.id,
        latestInvoiceId: action.latestInvoiceId,
        latestInvoicePaymentIntentStatus:
          action.latestInvoicePaymentIntentStatus,
        status: action.status,
        priceId: action.priceId,
        lastLoaded: moment().valueOf(),
        loading: false,
        error: action.error,
      }
    case actionTypes.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        lastLoaded: moment().valueOf(),
      }
    case actionTypes.ADD_SUBSCRIPTION_PRICE_ID:
      return {
        ...state,
        priceId: action.priceId,
      }
    case actionTypes.CANCEL_SUBSCRIPTION_AT_PERIOD_END_SUCCESS:
      return {
        ...state,
        cancelAtPeriodEnd: action.cancelAtPeriodEnd,
        currentPeriodStart: action.currentPeriodStart,
        currentPeriodEnd: action.currentPeriodEnd,
        loading: false,
      }
    case actionTypes.CANCEL_SUBSCRIPTION_AT_PERIOD_END_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case actionTypes.ADD_SUBSCRIPTION_PROMOTION_SUCCESS:
      return {
        ...state,
        promotion: action.promotion,
      }
    case actionTypes.UPDATE_SUBSCRIPTION_PRICE_SUCCESS:
      return {
        ...state,
        priceId: action.priceId,
      }
    case actionTypes.SCHEDULE_SUBSCRIPTION_DOWNGRADE_SUCCESS:
      return {
        ...state,
        scheduleId: action.scheduleId,
        promotion: null,
      }
    case actionTypes.CANCEL_SUBSCRIPTION_DOWNGRADE_SUCCESS:
      return {
        ...state,
        scheduleId: undefined,
        promotion: null,
      }
    case actionTypes.SUBSCRIPTION_SHOULD_UPDATE:
      return {
        ...state,
        lastLoaded: undefined,
      }
    default:
      return state
  }
}

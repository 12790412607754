export const transformMuscleGroups = response => {
  if (!response || response.empty) return null

  let muscleGroupsById = {}

  response.forEach(doc => {
    muscleGroupsById = {
      ...muscleGroupsById,
      [doc.id]: {
        ...doc.data(),
        id: doc.id,
      },
    }
  })

  return muscleGroupsById
}

import { auth, firebase } from './firebase'

export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password)

export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

export const doSignOut = () => auth.signOut()

export const doResetPassword = email => auth.sendPasswordResetEmail(email)

export const doUpdatePassword = password =>
  auth.currentUser.updatePassword(password)

export const doUpdateEmail = email => auth.currentUser.updateEmail(email)

export const doReauthenticateWithCredential = password => {
  const user = auth.currentUser
  const credential = firebase.auth.EmailAuthProvider.credential(
    user.email,
    password,
  )
  return auth.currentUser.reauthenticateWithCredential(credential)
}

export const doDeleteUser = () => auth.currentUser.delete()

export const doUpdateUserProfile = (key, value) =>
  auth.currentUser.updateProfile({
    [key]: value,
  })

import { defineMessages } from 'react-intl'

export default defineMessages({
  currentPasswordLabel: {
    id: 'web.Auth.UpdatePasswordForm.currentPasswordLabel',
    defaultMessage: 'Current Password',
  },
  newPasswordLabel: {
    id: 'web.Auth.UpdatePasswordForm.newPasswordLabel',
    defaultMessage: 'New Password',
  },
  submitButton: {
    id: 'web.Auth.UpdatePasswordForm.submitButton',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'web.Auth.UpdatePasswordForm.cancelButton',
    defaultMessage: 'Cancel',
  },
})

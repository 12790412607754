import { call, put, takeLatest, spawn } from 'redux-saga/effects'

import * as database from 'shared/firebase/database'
import { throwSentryError } from 'shared/utils/ErrorUtils'

import * as actionTypes from './actionTypes'
import * as actions from './actions'
import { transformEquipment } from './utils'

export function* getEquipment() {
  try {
    const response = yield call(database.getEquipment)
    yield put(actions.getEquipmentSuccess(transformEquipment(response)))
  } catch (error) {
    yield put(actions.getEquipmentError(error))
    yield spawn(throwSentryError, error)
  }
}

export default [takeLatest(actionTypes.GET_EQUIPMENT_REQUEST, getEquipment)]

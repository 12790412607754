import * as actionTypes from './actionTypes'

export const getWeeklyMantraRequest = () => ({
  type: actionTypes.GET_WEEKLY_MANTRA_REQUEST,
})

export const getWeeklyMantraSuccess = weeklyMantra => ({
  type: actionTypes.GET_WEEKLY_MANTRA_SUCCESS,
  weeklyMantra,
})

export const getWeeklyMantraError = error => ({
  type: actionTypes.GET_WEEKLY_MANTRA_ERROR,
  error,
})

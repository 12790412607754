import React from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import { FormattedMessage } from 'react-intl'

import {
  HelmetLocalised,
  FullPageBgFade,
  Image,
  Grid,
  Card,
} from 'web/components'
import {
  BillingDetailsForm,
  RegisteringAs,
} from 'web/modules/Subscription/components'
import logo from 'web/images/evolve-logo.png'

import messages from './messages'

const getTrialEnd = () =>
  moment()
    .add(7, 'days')
    .unix()

const BillingDetailsPage = () => (
  <>
    <HelmetLocalised
      title={messages.title}
      description={messages.description}
    />
    <FullPageBgFade type="second">
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={0}
        style={styles.wrapper}
      >
        <Grid item xs={11} md={6} lg={4}>
          <Grid container justify="center">
            <Grid item xs={5} sm={3} md={3}>
              <Image src={logo} alt="Evolve" />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="h1"
                    align="center"
                  >
                    <FormattedMessage {...messages.bodyTitle} />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={11} lg={9}>
                  <Card>
                    <CardContent>
                      <BillingDetailsForm trialEnd={getTrialEnd()} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <RegisteringAs />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullPageBgFade>
  </>
)

const styles = {
  wrapper: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  image: {
    mixBlendMode: 'luminosity',
    width: '100%',
  },
  subText: {
    marginTop: '15px',
  },
  footer: {
    marginBottom: '40px',
  },
}

export default BillingDetailsPage

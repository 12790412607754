import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { formatUnixDate } from 'shared/utils/DateTimeUtils'
import { SUBSCRIPTION_STATUS } from 'shared/modules/Subscription/constants'
import { InvoiceUpcomingContainer } from 'shared/modules/Invoices/components'

import { Alert, FormattedMoney, CircularProgress } from 'web/components'

import messages from './messages'

const UpcomingInvoiceAlert = ({
  status,
  currentPeriodEnd,
  cancelAtPeriodEnd,
}) => {
  if (cancelAtPeriodEnd) {
    return (
      <Alert severity="warning">
        {status === SUBSCRIPTION_STATUS.trialing ? (
          <FormattedMessage
            {...messages.cancelingTrial}
            values={{
              FROM_NOW: (
                <strong>{moment.unix(currentPeriodEnd).fromNow()}</strong>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            {...messages.canceling}
            values={{
              DATE: <strong>{formatUnixDate(currentPeriodEnd)}</strong>,
            }}
          />
        )}
      </Alert>
    )
  }

  return (
    <InvoiceUpcomingContainer>
      {({ total, nextPaymentAttempt, loading, error }) => {
        if (error)
          return (
            <Alert severity="warning">
              <FormattedMessage {...messages.errorMessage} />
            </Alert>
          )
        return (
          <Alert severity="info">
            {!total && loading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                {status === SUBSCRIPTION_STATUS.trialing ? (
                  <FormattedMessage
                    {...messages.trialing}
                    values={{
                      FROM_NOW: (
                        <strong>
                          {moment.unix(currentPeriodEnd).fromNow()}
                        </strong>
                      ),
                      TOTAL: (
                        <strong>
                          <FormattedMoney value={total} />
                        </strong>
                      ),
                      DATE: (
                        <strong>{formatUnixDate(nextPaymentAttempt)}</strong>
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    {...messages.nextPayment}
                    values={{
                      TOTAL: (
                        <strong>
                          <FormattedMoney value={total} />
                        </strong>
                      ),
                      DATE: (
                        <strong>{formatUnixDate(nextPaymentAttempt)}</strong>
                      ),
                    }}
                  />
                )}
              </>
            )}
          </Alert>
        )
      }}
    </InvoiceUpcomingContainer>
  )
}

UpcomingInvoiceAlert.propTypes = {
  status: PropTypes.string,
  frequency: PropTypes.string,
  price: PropTypes.number,
  currentPeriodEnd: PropTypes.number,
  cancelAtPeriodEnd: PropTypes.bool,
  scheduleId: PropTypes.string,
}

export default UpcomingInvoiceAlert

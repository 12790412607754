import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { appConfig } from 'shared/config'
import {
  validateRequired,
  validateEmailAddress,
  composeValidators,
  parseEmailAddress,
} from 'shared/utils/FormUtils'
import { InputGroup } from 'shared/components'
import { signUpRequest } from 'shared/modules/Auth/actions'
import { useAuth } from 'shared/modules/Auth/hooks'

import { colors } from 'web/theme'
import routes from 'web/routing/routes'
import {
  Grid,
  FormInputPasswordToggle,
  FormInputText,
  ButtonPrimary,
  FormErrorDisplay,
  Alert,
} from 'web/components'

import messages from './messages'

const UserDetailsForm = ({ freeTrial }) => {
  const [displayError, setDisplayError] = useState()
  const dispatch = useDispatch()
  const history = useHistory()
  const { loading } = useAuth()

  const onSuccess = () =>
    history.push(
      freeTrial
        ? routes.RegistrationFreeTrialPricePlanPage.routePath
        : routes.RegistrationPricePlanPage.routePath,
    )

  const onError = error => setDisplayError(error)

  return (
    <Form
      onSubmit={({ email, password, firstName, lastName }) => {
        setDisplayError(undefined)
        dispatch(
          signUpRequest(
            email,
            password,
            firstName,
            lastName,
            onSuccess,
            onError,
          ),
        )
      }}
    >
      {({ handleSubmit }) => (
        <>
          <Grid
            container
            component="form"
            justify="center"
            onSubmit={handleSubmit}
            noValidate
          >
            {displayError && (
              <Grid item xs={12}>
                <FormErrorDisplay error={displayError} />
              </Grid>
            )}
            <Grid item xs={12}>
              <InputGroup
                name="firstName"
                Component={FormInputText}
                validate={composeValidators(validateRequired)}
                label={<FormattedMessage {...messages.firstNameLabel} />}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputGroup
                name="lastName"
                Component={FormInputText}
                validate={composeValidators(validateRequired)}
                label={<FormattedMessage {...messages.lastNameLabel} />}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputGroup
                name="email"
                Component={FormInputText}
                validate={composeValidators(
                  validateRequired,
                  validateEmailAddress,
                )}
                parse={parseEmailAddress}
                label={<FormattedMessage {...messages.emailLabel} />}
                required
              />
            </Grid>
            <Grid item xs={12} data-testid="password-input-wrapper">
              <InputGroup
                name="password"
                Component={FormInputPasswordToggle}
                label={<FormattedMessage {...messages.passwordLabel} />}
                validate={validateRequired}
                required
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">
                By clicking Register, you are agreeing to our{' '}
                <a
                  href={appConfig.termsOfUseUrl}
                  target="_blank"
                  style={styles.link}
                >
                  Terms of Use
                </a>{' '}
                and{' '}
                <a
                  href={appConfig.privacyPolicyUrl}
                  target="_blank"
                  style={styles.link}
                >
                  Privacy Policy
                </a>
                .
              </Alert>
            </Grid>
            <Grid item xs={12} style={styles.buttonGrid}>
              <ButtonPrimary
                type="submit"
                onClick={handleSubmit}
                loading={loading}
              >
                <FormattedMessage {...messages.nextButton} />
              </ButtonPrimary>
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  )
}

const styles = {
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    color: colors.fontFirst,
  },
}

UserDetailsForm.propTypes = {
  freeTrial: PropTypes.bool,
}

export default UserDetailsForm

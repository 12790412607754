import * as actionTypes from './actionTypes'

const initialState = {
  weeklyPlanByDay: null,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WEEKLY_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GET_WEEKLY_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        weeklyPlanByDay: {
          monday: action.weeklyPlan.monday,
          tuesday: action.weeklyPlan.tuesday,
          wednesday: action.weeklyPlan.wednesday,
          thursday: action.weeklyPlan.thursday,
          friday: action.weeklyPlan.friday,
          saturday: action.weeklyPlan.saturday,
          sunday: action.weeklyPlan.sunday,
        },
      }
    case actionTypes.GET_WEEKLY_PLAN_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}

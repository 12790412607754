import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { getInvoiceUpcomingRequest } from 'shared/modules/Invoices/actions'
import * as selectors from 'shared/modules/Invoices/selectors'

const InvoiceUpcomingContainer = ({ children, render }) => {
  const dispatch = useDispatch()

  const total = useSelector(state =>
    selectors.selectInvoicesUpcomingTotal(state),
  )
  const nextPaymentAttempt = useSelector(state =>
    selectors.selectInvoicesUpcomingNextPaymentAttempt(state),
  )
  const lastLoaded = useSelector(state =>
    selectors.selectInvoicesUpcomingLastLoaded(state),
  )
  const loading = useSelector(state =>
    selectors.selectInvoicesUpcomingLoading(state),
  )
  const error = useSelector(state =>
    selectors.selectInvoicesUpcomingError(state),
  )

  // Only fetch if the data is more than 5 minutes old. Otherwise, we assume it to be valid.
  const shouldBeLoading = lastLoaded
    ? moment(lastLoaded).isBefore(moment().subtract(5, 'minutes'))
    : true

  useEffect(() => {
    if (shouldBeLoading) dispatch(getInvoiceUpcomingRequest())
  }, [dispatch, shouldBeLoading])

  const renderProps = children || render

  return renderProps({
    total: total ? total / 100 : undefined,
    nextPaymentAttempt,
    loading,
    error,
  })
}

InvoiceUpcomingContainer.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func,
}

export default InvoiceUpcomingContainer

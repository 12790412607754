import { createSelector } from 'reselect'
import get from 'lodash/get'
import { NAME } from './constants'

export const selectEquipmentState = state => state[NAME]
export const getEquipmentItemById = (state, id) =>
  get(state[NAME], `equipmentById[${id}]`)

const selectEquipment = createSelector(
  selectEquipmentState,
  equipmentState => equipmentState,
)

export const selectEquipmentLoading = createSelector(
  selectEquipment,
  equipment => equipment.loading,
)

export const selectEquipmentError = createSelector(
  selectEquipment,
  equipment => equipment.error,
)

export const selectEquipmentById = createSelector(
  selectEquipment,
  equipment => equipment.equipmentById,
)

export const selectEquipmentItemById = createSelector(
  getEquipmentItemById,
  equipmentItem => equipmentItem,
)

import React from 'react'
import Typography from '@material-ui/core/Typography'

import { LiveScheduleContainer } from 'shared/modules/LiveSchedule/components'
import { AccessLevelRequired } from 'web/modules/Navigation/components'

import { PageWrapper } from 'web/modules/PageLayout/components'
import { HelmetLocalised, Grid, Alert } from 'web/components'
import { LiveSchedule } from 'web/modules/LiveSchedule/components'

import messages from './messages'

const DashboardPage = () => (
  <>
    <HelmetLocalised
      title={messages.title}
      description={messages.description}
    />
    <PageWrapper>
      <Grid container>
        <AccessLevelRequired requiredAccessLevel={20}>
          <Grid item xs={12}>
            <Typography variant="h1">Live Class Schedule</Typography>
          </Grid>
          <Grid item xs={12}>
            <LiveScheduleContainer>
              {({
                pastLiveScheduleItems,
                futureLiveScheduleItems,
                activeLiveScheduleItems,
                loading,
                error,
              }) => {
                if (
                  !pastLiveScheduleItems &&
                  !futureLiveScheduleItems &&
                  !activeLiveScheduleItems
                ) {
                  return (
                    <Alert severity="warning">
                      We could not find any scheduled Live Classes, please try
                      back soon.
                    </Alert>
                  )
                }

                return (
                  <LiveSchedule
                    pastLiveScheduleItems={pastLiveScheduleItems}
                    futureLiveScheduleItems={futureLiveScheduleItems}
                    activeLiveScheduleItems={activeLiveScheduleItems}
                    error={error}
                    loading={loading}
                  />
                )
              }}
            </LiveScheduleContainer>
          </Grid>
        </AccessLevelRequired>
      </Grid>
    </PageWrapper>
  </>
)

export default DashboardPage

import * as actionTypes from './actionTypes'

const initialState = {
  liveScheduleById: null,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIVE_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.GET_LIVE_SCHEDULE_SUCCESS:
      return {
        ...state,
        liveScheduleById: action.liveScheduleById,
        loading: false,
      }
    case actionTypes.GET_LIVE_SCHEDULE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case actionTypes.GET_LIVE_SCHEDULE_ITEM_SUCCESS:
      return {
        ...state,
        liveScheduleById: {
          ...state.liveScheduleById,
          [action.liveScheduleItem.id]: action.liveScheduleItem,
        },
      }
    default:
      return state
  }
}
